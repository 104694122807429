import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { customerService, templateService, documentationService, photoService } from '../../_services'
import moment from 'moment'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { Line } from 'react-chartjs-2';

function CustomerStatistics() {

	const [customers, setCustomers] = useState([])
	const [loading, setLoading] = useState(false)
	const [templates, setTemplates] = useState([])
	const [documentations, setDocumentations] = useState([])
	const [photos, setPhotos] = useState([])
	const [selectedCustomerId, setSelectedCustomerId] = useState("")
	const [type, setType] = useState("documentations")
	const [minDate, setMinDate] = useState("")
	const {t} = useTranslation('common');
	const colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#00B3E6',
		  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

    useEffect(() => {
        if (customers.length == 0) {
	        customerService.list(1000).then(data => {
	            data.sort((a, b) => {
		            return a.name.localeCompare(b.name)
		        })
	            setCustomers(data)
	        })
        }

        setLoading(true)
        if (selectedCustomerId) {
            templateService.findAllByCustomerId(selectedCustomerId).then(data => {
	            data.sort((a, b) => {
		            if (a.position != b.position) {
		                return a.position - b.position
		            }
		            return a.name.localeCompare(b.name)
		        })
	            setTemplates(data)
	        })
	        documentationService.findAllByCustomerId(selectedCustomerId).then(documentations => {
	            var documentationMap = new Map()
	            documentations.forEach(documentation => {
	                documentation.photos = []
	                documentationMap.set(documentation.id, documentation)
	            })

	            var minDate = documentations[0].dateCreated
		        documentations.forEach(documentation => {
		            if (documentation.dateCreated < minDate) {
		                minDate = documentation.dateCreated
		            }
		        })
		        setMinDate(minDate)

	            setDocumentations(documentations)

	            photoService.findAllByCustomerId(selectedCustomerId).then(photos => {
	                photos.forEach(photo => {
	                    var documentation = documentationMap.get(photo.documentationId)
	                    if (documentation) {
	                        documentation.photos.push(photo)
	                    }
	                })
		            setPhotos(photos)

		            setLoading(false)
		        })
	        })
        }
    }, [selectedCustomerId])

    function handleCustomerChange(e) {
        var customerId = e.target.value

        if (customerId) {
            setSelectedCustomerId(customerId)
        }
    }

    function handleTypeChange(e) {
        var type = e.target.value
        setType(type)
    }

    function getMonths() {
        var startDate = moment(minDate).startOf('month')
        var months = []
        while (startDate.isBefore()) {
            months.push(startDate.toDate())
            startDate.add(1, 'month')
        }
        return months.reverse()
    }

    function getStatistics(params) {
        if (type == 'documentations') {
            var filteredDocumentations = documentations.filter(documentation => {
	            return documentation.customerId == selectedCustomerId
	        })
	        if (params.month) {
	            var startDate = moment(params.month)
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return startDate.isSame(moment(documentation.dateCreated), 'month')
		        })
	        }
	        if (params.template != null) {
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return documentation.templateId == params.template.id
		        })
	        }
            return filteredDocumentations.length
        } else if (type == 'photos') {
            var filteredDocumentations = documentations.filter(documentation => {
	            return documentation.customerId == selectedCustomerId
	        })
	        if (params.month) {
	            var startDate = moment(params.month)
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return startDate.isSame(moment(documentation.dateCreated), 'month')
		        })
	        }
	        if (params.template != null) {
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return documentation.templateId == params.template.id
		        })
	        }
	        var numberOfPhotos = 0
            filteredDocumentations.forEach(documentation => {
                numberOfPhotos += documentation.photos.length
	        })
	        return numberOfPhotos
        } else if (type == 'datasize') {
            var filteredDocumentations = documentations.filter(documentation => {
	            return documentation.customerId == selectedCustomerId
	        })
	        if (params.month) {
	            var startDate = moment(params.month)
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return startDate.isSame(moment(documentation.dateCreated), 'month')
		        })
	        }
	        if (params.template != null) {
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return documentation.templateId == params.template.id
		        })
	        }
            var size = 0
            filteredDocumentations.forEach(documentation => {
                documentation.photos.forEach(photo => {
                    size += photo.dataSize
                })
	        })
	        return formatSize(size)
        }
    }

    const formatSize = function(size) {
	    if (size) {
			var i = Math.floor( Math.log(size) / Math.log(1000) );
	        return ( size / Math.pow(1000, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
	    }
        return 0
    }

    const getOptions = () => {
        return {
	        maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: function(item, data) {
                        var result = data.datasets[item.datasetIndex].label + ': '
                        if (type == 'datasize') {
                            result += formatSize(item.yLabel)
                        } else {
                            result += item.yLabel
                        }
                        return result
                    }
                }
	        },
	        scales: {
	            xAxes: [{
	                type: 'time',
	                time: {
	                    unit: 'month',
	                    tooltipFormat: 'YYYY-MM',
	                    displayFormats: {
	                        millisecond: 'HH:mm:ss.SSS',
	                        second: 'HH:mm:ss',
	                        minute: 'HH:mm',
	                        hour: 'HH:mm',
	                    }
	                }
	            }],
	            yAxes: [{
	                ticks: {
	                    callback: function(label, index, labels) {
	                        if (type == 'datasize') {
	                            return formatSize(label);
	                        }
	                        return label
	                    }
	                }
	            }]
	        }
        }
    }

    const getChartData = () => {
        console.log('getChartData')
        var months = getMonths().reverse()
        var datasets = []
        var tmp = [{
            id: null,
            name: t('customerstatistics.total')
        }]
        tmp.concat(templates).forEach(template => {
			var data = []
	        months.forEach(month => {
	            var filteredDocumentations = documentations.filter(documentation => {
		            return documentation.customerId == selectedCustomerId
		        })
	            var startDate = moment(month)
	            filteredDocumentations = filteredDocumentations.filter(documentation => {
		            return startDate.isSame(moment(documentation.dateCreated), 'month')
		        })
		        if (template.id) {
			        filteredDocumentations = filteredDocumentations.filter(documentation => {
			            return documentation.templateId == template.id
			        })
		        }
		        if (type == 'documentations') {
		            data.push({x: month.getTime(), y: filteredDocumentations.length})
		        } else if (type == 'photos') {
					var numberOfPhotos = 0
		            filteredDocumentations.forEach(documentation => {
		                numberOfPhotos += documentation.photos.length
			        })
			        data.push({x: month.getTime(), y: numberOfPhotos})
		        } else if (type == 'datasize') {
					var size = 0
		            filteredDocumentations.forEach(documentation => {
		                documentation.photos.forEach(photo => {
		                    size += photo.dataSize
		                })
			        })
			        data.push({x: month.getTime(), y: size})
		        }
	        })
	        datasets.push({
	            label: template.name,
	            lineTension: 0,
	            data: data,
	            fill: false,
	            borderColor: colorArray[datasets.length],
	            pointBorderColor: colorArray[datasets.length],
	            pointBackgroundColor: colorArray[datasets.length]
	        })
        })
        return {datasets: datasets}
    }

	return (
		<div>
			<Row>
	            <Col sm="3">
					<Form.Group controlId="customer">
		                <Form.Label>
		                    {t('customerstatistics.customer')}
		                </Form.Label>
						<Form.Control required as="select" name="customerId" value={selectedCustomerId} onChange={handleCustomerChange}>
		                    <option value="">{t('customerstatistics.choosecustomer')}</option>
		                    {customers.map(customer => (
		                        <option key={customer.id} value={customer.id}>{customer.name}</option>
		                    ))}
		                </Form.Control>
		            </Form.Group>
                </Col>
            </Row>
            <Row>
	            <Col sm="3">
					<Form.Group controlId="type">
		                <Form.Label>
		                    {t('customerstatistics.type')}
		                </Form.Label>
						<Form.Control required as="select" name="type" value={type} onChange={handleTypeChange}>
		                    <option value="documentations">Antal dokumentationer</option>
		                    <option value="photos">Antal foton</option>
		                    <option value="datasize">Storlek</option>
		                </Form.Control>
		            </Form.Group>
                </Col>
            </Row>
            { selectedCustomerId && !loading &&
                <>
		            <Row>
		                <Col sm="12">
							<table className="table">
								<thead>
									<tr>
										<td>{t('customerstatistics.month')} \ {t('customerstatistics.template')}</td>
										{ templates.map(template => (
											<td key={'thead-' + template.id}>{template.name}</td>
										))}
										<td>{t('customerstatistics.total')}</td>
									</tr>
								</thead>
								<tbody>
									{ getMonths().map(month => (
										<tr key={'tr-' + moment(month).format("yyyy-MM")}>
											<td>
												{moment(month).format("yyyy-MM")}
											</td>
											{ templates.map(template => (
												<td key={moment(month).format("yyyy-MM") + '-' + template.id}>
													{getStatistics({month: month, template: template})}
												</td>
											))}
											<td>
												{getStatistics({month: month})}
											</td>
										</tr>
									))}
									<tr>
										<td>Totalt</td>
										{ templates.map(template => (
											<td key={'total-' + template.id}>
												{getStatistics({template: template})}
											</td>
										))}
										<td>
											{getStatistics({})}
										</td>
									</tr>
								</tbody>
							</table>
		                </Col>
		            </Row>
		            <Row>
		                <Col sm="12">
		                    <Line options={getOptions()} data={getChartData()} height={600} />
		                </Col>
		            </Row>
	            </>
            }
            { selectedCustomerId && loading &&
                <Row>
	                <Col sm="12">
						{t('customerstatistics.loading')}
                    </Col>
	            </Row>
            }
        </div>
    )
}

export default CustomerStatistics;