import React, {useState, useEffect} from 'react'
import { Form, Button, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { customerService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faX } from '@fortawesome/free-solid-svg-icons'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const LicenseForm = ({data, handleChange, handleSubmit, validated}) => {

	const [customers, setCustomers] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (customers.length === 0) {
            customerService.getOptions().then(customers => {
                customers.sort((a, b) => a.name.localeCompare(b.name))
	            setCustomers(customers)
	            if (data.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
    })

    const onChange = (e) => {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
                handleChange({target: {type: "file", name: "logo", value: hexString}})
            });
            reader.readAsBinaryString(file)
        }
    }

	const onDateChange = (e) => {
        handleChange({target: {type: "text", name: "expireDate", value: moment(e).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]')}})
    }

    const clearDate = (e) => {
		handleChange({target: {type: "text", name: "expireDate", value: null}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="customerId">
                <Form.Label>
                    {t('license.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleChange}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('license.columns.name')}
                </Form.Label>
                <Form.Control type="text" placeholder={t('license.columns.name')} name="name" value={data.name || ''} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="licenseCode">
                <Form.Label>
                    {t('license.columns.licensecode')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('license.columns.licensecode')} name="licenseCode" value={data.licenseCode} onChange={handleChange} />
            </Form.Group>
			<Form.Group controlId="reverseDocumentationCreation" style={{marginBottom: 0}}>
				<Form.Label>
                    <Form.Check type="checkbox" name="reverseDocumentationCreation" label={t('license.columns.reversedocumentationcreation')} checked={data.reverseDocumentationCreation} onChange={handleChange}/>
                </Form.Label>
			</Form.Group>
			<Form.Group controlId="pauseDocumentations" style={{marginBottom: 0}}>
				<Form.Label>
                    <Form.Check type="checkbox" name="pauseDocumentations" label={t('license.columns.pausedocumentations')} checked={data.pauseDocumentations} onChange={handleChange}/>
                </Form.Label>
			</Form.Group>
			<Form.Group controlId="tempFiles" style={{marginBottom: 0}}>
				<Form.Label>
                    <Form.Check type="checkbox" name="tempFiles" label={t('license.columns.tempfiles')} checked={data.tempFiles} onChange={handleChange}/>
                </Form.Label>
			</Form.Group>
			<Form.Group controlId="demo">
				<Form.Label>
                    <Form.Check type="checkbox" name="demo" label={t('license.columns.demo')} checked={data.demo} onChange={handleChange}/>
                </Form.Label>
			</Form.Group>
			<Form.Group controlId="deletePhotosAfterUpload">
				<Form.Label>
                    {t('license.columns.deletephotosafterupload')}
                </Form.Label>
                <Form.Control required as="select" name="deletePhotosAfterUpload" value={data.deletePhotosAfterUpload} onChange={handleChange}>
                    <option value="UNFORCED">{t('license.unforced')}</option>
                    <option value="FORCED_YES">{t('license.forcedyes')}</option>
                    <option value="FORCED_NO">{t('license.forcedno')}</option>
                </Form.Control>
			</Form.Group>
			<Form.Group controlId="backupPhotosToLibrary">
				<Form.Label>
                    {t('license.columns.backupphotostolibrary')}
                </Form.Label>
                <Form.Control required as="select" name="backupPhotosToLibrary" value={data.backupPhotosToLibrary} onChange={handleChange}>
                    <option value="UNFORCED">{t('license.unforced')}</option>
                    <option value="FORCED_YES">{t('license.forcedyes')}</option>
                    <option value="FORCED_NO">{t('license.forcedno')}</option>
                </Form.Control>
			</Form.Group>
            <Form.Group controlId="logo">
                <Form.Label>
                    {t('license.columns.logo')}
                </Form.Label>
                { data.logo &&
                    <div>
	                    <img src={'data:image/png;base64, ' + data.logo} style={{width: '90%'}} alt=""/>
	                    <Button
				            onClick={(e) => handleChange({target: {type: "file", name: "logo", value: null}})}
				            title={t("crudtable.add")}
				            size="sm"
				            color="info"
				            variant="outline-danger">
				            <FontAwesomeIcon icon={faTrash}/>
				        </Button>
                    </div>
                }
                <Form.Control type="file" accept=".png" name="logo" onChange={onChange} />
                <Form.Control.Feedback type="invalid">
                    {t('sensoricon.errors.datainvalid')}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="expireDate">
				<Form.Label>
                    {t('license.columns.expiredate')}
                </Form.Label>
                <InputGroup>
                    <div style={{width: '400px'}}>
		                <DatePicker
		                    selected={data.expireDate ? moment(data.expireDate).toDate() : null}
		                    dateFormat="yyyy-MM-dd"
		                    onChange={onDateChange}
		                    className="form-control"
		                    showWeekNumbers
		                />
	                </div>
	                { data.expireDate &&
		                <Button variant="outline-danger" onClick={clearDate}>
							<FontAwesomeIcon icon={faX}/>
				        </Button>
			        }
		        </InputGroup>
			</Form.Group>
        </Form>
    )
}

export default LicenseForm