import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { customerService, expectedAnswerListService, expectedAnswerImportConfigurationService } from '../../_services'

const ExpectedAnswerImportConfigurationForm = ({data, handleChange, handleSubmit, validated}) => {

	const [expectedAnswerLists, setExpectedAnswerLists] = useState([])
	const [expectedAnswerImportConfigurations, setExpectedAnswerImportConfigurations] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        expectedAnswerListService.list(1000).then(expectedAnswerLists => {
            expectedAnswerLists.sort((a, b) => {
                if (a.name != b.name) {
                    return a.name.localeCompare(b.name)
                }
                return a.customerName.localeCompare(b.customerName)
            })
            setExpectedAnswerLists(expectedAnswerLists)
            if (data.expectedAnswerList === '' && expectedAnswerLists.length > 0) {
                handleChange({target: {type: "select", name: "expectedAnswerList", value: expectedAnswerLists[0].id}})
            }
        })
        expectedAnswerImportConfigurationService.list(1000).then(expectedAnswerImportConfigurations => {
            expectedAnswerImportConfigurations.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            setExpectedAnswerImportConfigurations(expectedAnswerImportConfigurations)
        })
    }, [])

    function handleTypeChange(e) {
        e.preventDefault()

		var newParams = {}
        if (e.target.value === 'RAMSITE_3GIS') {
            newParams.apiKey = ''
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
        handleChange({target: {type: "select", name: "type", value: e.target.value}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...data.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleExpectedAnswerImportConfigurationChange(e) {
        var expectedAnswerImportConfiguration = expectedAnswerImportConfigurations.find(expectedAnswerImportConfiguration => expectedAnswerImportConfiguration.id == e.target.value)
        handleChange({target: {type: "text", name: "name", value: expectedAnswerImportConfiguration.name}})
        handleChange({target: {type: "text", name: "type", value: expectedAnswerImportConfiguration.type}})
        handleChange({target: {type: "text", name: "frequency", value: expectedAnswerImportConfiguration.frequency}})
        handleChange({target: {type: "object", name: "params", value: {...expectedAnswerImportConfiguration.params}}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            { !data.dateCreated &&
                <Form.Group controlId="exportConfiguration">
                    <Form.Label>
	                    {t('expectedanswerimportconfiguration.copyfrom')}
	                </Form.Label>
					<Form.Control required as="select" name="template" value={-1} onChange={handleExpectedAnswerImportConfigurationChange}>
						<option value={-1}>{t('expectedanswerimportconfiguration.chooseexpectedanswerimportconfiguration')}</option>
	                    {expectedAnswerImportConfigurations.map(expectedAnswerImportConfiguration => (
	                        <option key={expectedAnswerImportConfiguration.id} value={expectedAnswerImportConfiguration.id}>
	                            {expectedAnswerImportConfiguration.name}
	                        </option>
	                    ))}
	                </Form.Control>
                </Form.Group>
            }
            <Form.Group controlId="name">
                <Form.Label>
                    {t('expectedanswerimportconfiguration.columns.name')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('template.columns.name')} name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="expectedAnswerList">
                <Form.Label>
                    {t('expectedanswerimportconfiguration.columns.expectedanswerlist')}
                </Form.Label>
                <Form.Control required as="select" name="expectedAnswerList" value={data.expectedAnswerList} onChange={handleChange}>
                    <option value="">Välj lista</option>
                    {expectedAnswerLists.map(expectedAnswerList => (
                        <option key={expectedAnswerList.id} value={expectedAnswerList.id}>{expectedAnswerList.name} ({expectedAnswerList.customerName})</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('expectedanswerimportconfiguration.columns.type')}
                </Form.Label>
                <Form.Control required as="select" name="type" value={data.type} onChange={handleTypeChange}>
                    <option>RAMSITE_3GIS</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="frequency">
                <Form.Label>
                    {t('expectedanswerimportconfiguration.columns.frequency')}
                </Form.Label>
                <Form.Control required as="select" name="frequency" value={data.frequency} onChange={handleChange}>
                    <option>DAILY</option>
                    <option>WEEKLY</option>
                    <option>MONTHLY</option>
                    <option>YEARLY</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="disabled">
                <Form.Label>
                    <Form.Check type="checkbox" name="disabled" label={t('expectedanswerimportconfiguration.columns.disabled')} checked={data.disabled} onChange={handleChange} />
                </Form.Label>
            </Form.Group>
            <Form.Group controlId="params">
                <Form.Label>
                    {t('expectedanswerimportconfiguration.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(data.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th>
	                                {t('expectedanswerimportconfiguration.params.key')}
	                            </th>
	                            <th>
	                                {t('expectedanswerimportconfiguration.params.value')}
	                            </th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(data.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									{key}
								</td>
								<td>
									<Form.Control type="text" placeholder={key} name="value" value={value} onChange={(e) => handleValueChange(e, key)}/>
								</td>
							</tr>
		                })}
	                </tbody>
                </table>
            </Form.Group>
        </Form>
    )
}

export default ExpectedAnswerImportConfigurationForm