import api from './api.service'

export const productService = {
    getAll
};

const BASE_URL = '/products'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}
