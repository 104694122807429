import React, {useState} from 'react'
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap'
import { fileService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function FileImportButton(props) {

	const [documentation, setDocumentation] = useState(props.documentation)
	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const [selectedTask, setSelectedTask] = useState("")
	const [importedFile, setImportedFile] = useState("")
	const [fileName, setFileName] = useState("")
	const [fileType, setFileType] = useState("")
	const [acceptedTypes, setAcceptedTypes] = useState("")
	const [showNoTaskSelectedError, setShowNoTaskSelectedError] = useState(false)
	const [showNoFileSelectedError, setShowNoFileSelectedError] = useState(false)
	const {t} = useTranslation('common')

	const toggle = () => {
		console.log('toggle')

		setShow(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const handleFileChange = (e) => {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
				setImportedFile(hexString)
				setFileName(file.name)
				setFileType(file.type)
            });
            reader.readAsBinaryString(file)

            setShowNoFileSelectedError(false)
        }
    }

    const handleTaskChange = (e) => {
        let taskId = e.target.value

        setSelectedTask(taskId)

        var task = props.documentationTasks.find(task => task.id == taskId)
        if (task && task.options) {
            setAcceptedTypes(task.options.split(";").map(type => '.' + type))
        } else {
            setAcceptedTypes("")
        }
    }

    const doImport = () => {
        console.log('doImport')

        setShowNoTaskSelectedError(false)
        setShowNoFileSelectedError(false)

        if (selectedTask.length == 0) {
            setShowNoTaskSelectedError(true)
            return
        }

        if (importedFile.length == 0) {
            setShowNoFileSelectedError(true)
            return
        }

        var importRequest = {
            customerId: documentation.customerId,
            documentationId: documentation.id,
            taskId: selectedTask,
            data: importedFile,
            name: fileName,
            type: fileType
        }

        fileService.importFile(importRequest).then(newFile => {
            if (props.callback) {
                props.callback(newFile)
            }

            onHide()
        })
    }

	return (
		<>
			<Button variant="outline-dark" onClick={toggle}>
				<FontAwesomeIcon icon={faFileImport} style={{color: 'gray'}}/> {t('documentation.importfiles')}
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('documentation.importfiles')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="task">
						<Form.Label>
		                    {t('documentation.task')}
		                </Form.Label>
	                    <Form.Control required as="select" name="task" value={selectedTask} onChange={handleTaskChange}>
	                        <option value="">{t('documentation.choosetask')}</option>
	                        { props.documentationTasks.filter(task => task.type == "FILE").sort((a, b) => a.name.localeCompare(b.name)).map(task => {
	                            return <option value={task.id} key={task.id}>{task.name}</option>
	                        })}
		                </Form.Control>
		                { showNoTaskSelectedError &&
							<Col sm="12">
								<Alert variant="danger" style={{marginTop: '5px'}}>
				                    {t('documentation.notaskselectederror')}
				                </Alert>
			                </Col>
						}
	                </Form.Group>
	                <Form.Group controlId="file">
						<Form.Label>
		                    {t('documentation.columns.files')}
		                </Form.Label>
		                <Form.Control type="file" accept={acceptedTypes} name="file" onChange={handleFileChange} multiple disabled={!selectedTask}/>
		                { showNoFileSelectedError &&
							<Col sm="12">
								<Alert variant="danger" style={{marginTop: '5px'}}>
				                    {t('documentation.nofileselectederror')}
				                </Alert>
			                </Col>
						}
	                </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={doImport}>
						{t('documentation.import')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default FileImportButton