import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { customerService } from '../../_services'

const DocumentationAliasForm = ({data, handleChange, handleSubmit, validated}) => {

	const [customers, setCustomers] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (customers.length === 0) {
            customerService.getOptions().then(customers => {
	            setCustomers(customers)
	            if (data.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
    })

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="customerId">
                <Form.Label>
                    {t('documentationalias.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleChange}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('documentationalias.columns.name')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('documentationalias.columns.name')} name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="alias">
                <Form.Label>
                    {t('documentationalias.columns.alias')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('documentationalias.columns.name')} name="alias" value={data.alias} onChange={handleChange} />
            </Form.Group>
        </Form>
    )
}

export default DocumentationAliasForm