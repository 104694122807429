import React, { useState, useEffect } from 'react';
import { authService } from '../_services'
import { Row, Col, Button, Form, Modal, Alert } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { alertActions } from '../_actions/alert.actions'

function Profile() {

	const [validated, setValidated] = useState(false);
	const [state, setState] = useState({password: '', repeatPassword: ''})
	const {t} = useTranslation('common');
	const dispatch = useDispatch();

    const handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value
        setState(prevState => ({
            ...prevState,
	        [name]: value
	    }));
    }

    const handleSubmit = (event) => {
	    event.preventDefault();
	    const form = event.currentTarget;
	    if (form.checkValidity() === false) {
			event.stopPropagation()
	        setValidated(true)
	    } else {
	        authService.updatePassword(state).then(response => {
				dispatch(alertActions.success(t('alerts.updatesuccessful')))
		    })
		    setState({password: '', repeatPassword: ''})
		    setValidated(false)
	    }
	};

	const getPattern = (password) => {
		if (!passwordsMatch()) {
			return "^$"
		}

		if (!containsCapitalLetter(password)) {
			return "^$"
		}

		if (!containsSpecialCharacter(password)) {
			return "^$"
		}

		return null
	}

	const passwordsMatch = () => {
		return state.password == state.repeatPassword
	}

	const containsCapitalLetter = (password) => {
		return /[A-ZÅÄÖ]/.test(password)
	}

	const containsSpecialCharacter = (password) => {
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
		return format.test(password)
	}

    return (
        <div>
		    <Row>
		        <Col sm="4">
		            <h2>
		                {t('profile.label')}
		            </h2>
		            <Alert variant="primary">
		                {t('profile.help')}
		            </Alert>
		            <Form id="password-form" noValidate onSubmit={handleSubmit} validated={validated}>
				        <Form.Group controlId="password">
				            <Form.Label>
				                {t('profile.password')}
				            </Form.Label>
				            <Form.Control
				                type="password"
				                placeholder={t('profile.password')}
				                name="password"
				                value={state.password}
				                onChange={handleChange}
				                required={true}
				                minLength={8}
				                pattern={getPattern(state.password)}
				            />
				            <Form.Control.Feedback type="invalid">
				                <ul>
					                { state.password == '' &&
					                    <li>
					                        {t('profile.emptypassword')}
					                    </li>
					                }
					                { state.password.length < 8 &&
					                    <li>
					                        {t('profile.shortpassword')}
					                    </li>
					                }
					                { !passwordsMatch() &&
					                    <li>
					                        {t('profile.mismatchingpasswords')}
					                    </li>
					                }
					                { !containsCapitalLetter(state.password) &&
					                    <li>
					                        {t('profile.nocapitalletter')}
					                    </li>
					                }
					                { !containsSpecialCharacter(state.password) &&
					                    <li>
					                        {t('profile.nospecialcharacter')}
					                    </li>
					                }
				                </ul>
                            </Form.Control.Feedback>
				        </Form.Group>
				        <Form.Group controlId="repeatpassword">
				            <Form.Label>
				                {t('profile.repeatpassword')}
				            </Form.Label>
				            <Form.Control
				                type="password"
				                placeholder={t('profile.repeatpassword')}
				                name="repeatPassword"
				                value={state.repeatPassword}
				                onChange={handleChange}
				                required={true}
				                minLength={8}
				                pattern={getPattern(state.repeatPassword)}
				            />
				            <Form.Control.Feedback type="invalid">
				                <ul>
					                { state.repeatPassword == '' &&
					                    <li>
					                        {t('profile.emptypassword')}
					                    </li>
					                }
					                { state.repeatPassword.length < 8 &&
					                    <li>
					                        {t('profile.shortpassword')}
					                    </li>
					                }
					                { !passwordsMatch() &&
					                    <li>
					                        {t('profile.mismatchingpasswords')}
					                    </li>
					                }
					                { !containsCapitalLetter(state.repeatPassword) &&
					                    <li>
					                        {t('profile.nocapitalletter')}
					                    </li>
					                }
					                { !containsSpecialCharacter(state.repeatPassword) &&
					                    <li>
					                        {t('profile.nospecialcharacter')}
					                    </li>
					                }
				                </ul>
                            </Form.Control.Feedback>
				        </Form.Group>
				        <Button type="submit">
				            {t('profile.update')}
				        </Button>
			        </Form>
		        </Col>
		    </Row>
	    </div>
    )
}

export default Profile;