import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import { exportConfigurationService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function ExportedToFilter({exportedToFilterList, setExportedToFilterList}) {

	const [internalExportedToFilterList, setInternalExportedToFilterList] = useState(exportedToFilterList)
	const [exportConfigurations, setExportConfigurations] = useState([])
	const {t} = useTranslation('common')

	useEffect(() => {
//		exportConfigurationService.getExportConfigurations().then(data => {
//            setExportConfigurations(data)
//        })
	}, [])


	const toggleExportedToFilter = (e) => {
		let name = e.target.name
		if (internalExportedToFilterList.includes(name)) {
			var newList = internalExportedToFilterList.filter(it => it != name)
			setInternalExportedToFilterList(newList)
			setExportedToFilterList(newList)
		} else {
			var newList = [...internalExportedToFilterList, name]
			setInternalExportedToFilterList(newList)
			setExportedToFilterList(newList)
		}
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					<div>
						<label>
							<input type="checkbox" name="exported" checked={internalExportedToFilterList.includes('exported')} onChange={toggleExportedToFilter}/>
							&nbsp;
							{t('documentation.filter.exported')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="unexported" checked={internalExportedToFilterList.includes('unexported')} onChange={toggleExportedToFilter}/>
							&nbsp;
							{t('documentation.filter.unexported')}
						</label>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default ExportedToFilter;