import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import { authService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function TemplateFilter({templates, templateFilterList, setTemplateFilterList, deletedTemplates, setDeletedTemplates}) {

	const [isSuperAdmin, setIsSuperAdmin] = useState(false)
	const {t} = useTranslation('common')

	useEffect(() => {
		setIsSuperAdmin(authService.hasRoles(['ROLE_SUPER_ADMIN']))
	}, [])

	const toggleTemplateFilter = (e) => {
		let name = e.target.name
		var newTemplateFilterList = []
		if (templateFilterList.includes(name)) {
			newTemplateFilterList = templateFilterList.filter(templateId => templateId != name)
		} else {
			newTemplateFilterList = [...templateFilterList, name]
		}
		setTemplateFilterList(newTemplateFilterList)
	}

	const selectAll = () => {
		setTemplateFilterList(templates.map(template => template.id))
		setDeletedTemplates(true)
	}

	const selectNone = () => {
		setTemplateFilterList([])
		setDeletedTemplates(false)
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '350px'}}>
					<div style={{paddingBottom: '20px'}}>
						<Button variant="outline-dark" size="sm" onClick={selectAll}>
							{t('documentation.filter.selectall')}
						</Button>
						<Button variant="outline-dark" size="sm" onClick={selectNone}>
							{t('documentation.filter.selectnone')}
						</Button>
					</div>
					<div>
						<label>
							<input type="checkbox" name="deletedTemplates" checked={deletedTemplates} onChange={e => setDeletedTemplates(!deletedTemplates)}/>
							&nbsp;
							{t('documentation.filter.deletedtemplates')}
						</label>
					</div>
					<hr style={{marginTop: '0px', marginBottom: '0.5rem'}}/>
					{ templates.sort((a, b) => a.name.localeCompare(b.name)).map(template => {
						return <div key={template.id}>
							<label>
								<input type="checkbox" name={template.id} checked={templateFilterList.includes(template.id)} onChange={toggleTemplateFilter}/>
								&nbsp;
								{template.name}
								{ isSuperAdmin &&
									<>&nbsp;({template.customerName})</>
								}
							</label>
						</div>
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default TemplateFilter;