import React, { useContext } from 'react'
import { Form, Button, Row, Col, Tabs, Tab, Card, InputGroup, Accordion, AccordionContext, AccordionToggle, Dropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faChevronUp, faChevronDown, faCopy } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Node from './node';

function ExportTab({state, exportConfigurations, exportDocumentTypes, exportDocumentConfigurations, handleChange}) {

	const {t} = useTranslation('common')

	const addTemplateExportConfiguration = (e, exportConfiguration) => {
		e.preventDefault()

		var position = 0
        state.templateExportConfigurations.forEach(templateExportConfiguration => {
            position = Math.max(position, templateExportConfiguration.position)
        })

		var templateExportConfiguration = {
            id: uuidv4(),
            customerId: state.customerId,
            exportConfiguration: exportConfiguration.id,
            position: position + 1,
            params: { exportAutomatically: false },
            nodes: []
        }

        if (exportConfiguration.type == "EMAIL") {
            templateExportConfiguration.params.subject = ""
            templateExportConfiguration.params.body = ""
        }

		var newTemplateExportConfigurations = [...state.templateExportConfigurations, templateExportConfiguration]

		handleChange({target: {type: "object", name: "templateExportConfigurations", value: newTemplateExportConfigurations}})
	}

	const removeTemplateExportConfiguration = (e, templateExportConfiguration) => {
        e.preventDefault()

		let newTemplateExportConfigurations = state.templateExportConfigurations.filter(other => other.id != templateExportConfiguration.id)

        handleChange({target: {type: "object", name: "templateExportConfigurations", value: newTemplateExportConfigurations}})
    }

	const addNode = (e, templateExportConfiguration, parent, type) => {
        e.preventDefault()

        var node = {
            id: uuidv4(),
            customerId: state.customerId,
            type: type,
            position: 0,
            params: {},
            children: []
        }

        if (type == "ZIP_FILE") {
            node.params.name = "{DOCUMENTATION_NAME}.zip"
        } else if (type != 'PHOTOS' && type != 'FILES' && type != 'EXTERNAL_FILES') {
            node.params.name = ""
        }

		let newTemplateExportConfigurations = [...state.templateExportConfigurations]

        if (parent) {
            node.parent = parent.id
            parent.children.push(node)

            parent.children.forEach((sibling, index) => {
	            sibling.position = index
	        })
        } else {
            var maxIndex = -1
            templateExportConfiguration.nodes.forEach((root, index) => {
                root.position = index
	            maxIndex = index
	        })
	        node.position = maxIndex + 1

	        node.templateExportConfiguration = templateExportConfiguration.id

	        templateExportConfiguration.nodes.push(node)
        }

        handleChange({target: {type: "object", name: "templateExportConfigurations", value: newTemplateExportConfigurations}})
    }

    const removeNode = (e, templateExportConfiguration, parent, node) => {
        e.preventDefault()

		let newTemplateExportConfigurations = [...state.templateExportConfigurations]

        if (parent) {
            parent.children = parent.children.filter(child => child.id != node.id)

            parent.children.forEach((child, index) => {
	            child.position = index
	        })
        } else {
            templateExportConfiguration.nodes = templateExportConfiguration.nodes.filter(child => child.id != node.id)
            templateExportConfiguration.nodes.forEach((root, index) => {
                root.position = index
	        })
        }

        handleChange({target: {type: "object", name: "templateExportConfigurations", value: newTemplateExportConfigurations}})
    }

    const handleParamsChange = (e, templateExportConfiguration) => {
        console.log('handleParamsChange ' + e.target.name + ', ' + e.target.value + ', ' + e.target.checked)

        if (e.target.type === 'checkbox') {
			templateExportConfiguration.params[e.target.name] = e.target.checked
		} else {
			templateExportConfiguration.params[e.target.name] = e.target.value
		}

		let newTemplateExportConfigurations = [...state.templateExportConfigurations]
		handleChange({target: {type: "object", name: "templateExportConfigurations", value: newTemplateExportConfigurations}})
    }

    const handleNodeParamsChange = (e, node) => {
        console.log('handleNodeParamsChange ' + e.target.name + ', ' + e.target.value + ', ' + e.target.checked)

        if (e.target.type === 'checkbox') {
			node.params[e.target.name] = e.target.checked
		} else {
			node.params[e.target.name] = e.target.value
		}

		console.log(node.params)

		let newTemplateExportConfigurations = [...state.templateExportConfigurations]
		handleChange({target: {type: "object", name: "templateExportConfigurations", value: newTemplateExportConfigurations}})
    }

	return (
		<>
			{ exportConfigurations.length > 0 &&
				<>
					{ state.templateExportConfigurations.map(templateExportConfiguration => {
						const exportConfiguration = exportConfigurations.find(exportConfiguration => exportConfiguration.id == templateExportConfiguration.exportConfiguration)
	                    return <Card key={templateExportConfiguration.id}>
	                        <Card.Body>
		                        <div className="clearfix">
									<div className="float-left">
										<h4>
			                                { exportConfiguration.name }
			                            </h4>
									</div>
									<div className="float-right">
										<Button
								            onClick={(e) => removeTemplateExportConfiguration(e, templateExportConfiguration)}
								            title={t("crudtable.delete.button")}
								            size="sm"
								            color="info"
								            variant="outline-danger">
								            <FontAwesomeIcon icon={faTrash}/>
								        </Button>
									</div>
								</div>
	                            <div>
	                                <Form.Group controlId="exportAutomatically">
		                                <Form.Check
		                                    id={'exportAutomatically-' + templateExportConfiguration.id}
		                                    type="checkbox"
		                                    name="exportAutomatically"
		                                    label={t('templateexportconfiguration.params.exportautomatically')}
		                                    checked={templateExportConfiguration.params.exportAutomatically || false}
		                                    onChange={e => handleParamsChange(e, templateExportConfiguration)}
		                                />
									</Form.Group>
									{ exportConfiguration.type == 'EMAIL' &&
										<>
											<Form.Group controlId="subject">
												<Form.Label>
								                    {t('templateexportconfiguration.params.subject')}
								                </Form.Label>
									            <InputGroup>
									                <Form.Control
									                    type="text"
									                    placeholder={t('templateexportconfiguration.params.subject')}
									                    name="subject"
									                    value={templateExportConfiguration.params.subject || ''}
									                    onChange={e => handleParamsChange(e, templateExportConfiguration)}
									                />
									                <Dropdown>
										                <Dropdown.Toggle variant="primary">
										                </Dropdown.Toggle>
					                                    <Dropdown.Menu>
					                                        <Dropdown.Item onClick={e => handleParamsChange({target: {name: 'subject', value: templateExportConfiguration.params.subject + '{DOCUMENTATION_NAME}'}}, templateExportConfiguration)}>
					                                            DOCUMENTATION_NAME
					                                        </Dropdown.Item>
														</Dropdown.Menu>
					                                </Dropdown>
								                </InputGroup>
							                </Form.Group>
											<Form.Group controlId="body">
												<Form.Label>
								                    {t('templateexportconfiguration.params.body')}
								                </Form.Label>
								                <InputGroup>
									                <Form.Control
									                    as="textarea"
									                    placeholder={t('templateexportconfiguration.params.body')}
									                    name="body"
									                    value={templateExportConfiguration.params.body || ''}
									                    rows={5}
									                    onChange={e => handleParamsChange(e, templateExportConfiguration)}
									                />
									                <Dropdown>
										                <Dropdown.Toggle variant="primary">
										                </Dropdown.Toggle>
					                                    <Dropdown.Menu>
					                                        <Dropdown.Item onClick={e => handleParamsChange({target: {name: 'body', value: templateExportConfiguration.params.body + '{DOCUMENTATION_NAME}'}}, templateExportConfiguration)}>
					                                            DOCUMENTATION_NAME
					                                        </Dropdown.Item>
														</Dropdown.Menu>
					                                </Dropdown>
								                </InputGroup>
											</Form.Group>
										</>
									}
	                            </div>
	                            <div>
	                                {t('templateexportconfiguration.nodes.label')}:
		                            { templateExportConfiguration.nodes.map(node => {
		                                return <Node
		                                    key={node.id}
		                                    node={node}
		                                    templateExportConfiguration={templateExportConfiguration}
		                                    exportConfiguration={exportConfiguration}
		                                    exportDocumentTypes={exportDocumentTypes}
		                                    exportDocumentConfigurations={exportDocumentConfigurations}
		                                    addNode={addNode}
		                                    removeNode={removeNode}
		                                    handleNodeParamsChange={handleNodeParamsChange}
		                                />
		                            })}
	                            </div>
	                            <Dropdown>
						            <Dropdown.Toggle variant="outline-primary">
										{t('templateexportconfiguration.addnode')}
									</Dropdown.Toggle>
									<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
										{ ['SHAREPOINT', 'FTP', 'WEBDAV', 'STOREGATE'].includes(exportConfiguration.type) &&
											<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'FOLDER')}>
												{t('templateexportconfiguration.nodes.folder')}
											</Dropdown.Item>
										}
										<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'ZIP_FILE')}>
											{t('templateexportconfiguration.nodes.zipfile')}
										</Dropdown.Item>
										<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'PHOTOS')}>
											{t('templateexportconfiguration.nodes.photos')}
										</Dropdown.Item>
										<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'FILES')}>
											{t('templateexportconfiguration.nodes.files')}
										</Dropdown.Item>
										<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'EXTERNAL_FILES')}>
											{t('templateexportconfiguration.nodes.externalfiles')}
										</Dropdown.Item>
										<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'EXPORT_DOCUMENT_CONFIGURATION')}>
											{t('templateexportconfiguration.nodes.exportdocumentconfiguration')}
										</Dropdown.Item>
										<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, null, 'EXPORT_DOCUMENT_TYPE')}>
											{t('templateexportconfiguration.nodes.exportdocumenttype')}
										</Dropdown.Item>
									</Dropdown.Menu>
						        </Dropdown>
	                        </Card.Body>
	                    </Card>
	                })}
				</>
			}
	        <Dropdown>
	            <Dropdown.Toggle variant="outline-primary">
					{t('template.addexportconfiguration')}
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					{ exportConfigurations.filter(exportConfiguration => !state.templateExportConfigurations.some(templateExportConfiguration => templateExportConfiguration.exportConfiguration == exportConfiguration.id)).map(exportConfiguration => {
						return <Dropdown.Item onClick={e => addTemplateExportConfiguration(e, exportConfiguration)} key={exportConfiguration.id}>
							{exportConfiguration.name}
						</Dropdown.Item>
					})}
				</Dropdown.Menu>
	        </Dropdown>
        </>
	)
}

export default ExportTab