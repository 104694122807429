import api from './api.service'

export const documentationTaskService = {
    findAllByDocumentation,
    findAllByDocumentationName
};

const BASE_URL = '/documentationTasks'

function findAllByDocumentation(documentationId, max) {
    return api.get(BASE_URL + '?documentation=' + documentationId, {max : max || 1000})
}

function findAllByDocumentationName(documentationName, max) {
	return api.get('/documentationTask/findAllByDocumentationName/' + documentationName, {max : max || 1000, sort: 'name', order: 'asc'})
}
