import React, { useState } from 'react'
import { Form, Button, Row, Col, Tabs, Tab, Card, Dropdown, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faArrowsRotate, faBars, faTableColumns, faFont, faCamera,
	faImage, faX, faChevronUp, faChevronDown, faTableCells } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

function Excel({nodes, addNode, removeNode, onDragEnd, toggleNode, hasParentWithNodeType}) {

	const {t} = useTranslation('common');

	return (
		<div>
			<DragDropContext onDragEnd={result => onDragEnd(result)}>
				<Droppable droppableId="1">
					{ provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{ nodes.map((node, index) => {
								return <Draggable key={node.id} draggableId={node.id} index={index}>
									{ provided => (
										<Node
											provided={provided}
											node={node}
											toggleNode={toggleNode}
											addNode={addNode}
											removeNode={removeNode}
											onDragEnd={onDragEnd}
										/>
									)}
								</Draggable>
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
            </DragDropContext>
            <Dropdown>
				<Dropdown.Toggle variant="outline-primary">
					<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={e => addNode(e, null, 'EACH')}>
						{t('exportdocumentconfiguration.nodetypes.each')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'SHEET')}>
						{t('exportdocumentconfiguration.nodetypes.sheet')}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)

	function Node({node, parent, provided}) {

		const [state, setState] = useState(node.params)

	    const handleChange = e => {
	        var name = e.target.name
            var value = e.target.value

            var newState = {...state}

            if (name == 'DATA') {
                let file = e.target.files[0]

		        if (file) {
		            const reader = new FileReader()
		            reader.addEventListener('load', (event) => {
		                let binaryString = event.target.result;
		                let hexString = btoa(binaryString)
		                newState["DATA"] = hexString
		                newState["TYPE"] = file.type
		                node.params["DATA"] = hexString
		                node.params["TYPE"] = file.type
		                handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
		            });
		            reader.readAsBinaryString(file)
		        }
            } else {
				newState[name] = value
	            node.params[name] = value
            }

            setState(newState)
	    }

		const getIcon = type => {
			switch (type) {
				case 'EACH':
					return <FontAwesomeIcon icon={faArrowsRotate}/>
				case 'ROW':
					return <FontAwesomeIcon icon={faBars}/>
				case 'COLUMN':
					return <FontAwesomeIcon icon={faBars} className="fa-rotate-90"/>
				case 'TEXT':
					return <FontAwesomeIcon icon={faFont}/>
				case 'PHOTO':
					return <FontAwesomeIcon icon={faCamera}/>
				case 'IMAGE':
					return <FontAwesomeIcon icon={faImage}/>
				case 'SHEET':
					return <FontAwesomeIcon icon={faTableCells}/>
				default:
					return <></>
			}
		}

	    return (
	        <div {...provided.draggableProps} ref={provided.innerRef}>
	            <Card>
	                <Card.Body>
		                <div className="clearfix">
							<div className="float-left">
								<span {...provided.dragHandleProps}>
									<FontAwesomeIcon icon={faGripVertical}/>
								</span>
								<span style={{paddingLeft: '20px', paddingRight: '5px'}}>
									{getIcon(node.type)}
								</span>
	                            {node.type}
							</div>
							<div className="float-right">
								{ node.type != 'PAGE_BREAK' &&
									<>
										<Button variant="outline-secondary" size="sm" onClick={e => toggleNode(node)}>
											<FontAwesomeIcon icon={node.collapsed ? faChevronDown : faChevronUp}/>
										</Button>
										&nbsp;
									</>
								}
	                            <Button variant="outline-danger" size="sm" onClick={e => removeNode(e, node, parent)}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
					        </div>
						</div>
						{ !node.collapsed &&
							<>
								{ (node.type == 'SHEET') &&
				                    <>
				                        <Form.Group controlId="name">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.name')}
							                </Form.Label>
							                <Form.Control required name="NAME" value={node.params['NAME']} onChange={handleChange}/>
						                </Form.Group>
				                    </>
				                }
				                { node.type == 'EACH' &&
				                    <>
				                        <Form.Group controlId="in">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.iterateover')}
							                </Form.Label>
											<Form.Control required as="select" name="IN" value={node.params['IN']} onChange={handleChange}>
												<option value={'PHOTOS'}>{t('exportdocumentconfiguration.params.values.photos')}</option>
												<option value={'TASKS'}>{t('exportdocumentconfiguration.params.values.tasks')}</option>
							                </Form.Control>
						                </Form.Group>
						                { node.params['IN'] == 'TASKS' &&
						                    <Form.Group controlId="filter">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.filter')}
								                </Form.Label>
												<Form.Control required as="select" name="FILTER" value={node.params['FILTER'] || 'NONE'} onChange={handleChange}>
													<option value={'NONE'}>{t('exportdocumentconfiguration.params.values.none')}</option>
													<option value={'GROUPS'}>{t('exportdocumentconfiguration.params.values.groups')}</option>
													<option value={'CHILDREN'}>{t('exportdocumentconfiguration.params.values.children')}</option>
								                </Form.Control>
							                </Form.Group>
						                }
				                    </>
				                }
				                { (node.type == 'COLUMN') &&
				                    <>
				                        <Form.Group controlId="backgroundcolor">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.backgroundcolor')}
							                </Form.Label>
							                <Row>
							                    <Col md="4">
													<InputGroup>
														<Form.Control type="color" name="BACKGROUND_COLOR" value={node.params['BACKGROUND_COLOR'] || '#FFFFFF'} onChange={handleChange}/>
								                        { node.params['BACKGROUND_COLOR'] &&
									                        <Button variant="outline-secondary" onClick={e => handleChange({target: {name: 'BACKGROUND_COLOR', value: ''}})}>
									                            <FontAwesomeIcon icon={faX} style={{color: 'red'}}/>
					                                        </Button>
				                                        }
								                    </InputGroup>
								                </Col>
						                    </Row>
						                </Form.Group>
				                    </>
				                }
				                { (node.type == 'CHILD') &&
				                    <>
						                <Form.Group controlId="name">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.name')}
							                </Form.Label>
							                <Form.Control required name="NAME" value={node.params['NAME']} onChange={handleChange}/>
						                </Form.Group>
				                    </>
				                }
				                { node.type == 'TEXT' &&
				                    <>
					                    <Form.Group controlId="text">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.text')}
							                </Form.Label>
							                <TextareaAutosize required className="form-control" placeholder={t('exportdocumentconfiguration.params.keys.text')} name="TEXT" value={node.params['TEXT']} onChange={handleChange}/>
						                </Form.Group>
						                <Row>
						                    <Col md="6">
								                <Form.Group controlId="size">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.size')}
									                </Form.Label>
									                <Form.Control required type="number" name="SIZE" value={node.params['SIZE']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
								            <Col md="6">
								                <Form.Group controlId="color">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.color')}
									                </Form.Label>
													<Form.Control type="color" name="COLOR" value={node.params['COLOR'] || '#000000'} onChange={handleChange}/>
								                </Form.Group>
					                        </Col>
						                </Row>
				                    </>
				                }
				                { node.type == 'PHOTO' &&
				                    <>
						                <Form.Group controlId="width">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.width')}
							                </Form.Label>
							                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH']} onChange={handleChange}/>
						                </Form.Group>
						                <Form.Group controlId="height">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.height')}
							                </Form.Label>
							                <Form.Control type="number" name="HEIGHT" value={node.params['HEIGHT']} onChange={handleChange}/>
						                </Form.Group>
				                    </>
				                }
				                { node.type == 'IMAGE' &&
				                    <>
				                        <Form.Group controlId="data">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.data')}
							                </Form.Label>
							                { node.params['DATA'] &&
							                    <div>
							                        <img src={'data:' + node.params['TYPE'] + ';base64, ' + node.params['DATA']} style={{width: '100px'}} alt=""/>
							                    </div>
							                }
											<Form.Control required={!node.params['DATA']} type="file" accept="image/png, image/jpeg" name="DATA" onChange={handleChange}/>
						                </Form.Group>
						                <Form.Group controlId="width">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.width')}
							                </Form.Label>
							                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH']} onChange={handleChange}/>
						                </Form.Group>
						                <Form.Group controlId="height">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.height')}
							                </Form.Label>
							                <Form.Control type="number" name="HEIGHT" value={node.params['HEIGHT']} onChange={handleChange}/>
						                </Form.Group>
				                    </>
				                }
				                { node.type != 'TEXT' && node.type != 'PHOTO' && node.type != 'IMAGE' &&
				                    <>
				                        <label>
				                            {t('exportdocumentconfiguration.children')}:
				                        </label>
						                <div>
						                    <DragDropContext onDragEnd={result => onDragEnd(result, node)}>
						                        <Droppable droppableId={node.id}>
						                            { provided => (
						                                <div ref={provided.innerRef} {...provided.droppableProps}>
										                    { node.children.map((child, index) => {
										                        return <Draggable key={child.id} draggableId={child.id} index={index}>
										                            { provided => (
											                            <Node
												                            provided={provided}
																			node={child}
																			parent={node}
																		/>
																	)}
																</Draggable>
										                    })}
										                    {provided.placeholder}
									                    </div>
						                            )}
						                        </Droppable>
						                    </DragDropContext>
						                </div>
						                <Dropdown>
											<Dropdown.Toggle variant="outline-primary">
												<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{ node.type == 'EACH' &&
													<>
														{ !hasParentWithNodeType(node, 'SHEET') &&
															<Dropdown.Item onClick={e => addNode(e, node, 'SHEET')}>
																{t('exportdocumentconfiguration.nodetypes.sheet')}
															</Dropdown.Item>
														}
														{ hasParentWithNodeType(node, 'SHEET') &&
															<Dropdown.Item onClick={e => addNode(e, node, 'ROW')}>
																{t('exportdocumentconfiguration.nodetypes.row')}
															</Dropdown.Item>
														}
													</>
												}
												{ node.type == 'SHEET' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'EACH')}>
															{t('exportdocumentconfiguration.nodetypes.each')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'ROW')}>
															{t('exportdocumentconfiguration.nodetypes.row')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'ROW' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'COLUMN')}>
															{t('exportdocumentconfiguration.nodetypes.column')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'COLUMN' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'CHILD')}>
															{t('exportdocumentconfiguration.nodetypes.child')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'PHOTO')}>
															{t('exportdocumentconfiguration.nodetypes.photo')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'IMAGE')}>
															{t('exportdocumentconfiguration.nodetypes.image')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'CHILD' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
													</>
												}
											</Dropdown.Menu>
										</Dropdown>
									</>
								}
							</>
						}
					</Card.Body>
				</Card>
			</div>
	    )
	}
}

export default Excel