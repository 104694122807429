import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { useTranslation } from "react-i18next";

function AddButton(props) {

    const [show, setShow] = useState(false)
    const [copy, setCopy] = useState(JSON.parse(JSON.stringify(props.data)))
    const {t} = useTranslation('common');
    const dispatch = useDispatch();

    function handleConfirm (data) {
        handleClose()
        props.confirmClick(data)
    }

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    const onClick = () => {
        console.log('AddButton onClick')
        console.log(props.data)
        console.log(copy)
        if (props.onClick) {
            props.onClick(props.data)
        } else {
            setCopy(JSON.parse(JSON.stringify(props.data)))
            dispatch(alertActions.clear());
            setShow(true)
        }
    }

    return (
        <>
	        <Button
	            style={{ width: "100px", float: "right" }}
	            onClick={onClick}
	            title={t("crudtable.add")}
	            size="md"
	            color="info"
	            variant="outline-secondary">
	            {t("crudtable.add")} <FontAwesomeIcon icon={faPlus}/>
	        </Button>
	        { props.form &&
		        <ModalForm
		            data={copy}
		            show={show}
		            handleConfirm={handleConfirm}
		            handleClose={handleClose}
		            modalHeader={t("crudtable.add")}
		            modalBody={props.form}
		            size={props.size}
		        />
	        }
        </>
    )

}

export default AddButton