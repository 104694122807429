import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { alertActions } from '../../_actions';
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { useTranslation } from "react-i18next";

function EditButton(props) {

    const [show, setShow] = useState(false)
    const [copy, setCopy] = useState(null)
    const {t} = useTranslation('common');
    const dispatch = useDispatch();

    function handleConfirm (data) {
        handleClose()
        props.confirmClick(data)
    }

    const handleClose = () => {
        setCopy(null)
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.data)
        } else {
            dispatch(alertActions.clear());

            setCopy(JSON.parse(JSON.stringify(props.data)))
            setShow(true)
        }
    }

    return (
        <>
	        <Button
	            onClick={onClick}
	            title={t('crudtable.edit')}
	            size="sm"
	            color="info"
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={faEdit}/>
	        </Button>
	        { props.form &&
		        <ModalForm
		            data={props.data}
		            show={show && copy != null}
		            handleConfirm={handleConfirm}
		            handleClose={handleClose}
		            modalHeader={t('crudtable.edit')}
		            modalBody={props.form}
		            size={props.size}
		        />
	        }
        </>
    )

}

export default EditButton