import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { formMonthService, templateService } from '../../_services'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

function DocumentationGroupList() {

	const [documentationGroups, setDocumentationNames] = useState([])
	const [templates, setTemplates] = useState([])
	const {t} = useTranslation('common');

    useEffect(() => {
        formMonthService.list(10000).then(documentationGroups => {
			setDocumentationNames(documentationGroups)
        })
        templateService.list(1000).then(templates => {
            templates.sort((a, b) => {
                if (a.position != b.position) {
                    return a.position - b.position
                }
                return a.name.localeCompare(b.name)
            })
            setTemplates(templates)
        })
    }, [])

    const formatName = function(cell, row) {
		return <a href={'/formMonth/' + cell}>{cell}</a>
	}

	const formatAlias = function(cell, row) {
		return cell || '-'
	}

	const formatTemplates = function(cell, row) {
        if (row.templateIds && row.templateIds.length > 0) {
			var templateNames = []
			row.templateIds.forEach(templateId => {
				var template = templates.find(template => template.id == templateId)
				if (template) {
					templateNames.push(template.name)
				}
			})
            return templateNames.join(", ")
        }
		return '-'
	}

	const formatDate = function(cell, row) {
        if (cell) {
            return moment(cell).format('yyyy-MM-DD HH:mm')
        }
		return '-'
	}

    const { SearchBar } = Search;

    const pagination = paginationFactory({
		sizePerPage: 25,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		onPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		onSizePerPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		paginationTotalRenderer: (from, to, size) => {
			return ' ' + t('crudtable.total', {from: from, to: to, size: size})
		},
		nextPageTitle: t('crudtable.next'), // the title of next page button
		prePageTitle: t('crudtable.previous'), // the title of previous page button
		firstPageTitle: t('crudtable.first'), // the title of first page button
		lastPageTitle: t('crudtable.last')
    })

    const columns = [
	    {
			dataField: 'name',
			text: t('documentation.columns.name'),
			formatter: formatName,
			sort: true
	    },
	    {
			dataField: 'alias',
			text: t('documentation.columns.alias'),
			formatter: formatAlias,
			sort: true
	    },
	    {
			dataField: 'templates',
			text: t('documentationcomparison.columns.templates'),
			formatter: formatTemplates,
			filterValue: formatTemplates,
			sort: true
	    },
	    {
			dataField: 'maxDate',
			text: t('documentationcomparison.columns.date'),
			formatter: formatDate,
			filterValue: formatDate,
			sort: true
	    }
    ]

	return (
		<div>
			{ documentationGroups.length > 0 && templates.length > 0 &&
				<ToolkitProvider
					bootstrap4
					keyField='id'
					data={documentationGroups}
					columns={columns}
					search
				>
	                { toolkitprops => (
	                    <div>
	                        <SearchBar {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
	                        <BootstrapTable
								remote={{ filter: false, search: false, pagination: false }}
								condensed={true}
								pagination={pagination}
								{...toolkitprops.baseProps}
								onTableChange={() => console.log("onTableChange")}
								sort={{dataField: 'name', order: 'asc'}}
	                        />
	                    </div>
	                )}
		        </ToolkitProvider>
	        }
        </div>
    )
}

export default DocumentationGroupList;