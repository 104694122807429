import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, Form, Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Access from './access.component';
import { useTranslation } from "react-i18next";
import { authService } from '../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

function PageNavbar() {

	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');

	function isActive(component) {
		var split = window.location.pathname.split('/')
		return split[1] == component
	}

	const getUserName = () => {
		if (authService.hasRoles(['ROLE_SWITCH_CUSTOMER'])) {
			var currentUser = authService.getCurrentUser()
			return <>
	            <FontAwesomeIcon icon={faUser}/> {currentUser.username} ({currentUser.customerName})
	        </>
		}
		return <>
            <FontAwesomeIcon icon={faUser}/> {authService.getCurrentUser().username}
        </>
    }

    const onHideLanguageModal = () => {
		setShowLanguageModal(false)
		setBackdrop(false)
    }

    const changeLanguage = (newLanguage) => {
        localStorage.setItem("i18nextLng", newLanguage);
        window.location.reload()
    }

    return (
        <>
	        <Navbar bg="light" expand="lg">
		        <Navbar.Brand as={Link} to={"/"}>
		            <img src="/logo-small.png" style={{height: 24}}/>
		        </Navbar.Brand>
		        <Navbar.Toggle aria-controls="basic-navbar-nav" />
		        <Navbar.Collapse id="basic-navbar-nav">
		            <Nav className="mr-auto">
		                <Access permission={["ROLE_DOCUMENTATION_READ"]}>
			                <Nav.Link as={Link} to={"/documentation"} active={isActive("documentation")}>
			                    {t('navbar.documentation')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_DOCUMENTATION_READ"]} products={["DOCUMENTATION_GROUP"]}>
			                <Nav.Link as={Link} to={"/documentationGroup"} active={isActive("documentationGroup")}>
			                    {t('navbar.documentationgroup')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_DOCUMENTATION_READ"]} products={["DOCUMENTATION_COMPARISON"]}>
			                <Nav.Link as={Link} to={"/documentationComparison"} active={isActive("documentationComparison")}>
			                    {t('navbar.documentationcomparison')}
			                </Nav.Link>
		                </Access>
		                 <Access permission={["ROLE_DOCUMENTATION_WRITE"]} products={["MERGE_DOCUMENTATIONS"]}>
		                    <Nav.Link as={Link} to={"/mergeDocumentations"} active={isActive("mergeDocumentations")}>
		                        {t('navbar.mergedocumentations')}
		                    </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_DOCUMENTATION_READ"]} products={["FORM_MONTH"]}>
			                <Nav.Link as={Link} to={"/formMonth"} active={isActive("formMonth")}>
			                    {t('navbar.formmonth')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_DOCUMENTATION_READ"]} products={["MAP"]}>
			                <Nav.Link as={Link} to={"/map"} active={isActive("map")}>
			                    {t('navbar.map')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_TEMPLATE_READ"]}>
			                <Nav.Link as={Link} to={"/template"} active={isActive("template")}>
			                    {t('navbar.template')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_GRAPHIC_READ"]} products={["GRAPHIC"]}>
			                <Nav.Link as={Link} to={"/graphic"} active={isActive("graphic")}>
			                    {t('navbar.graphic')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_SUGGESTION_LIST_READ"]}>
			                <Nav.Link as={Link} to={"/suggestionList"} active={isActive("suggestionList")}>
			                    {t('navbar.suggestionlist')}
			                </Nav.Link>
		                </Access>
		                <Access permission={["ROLE_EXPECTED_ANSWER_LIST_WRITE"]}>
			                <Nav.Link as={Link} to={"/expectedAnswerList"} active={isActive("expectedAnswerList")}>
			                    {t('navbar.expectedanswerlist')}
			                </Nav.Link>
		                </Access>
			            <Access permission={["ROLE_SUPER_ADMIN"]}>
			                <NavDropdown title="Admin" id="basic-nav-dropdown">
			                    <NavDropdown.Item as={Link} to={"/customer"} active={isActive("customer")}>
			                        {t('navbar.customer')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/customerstatistics"} active={isActive("customerstatistics")}>
			                        {t('navbar.customerstatistics')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/user"} active={isActive("user")}>
			                        {t('navbar.user')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/license"} active={isActive("license")}>
			                        {t('navbar.license')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/demo"} active={isActive("demo")}>
			                        {t('navbar.demo', 'Demo')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/exportconfiguration"} active={isActive("exportconfiguration")}>
			                        {t('navbar.exportconfiguration')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/exportDocumentConfiguration"} active={isActive("exportDocumentConfiguration")}>
			                        {t('navbar.exportdocumentconfiguration', 'Export Document Configurations')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/suggestionImport"} active={isActive("suggestionImport")}>
			                        {t('navbar.suggestionimport')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/expectedAnswerImport"} active={isActive("expectedAnswerImport")}>
			                        {t('navbar.expectedanswerimport')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/documentationalias"} active={isActive("documentationalias")}>
			                        {t('navbar.documentationalias')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/photoalias"} active={isActive("photoalias")}>
			                        {t('navbar.photoalias')}
			                    </NavDropdown.Item>
		                        <NavDropdown.Item as={Link} to={"/domainChangeLog"} active={isActive("domainChangeLog")}>
		                            {t('navbar.domainchangelog')}
		                        </NavDropdown.Item>
		                        <NavDropdown.Item as={Link} to={"/changeLog"} active={isActive("changeLog")}>
		                            {t('navbar.changelog')}
		                        </NavDropdown.Item>
		                        <NavDropdown.Item as={Link} to={"/copyTemplate"} active={isActive("copyTemplate")}>
			                        {t('navbar.copytemplate')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/copyGraphic"} active={isActive("copyGraphic")}>
			                        {t('navbar.copygraphic')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/changeTemplate"} active={isActive("changeTemplate")}>
			                        {t('navbar.changetemplate')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/deleteDocumentations"} active={isActive("deleteDocumentations")}>
			                        {t('navbar.deletedocumentations')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/documentationTaskFilter"} active={isActive("documentationTaskFilter")}>
			                        {t('navbar.documentationtaskfilter')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/device"} active={isActive("device")}>
			                        {t('navbar.device', 'Devices')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/tempFile"} active={isActive("tempFile")}>
			                        {t('navbar.tempfile', 'Tempfiler')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/lastAuthenticationSuccessEvent"} active={isActive("lastAuthenticationSuccessEvent")}>
			                        {t('navbar.lastauthenticationsuccessevent')}
			                    </NavDropdown.Item>
			                    <NavDropdown.Item as={Link} to={"/quartz"} active={isActive("quartz")}>
			                        {t('navbar.quartz', 'Quartz')}
			                    </NavDropdown.Item>
			                </NavDropdown>
			            </Access>
		            </Nav>
		            { authService.isAuthenticated() &&
			            <Form inline>
			                <Nav>
				                <NavDropdown alignRight title={getUserName()} id="basic-nav-dropdown">
				                    <Access permission={["ROLE_SUPER_ADMIN"]}>
					                    <NavDropdown.Item as={Link} to={"/switchuser"}>
					                        {t('navbar.switchuser')}
					                    </NavDropdown.Item>
				                    </Access>
				                    <Access permission={["ROLE_SWITCHED_USER"]}>
						                <NavDropdown.Item as={Link} to={"/switchback"}>
					                        {t('navbar.switchback')}
					                    </NavDropdown.Item>
			                        </Access>
			                        <Access permission={["ROLE_SWITCH_CUSTOMER"]}>
						                <NavDropdown.Item as={Link} to={"/switchCustomer"}>
					                        {t('navbar.switchcustomer')}
					                    </NavDropdown.Item>
			                        </Access>
			                        <NavDropdown.Item as={Link} to={"/profile"}>
				                        {t('navbar.profile')}
				                    </NavDropdown.Item>
			                        <NavDropdown.Item onClick={() => setShowLanguageModal(true)}>
				                        {t('navbar.language')}
				                    </NavDropdown.Item>
				                    <NavDropdown.Item as={Link} to={"/changeLog"}>
				                        {t('navbar.changelog')}
				                    </NavDropdown.Item>
				                    <NavDropdown.Item as={Link} to={"/logout"}>
				                        {t('navbar.logout')}
				                    </NavDropdown.Item>
				                </NavDropdown>
			                </Nav>
			            </Form>
		            }
		        </Navbar.Collapse>
	        </Navbar>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showLanguageModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHideLanguageModal}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('navbar.language')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{marginBottom: '10px'}}>
						<a href="javascript:void(0)" onClick={() => changeLanguage('sv')} style={{color: '#373a3c'}}>
							<img src="/flags/swedish.png" style={{width: '36px'}}/> Svenska
						</a>
					</div>
					<div>
						<a href="javascript:void(0)" onClick={() => changeLanguage('en')} style={{color: '#373a3c'}}>
							<img src="/flags/english.png" style={{width: '36px'}}/> English
						</a>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={onHideLanguageModal}>
						{t('crudtable.cancel')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default PageNavbar;