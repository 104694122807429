import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { fileService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function EditButton(props) {

	const [state, setState] = useState(props.data)
	const [validated, setValidated] = useState(false)
    const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
    const {t} = useTranslation('common')

    const onClick = () => {
        setState(props.data)
        setValidated(false)
        setShow(true)
    }

    const handleConfirm = () => {
        handleClose()
        fileService.update(state.id, state).then(result => {
            props.onUpdate(result)
        })
    }

    const handleSubmit = (event) => {
	    event.preventDefault()
	    const form = event.currentTarget
	    if (form.checkValidity() === false) {
	        event.stopPropagation()
	        setValidated(true)
	    } else {
	        handleConfirm()
			setValidated(false)
	    }
	}

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
	    }));
	}

    return (
        <>
	        <Button
	            onClick={onClick}
	            title={t('crudtable.edit')}
	            size="sm"
	            color="info"
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={faEdit}/>
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('crudtable.edit')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
						<Form.Group controlId="name">
			                <Form.Label>
			                    {t('photo.columns.name')}
			                </Form.Label>
			                <Form.Control required type="text" name="name" value={state.name} onChange={handleChange} />
			            </Form.Group>
		            </Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' type="submit" variant="primary">
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default EditButton