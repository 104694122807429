import React from 'react'
import {Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function DeleteForm(props) {

    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    return (
        <Form id="data-form"  onSubmit={handleSubmit}>
            {t('crudtable.delete.confirmation')}
        </Form>
    )

}

export default DeleteForm