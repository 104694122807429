import React from 'react'
import {Form} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const ChangeLogForm = ({data, handleChange, handleSubmit, validated}) => {

    const {t} = useTranslation('common');

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="versioncode">
                <Form.Label>
                    {t('changelog.columns.versioncode')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('changelog.columns.versioncode')}  name="versionCode" value={data.versionCode} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="description">
                <Form.Label>
                    {t('changelog.columns.description')}
                </Form.Label>
                <Form.Control required as="textarea" rows="5" placeholder={t('changelog.columns.description')}  name="description" value={data.description} onChange={handleChange} />
            </Form.Group>
        </Form>
    )
}

export default ChangeLogForm