import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message, params) {
    return { type: alertConstants.SUCCESS, message: message, params: params };
}

function error(message, params) {
    return { type: alertConstants.ERROR, message: message, params: params };
}

function clear() {
    return { type: alertConstants.CLEAR };
}