import api from './api.service'

export const lastAuthenticationSuccessEventService = {
    getLastAuthenticationSuccessEvent
};

const BASE_URL = '/authenticationSuccessEvent'

function getLastAuthenticationSuccessEvent() {
    return api.get(BASE_URL + '/getLastAuthenticationSuccessEvent')
}
