import api from './api.service'

export const userService = {
    list,
    save,
    update,
    _delete
};

const BASE_URL = '/users'

function list() {
    return api.get(BASE_URL)
}

function save(user) {
    return api.post(BASE_URL, user)
}

function update(id, user) {
    return api.put(BASE_URL + "/" + id, user)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
