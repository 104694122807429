import React, { useState, useEffect } from 'react'
import { exportDocumentConfigurationService } from '../../_services'
import { Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import { SERVER_URL } from '../../config';

function TestConfiguration(props) {

	const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
	const [selectedDocumentation, setSelectedDocumentation] = useState('');
    const {t} = useTranslation('common')

	const onClick = () => {
		if (localStorage.getItem("exportDocumentConfiguration-documentId")) {
            setSelectedDocumentation(localStorage.getItem("exportDocumentConfiguration-documentId"))
        }

		setShow(true)
		setBackdrop(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const confirm = () => {
        localStorage.setItem("exportDocumentConfiguration-documentId", selectedDocumentation);
		window.location.href = SERVER_URL + '/exportDocumentConfiguration/test/?documentation=' + selectedDocumentation + '&exportDocumentConfiguration=' + props.configuration.id
        onHide()
    }

	return (
		<>
			<Button variant="outline-dark" size="sm" onClick={onClick} title="Kopiera konfiguration">
				<FontAwesomeIcon icon={faFlask}/>
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Testa konfiguration
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="documentation">
	                    <Form.Label>
		                    Id
		                </Form.Label>
						<Form.Control required type="text" placeholder="Id" name="name" value={selectedDocumentation} onChange={e => setSelectedDocumentation(e.target.value)}/>
	                </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={confirm}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default TestConfiguration