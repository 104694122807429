import React, { useEffect } from 'react';
import { authService } from '../../_services'
import { history } from '../../_helpers';

function SwitchBack() {

    useEffect(() => {
		authService.switchBack().then(response => {
				history.push("/")
			}, error => {
				history.push("/forbidden")
			})
    }, [])

	return (
		<div>
        </div>
    )
}

export default SwitchBack;