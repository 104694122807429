import { authService } from '../_services/'

export function authHeader() {
    // return authorization header with jwt token
    let user = authService.getCurrentUser()
    
    if (user && user.access_token) {
        return user.access_token
    } else {
        return "";
    }
}