import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../config';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { formMonthService, templateService } from '../../_services'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

function DocumentationGroupDetails() {

	const {documentationName} = useParams();
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [min, setMin] = useState(moment());
	const [max, setMax] = useState(moment());
	const [month, setMonth] = useState(moment().month());
	const [year, setYear] = useState(moment().year());
	const [documentations, setDocumentations] = useState([]);
	const [documentationTasks, setDocumentationTasks] = useState([]);
	const [alias, setAlias] = useState('');
    const {t} = useTranslation('common');
    const dateFormat = 'yyyy-MM-DD HH:mm:ss'

    useEffect(() => {
        formMonthService.findAllByDocumentationName(documentationName).then(data => {
            setDocumentations(data.documentations)
            setDocumentationTasks(data.documentationTasks)

            if (data.documentations.length > 0) {
                setAlias(data.documentations[0].alias)
            }

            templateService.list().then(templates => {
	            templates = templates.filter(template => data.documentations.some(documentation => documentation.templateId == template.id))
				setTemplates(templates)
				setSelectedTemplate(templates[0].id)
	        })
        })
    }, [])

    useEffect(() => {
        if (selectedTemplate) {
			var startDate = ''
			var endDate = ''
			var filterDocumentations = documentations.filter(documentation => documentation.templateId == selectedTemplate)
			filterDocumentations.forEach(documentation => {
				if (startDate == '' || documentation.dateCreated < startDate) {
					startDate = documentation.dateCreated
				}
				if (endDate == '' || documentation.dateCreated > endDate) {
					endDate = documentation.dateCreated
				}
			})
			setMin(moment(startDate))
			var max = moment(endDate)
			setMax(max)

			setMonth(max.month())
			setYear(max.year())
		}
    }, [selectedTemplate])

    function handleTemplateChange(e) {
        console.log('handleTemplateChange')
        setSelectedTemplate(e.target.value)
    }

    function handleMonthChange(e) {
        console.log('handleMonthChange')
        setMonth(e.target.value)
    }

    function handleYearChange(e) {
        console.log('handleYearChange')
        let newYear = e.target.value
		setYear(newYear)
		if (moment().set('year', newYear).set('month', month).isBefore(min)) {
			setMonth(min.month())
		} else if (moment().set('year', newYear).set('month', month).isAfter(max)) {
			setMonth(max.month())
		}
    }

    const filterDocumentations = () => {
        var filterDocumentations = documentations.filter(documentation => documentation.templateId == selectedTemplate)
        console.log(filterDocumentations)
        var startOfMonth = moment().set('year', year).set('month', month).startOf('month')
		return filterDocumentations.filter(documentation => moment(documentation.dateCreated).isSame(startOfMonth, 'month'))
    }

    const prev = () => {
		var prevMonth = moment().set('year', year).set('month', month).subtract('month', 1)
		setMonth(prevMonth.month())
		setYear(prevMonth.year())
    }

    const next = () => {
		var nextMonth = moment().set('year', year).set('month', month).add('month', 1)
		setMonth(nextMonth.month())
		setYear(nextMonth.year())
    }

    const getMonthOptions = () => {
        var months = []
        var date = moment().set('year', year).startOf('year')
        var startOfMin = moment(min).startOf('month')
        for (var i = 0; i < 12; i++) {
            if (date.isSameOrAfter(startOfMin) && date.isBefore(max)) {
				months.push(date.month())
            }
            date.add('month', 1)
        }
		return months
    }

    const getYearOptions = () => {
        var years = []
        for (var i = min.year(); i <= max.year(); i++) {
            years.push(i)
        }
		return years
    }

    const prevButtonDisabled = () => {
        let prevMonth = moment().set('year', year).set('month', month).startOf('month').subtract('month', 1)
        if (prevMonth.isSame(min, 'month')) {
            return false
        }
        return prevMonth.isBefore(min)
    }

    const nextButtonDisabled = () => {
        return moment().set('year', year).set('month', month).startOf('month').add('month', 1).isAfter(max)
    }

    const exportExcelFile = () => {
		console.log('exportExcelFile')
		var documentation = documentations[0]
		var startDate = moment().set('month', month).set('year', year).startOf('month').format('yyyy-MM-DD[T]HH:mm:ss[Z]')
		var endDate = moment().set('month', month).set('year', year).endOf('month').format('yyyy-MM-DD[T]HH:mm:ss[Z]')
		window.location.href = SERVER_URL + '/formMonth/exportExcelFile/' + documentation.id + '?template=' + selectedTemplate + '&startDate=' + startDate + '&endDate=' + endDate
    }

	return (
		<div style={{width: '100%'}}>
			<div className="clearfix">
				<div className="float-left">
					<h1>
						{documentationName} {alias ? '(' + alias + ')' : ''}
					</h1>
	            </div>
				<div className="float-right">
					<Button variant="outline-dark" onClick={exportExcelFile}>
						<FontAwesomeIcon icon={faFileExcel} style={{color: '#1f6e43'}}/> {t('formmonth.downloadexcelfile')}
					</Button>
				</div>
			</div>
			<div style={{marginBottom: '10px'}}>
				<Form.Control required as="select" name="template" value={selectedTemplate} onChange={handleTemplateChange} style={{width: '400px', display: 'inline'}}>
	                {templates.map(template => (
	                    <option key={template.id} value={template.id}>
	                        {template.name}
	                    </option>
	                ))}
	            </Form.Control>
				<Button variant="light" onClick={prev} disabled={prevButtonDisabled()}>
					<FontAwesomeIcon icon={faChevronLeft} style={{color: 'gray'}}/>
				</Button>
				<Form.Control required as="select" name="month" value={month} onChange={handleMonthChange} style={{width: '100px', display: 'inline'}}>
					{ getMonthOptions().map(month => {
						return <option key={month} value={month}>
							{moment().set('month', month).format('MMM')}
						</option>
					})}
                </Form.Control>
                <Form.Control required as="select" name="year" value={year} onChange={handleYearChange} style={{width: '100px', display: 'inline'}}>
					{ getYearOptions().map(year => {
						return <option key={year} value={year}>
							{year}
						</option>
					})}
                </Form.Control>
				<Button variant="light" onClick={next} disabled={nextButtonDisabled()}>
					<FontAwesomeIcon icon={faChevronRight} style={{color: 'gray'}}/>
				</Button>
			</div>
			<div style={{width: '100%', overflowY: 'hidden'}}>
				{ selectedTemplate &&
					<table className="table table-bordered table-sm">
						<thead>
							<tr>
								<th>
									Datum
								</th>
								<th>
									Signatur
								</th>
								{ templates.find(template => template.id == selectedTemplate).tasks.map(task => {
									return <th key={task.id} style={{whiteSpace: 'nowrap'}}>
										{task.name}
									</th>
								})}
							</tr>
						</thead>
						<tbody>
							{ filterDocumentations().map(documentation => {
								return <tr key={documentation.id}>
									<td style={{whiteSpace: 'nowrap'}}>
										{moment(documentation.dateCreated).format(dateFormat)}
									</td>
									<td style={{whiteSpace: 'nowrap'}}>
										{documentation.creator}
									</td>
									{ templates.find(template => template.id == selectedTemplate).tasks.map(templateTask => {
										let documentationTask = documentationTasks.find(documentationTask => documentationTask.templateTaskId == templateTask.id && documentationTask.documentationId == documentation.id)
										if (documentationTask) {
											return <td key={templateTask.id} style={{whiteSpace: 'nowrap'}}>
												{documentationTask.answer || '-'}
											</td>
										}
										return <td key={templateTask.id}>-</td>
									})}
								</tr>
							})}
						</tbody>
					</table>
				}
			</div>
			<div style={{height: '50px'}}>
			</div>
        </div>
    )
}

export default DocumentationGroupDetails;