import api from './api.service'

export const quartzService = {
    list,
    runNow
};

const BASE_URL = '/quartz'

function list() {
    return api.get(BASE_URL)
}

function runNow(jobGroup, jobName) {
    return api.get(BASE_URL + "/runNow?jobGroup=" + jobGroup + "&jobName=" + jobName)
}
