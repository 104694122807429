import React, {useState, useEffect} from 'react';
import { graphicService, customerService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import Select from 'react-select'
import { SERVER_URL } from '../../config'

function CopyGraphic() {

	const [graphics, setGraphics] = useState([])
	const [customers, setCustomers] = useState([])
	const [state, setState] = useState({'name': ''})
	const [showNameEmptyError, setShowNameEmptyError] = useState(false)
	const [showNameNotUniqueError, setShowNameNotUniqueError] = useState(false)
	const dispatch = useDispatch();
	const {t} = useTranslation('common')

	useEffect(() => {
		graphicService.getAll().then(graphics => {
			graphics.sort((a, b) => a.name.localeCompare(b.name))
			setGraphics(graphics)
			if (!state.graphic && graphics.length > 0) {
                handleChange({target: {type: "select", name: "graphic", value: graphics[0].id}})
                handleChange({target: {type: "text", name: "name", value: graphics[0].name}})
            }
	    })
	    customerService.getOptions().then(customers => {
	        customers.sort((a, b) => a.name.localeCompare(b.name))
			setCustomers(customers)
			if (!state.customer && customers.length > 0) {
                handleChange({target: {type: "select", name: "customer", value: customers[0].id}})
            }
	    })
	}, [])

	const getCustomer = (customerId) => {
		var customer = customers.find(customer => customer.id == customerId)
	    if (customer) {
	        return customer.name
	    }
	    return '-'
	}

	const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.value
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
	    }))
	}

	const handleGraphicChange = (graphic) => {
	    handleChange({target: {type: "select", name: "graphic", value: graphic.id}})
	    handleChange({target: {type: "text", name: "name", value: graphic.name}})
	}

	const submit = function(event) {
		console.log('submit')

		event.preventDefault()
        event.stopPropagation()

        console.log(state.graphic)
        console.log(state.customer)
        console.log(state.name)

        if (!state.graphic) {
            return
        }

		setShowNameEmptyError(false)
        if (!state.name) {
            setShowNameEmptyError(true)
            return
        }

		setShowNameNotUniqueError(false)
        var existingGraphic = graphics.find(graphic => graphic.name == state.name && graphic.customerId == state.customer)
        if (existingGraphic) {
            setShowNameNotUniqueError(true)
            return
        }

        graphicService.copy(state.graphic, state.customer, state.name).then(data =>  {
			console.log(data)
			dispatch(alertActions.success(t('alerts.createsuccessful')))

			graphicService.getAll().then(graphics => {
				graphics.sort((a, b) => a.name.localeCompare(b.name))
				setGraphics(graphics)
		    })
		})
	}

	const getOptionLabel = (graphic) => {
        return graphic.name + " (" + getCustomer(graphic.customerId) + ")"
    }

    const getValue = () => {
        return graphics.find(graphic => graphic.id == state.graphic)
    }

	return (
		<Container>
			<h2>
				{t('copygraphic.label')}
			</h2>
			{ graphics && customers &&
				<Row>
					<Col md={{ span: 6, offset: 0 }}>
						<Form id="data-form" onSubmit={submit}>
				            <Form.Group controlId="graphic">
				                <Form.Label>
				                    {t('copygraphic.columns.graphic')} *
				                </Form.Label>
				                <Select placeholder={t('copygraphic.columns.graphic')} value={getValue()} isLoading={graphics.length === 0} options={graphics} getOptionLabel={getOptionLabel} getOptionValue={graphic => graphic.id} onChange={handleGraphicChange}/>
				            </Form.Group>
				            <Form.Group controlId="customer">
				                <Form.Label>
				                    {t('copygraphic.columns.customer')} *
				                </Form.Label>
				                <Form.Control required as="select" placeholder={t('copygraphic.columns.customer')} name="customer" onChange={handleChange}>
				                    { customers.map(customer => (
				                        <option key={customer.id} value={customer.id}>{customer.name}</option>
				                    ))}
				                </Form.Control>
				            </Form.Group>
				            <Form.Group controlId="name">
				                <Form.Label>
				                    {t('copygraphic.columns.name')} *
				                </Form.Label>
				                <Form.Control required type="text" placeholder={t('copygraphic.columns.name')} name="name" value={state.name} onChange={handleChange} />
				                { showNameEmptyError &&
				                    <Alert variant="danger" style={{marginTop: '5px'}}>
				                        Du måste ange ett namn
				                    </Alert>
				                }
				                { showNameNotUniqueError &&
				                    <Alert variant="danger" style={{marginTop: '5px'}}>
				                        Det finns redan en mall med det namnet för den kunden
				                    </Alert>
				                }
				            </Form.Group>
						</Form>
						<Button form='data-form' type="submit" variant="primary">
							{t('copygraphic.copy')}
						</Button>
					</Col>
					<Col md={{ span: 6, offset: 0 }}>
						<img src={SERVER_URL + "/graphic/display/" + state.graphic} style={{maxWidth: '200px'}}/>
					</Col>
				</Row>
			}
		</Container>
	)
}

export default CopyGraphic;