import React, {useState, useEffect} from 'react';
import { authService, customerService, documentationService, documentationTaskService, photoService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Alert, Spinner, Table, Card, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import Select from 'react-select'
import moment from 'moment'
import { SERVER_URL } from '../../config';
import { history } from '../../_helpers';

function MergeDocumentations() {

	const [customers, setCustomers] = useState([])
	const [documentations, setDocumentations] = useState([])
	const [name, setName] = useState('')
	const [selectedCustomer, setSelectedCustomer] = useState('')
	const [selectedDocumentations, setSelectedDocumentations] = useState([])
	const [selectedTasks, setSelectedTasks] = useState([])
	const [selectedPhotos, setSelectedPhotos] = useState([])
	const [validated, setValidated] = useState(false)
	const [isMerging, setIsMerging] = useState(false)
	const [showPhotoModal, setShowPhotoModal] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const [selectedPhoto, setSelectedPhoto] = useState(null)
	const dispatch = useDispatch();
	const {t} = useTranslation('common')

	useEffect(() => {
		if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
		    customerService.getOptions().then(customers => {
			    customers.sort((a, b) => a.name.localeCompare(b.name))
				setCustomers(customers)
		    })
	    } else {
	        documentationService.simpleList().then(documentations => {
				documentations.sort((a, b) => a.name.localeCompare(b.name))
				setDocumentations(documentations)
		    })
	    }
	}, [])

	useEffect(() => {
		if (selectedCustomer) {
			setSelectedDocumentations([])
			setSelectedTasks([])
			documentationService.findAllByCustomerId(selectedCustomer).then(documentations => {
				documentations.sort((a, b) => a.name.localeCompare(b.name))
				setDocumentations(documentations)
		    })
		} else {
			setDocumentations([])
		}
		setSelectedDocumentations([])
		setSelectedTasks([])
		setSelectedPhotos([])
	}, [selectedCustomer])

	const handleCustomerChange = (e) => {
		let value = e.target.value
	    setSelectedCustomer(value)
	}

	const handleDocumentationChange = (e) => {
		var added = []
		var removed = []
		if (e) {
			added = e.filter(documentation => !selectedDocumentations.includes(documentation))
			removed = selectedDocumentations.filter(documentation => !e.includes(documentation))
		} else {
			removed = [...selectedDocumentations]
			setSelectedTasks([])
		}

		setSelectedDocumentations(e || [])

		added.forEach(documentation => {
			if (!documentation.tasks) {
	            documentationTaskService.findAllByDocumentation(documentation.id).then(tasks => {
					documentation.tasks = tasks

				    if (e.length == 1) {
			            setSelectedTasks(tasks)
			        } else {
			            var newTasks = tasks.filter(task => !selectedTasks.some(selectedTask => selectedTask.templateTaskId == task.templateTaskId))
			            var newSelectedTasks = [...selectedTasks, ...newTasks]
			            setSelectedTasks(newSelectedTasks)
			        }

					var newSelectedDocumentations = [...e]
			        setSelectedDocumentations(newSelectedDocumentations)
			    })
		    }
		    if (!documentation.photos) {
		        photoService.getAllDocumentationPhotos(documentation.id).then(photos => {
		            documentation.photos = photos

				    var newSelectedPhotos = [...selectedPhotos, ...photos]
				    setSelectedPhotos(newSelectedPhotos)

					var newSelectedDocumentations = [...e]
				    setSelectedDocumentations(newSelectedDocumentations)
		        })
		    }
		    if (!name) {
	            setName(e[0].name)
	        }
		})

		removed.forEach(documentation => {
			if (documentation.tasks) {
	            var newSelectedTasks = selectedTasks.filter(task => !documentation.tasks.includes(task))
	            setSelectedTasks(newSelectedTasks)
		    }
		    if (documentation.photos) {
		        var newSelectedPhotos = selectedPhotos.filter(photo => !documentation.photos.includes(photo))
	            setSelectedPhotos(newSelectedPhotos)
		    }
		})
	}

	const handleTaskChange = (task) => {
	    console.log(task)
	    var newSelectedTasks = []
	    if (selectedTasks.includes(task)) {
	        newSelectedTasks = selectedTasks.filter(selectedTask => selectedTask.id != task.id)
	    } else if (task.templateTaskId) {
	        newSelectedTasks = selectedTasks.filter(selectedTask => selectedTask.templateTaskId != task.templateTaskId)
	        newSelectedTasks.push(task)
	    } else {
	        newSelectedTasks.push(task)
	    }
	    setSelectedTasks(newSelectedTasks)
	}

	const handlePhotoChange = (photo) => {
	    var newSelectedPhotos = [...selectedPhotos]
	    if (selectedPhotos.includes(photo)) {
	        newSelectedPhotos = selectedPhotos.filter(selectedPhoto => selectedPhoto.id != photo.id)
	    } else {
	        newSelectedPhotos.push(photo)
	    }
	    setSelectedPhotos(newSelectedPhotos)
	}

	const submit = function(event) {
		console.log('submit')

		event.preventDefault()
        event.stopPropagation()

        const form = event.currentTarget
        if (form.checkValidity() === false) {
	        setValidated(true)
	        return
	    }

        if (selectedDocumentations.length == 0) {
            return
        }

        if (selectedTasks.length == 0) {
            return
        }

        var request = {
            name: name,
            documentations: selectedDocumentations.map(documentation => documentation.id),
            tasks: selectedTasks.map(task => task.id),
            photos: selectedPhotos.map(photo => photo.id)
        }

        setIsMerging(true)

        documentationService.mergeDocumentations(request).then(data =>  {
			console.log(data)
			dispatch(alertActions.success(t('alerts.createsuccessful')))

			setIsMerging(false)

			history.push("/documentation/" + data.id)
		})
	}

	const formatDate = function(date) {
		return moment(date).format('YYYY-MM-DD')
	}

	const filterDocumentations = () => {
		if (selectedDocumentations.length > 0) {
			var templateId = selectedDocumentations[0].templateId
			return documentations.filter(documentation => documentation.templateId == templateId)
		}
		return documentations
	}

	const getRootTasks = function() {
		var tasks = []
		selectedDocumentations.forEach(documentation => {
			if (documentation.tasks) {
				documentation.tasks.forEach(documentationTask => {
					if (!documentationTask.parent && !tasks.some(task => task.templateTaskId == documentationTask.templateTaskId)) {
						tasks.push(documentationTask)
					}
				})
			}
		})
		tasks.sort((a, b) => a.position - b.position)
		return tasks
	}

	const getTasks = function() {
		var allTasks = []

		selectedDocumentations.forEach(documentation => {
			if (documentation.tasks) {
				documentation.tasks.forEach(documentationTask => {
					if (!allTasks.some(task => task.templateTaskId == documentationTask.templateTaskId)) {
						allTasks.push(documentationTask)
					} else if (!documentationTask.templateTaskId) {
						allTasks.push(documentationTask)
					}
				})
			}
		})
		allTasks.sort((a, b) => a.position - b.position)

		var result = allTasks.filter(root => root.parent == null)
		result.forEach(task => {
			task.depth = 0
		})

		var i = 0
		while (i < result.length) {
			var task = result[i]
			var children = allTasks.filter(child => child.parent == task.id)
			var childIndex = i + 1
			children.forEach(child => {
				child.depth = task.depth + 1
				result.splice(childIndex, 0, child)
				childIndex++
			})
			i++
		}

		return result
	}

	const getTaskPhotos = (documentation, task) => {
		if (documentation.photos) {
			return documentation.photos.filter(photo => photo.documentationTaskId == task.id)
		}
		return []
	}

	const isRequired = (task) => {
		var required = false

		if (task.type == 'GROUP') {
			var templateTaskSelected = selectedTasks.some(selectedTask => selectedTask.templateTaskId == task.templateTaskId)
			if (!templateTaskSelected) {
				documentations.forEach(documentation => {
					if (documentation.tasks) {
						var children = documentation.tasks.filter(child => child.parent == task.id)
						children.forEach(child => {
							if (child.type == 'GROUP') {
								if (isRequired(child)) {
									required = true
								}
							} else {
								if (selectedTasks.some(selectedTask => selectedTask.templateTaskId == child.templateTaskId)) {
									required = true
								}
							}
						})
					}
				})
			}
		} else {
			var templateTaskSelected = selectedTasks.some(selectedTask => selectedTask.templateTaskId == task.templateTaskId)
			if (!templateTaskSelected) {
				documentations.forEach(documentation => {
					if (documentation.tasks) {
						var templateTasks = documentation.tasks.filter(templateTask => templateTask.templateTaskId == task.templateTaskId)
						templateTasks.forEach(templateTask => {
							if (selectedPhotos.some(selectedPhoto => selectedPhoto.documentationTaskId == templateTask.id)) {
								required = true
							}
						})
					}
				})
			}
		}

		return required
	}

	const onPhotoClick = photo => {
		setSelectedPhoto(photo)
		setShowPhotoModal(true)
		setBackdrop(true)
	}

	const onHide = () => {
		setShowPhotoModal(false)
		setBackdrop(false)
	}

	const selectAll = (documentation) => {
		var newSelectedTasks = [...selectedTasks]
		if (documentation.tasks) {
			documentation.tasks.forEach(task => {
				if (task.templateTaskId) {
					newSelectedTasks = newSelectedTasks.filter(selectedTask => selectedTask.templateTaskId != task.templateTaskId)
				} else {
					newSelectedTasks = newSelectedTasks.filter(selectedTask => selectedTask.id != task.id)
				}
				newSelectedTasks.push(task)
			})
		}
		setSelectedTasks(newSelectedTasks)
	}

	const selectNone = (documentation) => {
		var newSelectedTasks = [...selectedTasks]
		if (documentation.tasks) {
			documentation.tasks.forEach(task => {
				newSelectedTasks = newSelectedTasks.filter(selectedTask => selectedTask.id != task.id)
			})
		}
		setSelectedTasks(newSelectedTasks)
	}

	return (
		<Container fluid>
			<h2>
				{t('mergedocumentations.label')}
			</h2>
			{ customers &&
				<>
					<Form id="data-form" noValidate onSubmit={submit} validated={validated}>
						<Row>
							<Col md="4">
								{ authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
									<Form.Group controlId="customer">
						                <Form.Label>
						                    {t('mergedocumentations.columns.customer')} *
						                </Form.Label>
						                <Form.Control required as="select" placeholder={t('mergedocumentations.columns.customer')} value={selectedCustomer} name="customer" onChange={handleCustomerChange}>
						                    <option value="">Välj kund</option>
						                    { customers.map(customer => (
						                        <option key={customer.id} value={customer.id}>{customer.name}</option>
						                    ))}
						                </Form.Control>
						            </Form.Group>
					            }
					            <Form.Group controlId="documentations">
					                <Form.Label>
					                    {t('mergedocumentations.columns.documentations')} *
					                </Form.Label>
					                <Select
					                    placeholder={t('mergedocumentations.columns.documentations')}
					                    options={filterDocumentations()}
					                    value={selectedDocumentations}
					                    getOptionLabel={documentation => documentation.name + ' (' + formatDate(documentation.dateCreated) + ')'}
					                    getOptionValue={documentation => documentation.id}
					                    isMulti={true}
					                    closeMenuOnSelect={false}
					                    onChange={handleDocumentationChange}
					                />
					            </Form.Group>
					            <Form.Group controlId="name">
					                <Form.Label>
					                    {t('mergedocumentations.columns.name')} *
					                </Form.Label>
					                <Form.Control required type="text" placeholder={t('mergedocumentations.columns.name')} name="name" value={name} onChange={e => setName(e.target.value)} />
					            </Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md="12">
								<Table bordered style={{tableLayout: 'fixed'}}>
									<thead>
										<tr>
											{ selectedDocumentations.map( documentation => {
												return <th key={documentation.id}>
													<div className="clearfix">
														<div className="float-left">
															{documentation.name}
														</div>
														<div className="float-right">
															<Button variant="outline-primary" onClick={e => selectAll(documentation)}>
											                    {t('mergedocumentations.selectall')}
											                </Button>
											                &nbsp;
											                <Button variant="outline-primary" onClick={e => selectNone(documentation)}>
											                    {t('mergedocumentations.selectnone')}
											                </Button>
														</div>
													</div>
												</th>
											})}
										</tr>
									</thead>
									<tbody>
										{ getTasks().map( task => {
											return <tr key={task.templateTaskId || task.id}>
												{ selectedDocumentations.map( documentation => {
													var documentationTask = null
													if (documentation.tasks) {
														if (task.templateTaskId) {
															documentationTask = documentation.tasks.find(documentationTask => documentationTask.templateTaskId == task.templateTaskId)
														} else {
															documentationTask = documentation.tasks.find(documentationTask => documentationTask.id == task.id)
														}
													}
													if (documentationTask) {
														return <td key={documentationTask.id}>
															<div style={{display: 'inline-block', verticalAlign: 'top'}}>
																{'-----'.repeat(task.depth)}
																{ task.depth > 0 &&
																	<>&nbsp;</>
																}
															</div>
															<div style={{display: 'inline-block'}}>
																<Form.Label>
												                    <Form.Check
												                        type="checkbox"
												                        id={'check-' + documentationTask.id}
												                        label={documentationTask.name}
												                        checked={selectedTasks.includes(documentationTask)}
												                        onChange={e => handleTaskChange(documentationTask)}
												                        required={isRequired(documentationTask)}
												                    />
												                </Form.Label>
												                { documentationTask.answer &&
												                    <div>
												                        <b>Svar: </b>
												                        <br/>
												                        {documentationTask.answer}
												                    </div>
												                }
												                { getTaskPhotos(documentation, documentationTask).map(photo => {
												                    return <div key={photo.id}>
												                        <Form.Check
													                        type="checkbox"
													                        id={photo.id}
													                        label={photo.name}
													                        checked={selectedPhotos.includes(photo)}
													                        onChange={e => handlePhotoChange(photo)}
													                    />
													                    <div style={{cursor: 'pointer'}}>
													                        <img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: 200}} onClick={e => onPhotoClick(photo)}/>
													                    </div>
												                    </div>
												                })}
											                </div>
														</td>
													}
													return <td key={task.templateTaskId + '-' + documentation.id}></td>
												})}
											</tr>
										})}
									</tbody>
								</Table>
					            { !isMerging &&
					                <Button form="data-form" type="submit" variant="primary">
					                    {t('mergedocumentations.merge')}
					                </Button>
					            }
					            { isMerging &&
					                <Button variant="primary" disabled>
					                    {t('mergedocumentations.merging')}
							            &nbsp;
							            <Spinner animation="border" role="status" size="sm">
											<span className="sr-only">Loading...</span>
										</Spinner>
					                </Button>
					            }
							</Col>
						</Row>
					</Form>
				</>
			}
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={showPhotoModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedPhoto ? selectedPhoto.name : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ selectedPhoto &&
						<img src={SERVER_URL + '/photo/download/' + selectedPhoto.id} style={{maxWidth: '100%', pointerEvents: 'auto'}}/>
					}
				</Modal.Body>
			</Modal>
		</Container>
	)
}

export default MergeDocumentations;