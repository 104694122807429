import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Jumbotron, Row, Col, Button, Form, Modal } from 'react-bootstrap'
import AlertComponent from './alert.component'
import { useTranslation } from "react-i18next";
import { resetPasswordService } from '../_services'
import { history } from '../_helpers';

function ResetPassword() {

	const { token } = useParams()
	const [state, setState] = useState({ token: token, password: '', repeatPassword: '' })
	const [validated, setValidated] = useState(false);
	const [submitted, setSubmitted] = useState(false)
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation('common')

	const containerStyle = {
		backgroundColor: '#32a1f1',
		backgroundImage: 'url(splash.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
    };
  
	function handleChange(e) {
		let name = e.target.name
		let value = e.target.value
		var newState = {...state}
		newState[name] = value
		setState(newState)
	}

    function handleSubmit(event) {
        event.preventDefault()
	    const form = event.currentTarget

        if (form.checkValidity() === false) {
	        event.stopPropagation()
	        setValidated(true)
	    } else {
		    setLoading(true)
	        resetPasswordService.changePassword(state).then(response => {
		        setLoading(false)
		        setSubmitted(true)
		        setSuccess(response.success)
		    })
	    }
	}

	const getPattern = (password) => {
		if (!passwordsMatch()) {
			return "^$"
		}

		if (!containsCapitalLetter(password)) {
			return "^$"
		}

		if (!containsSpecialCharacter(password)) {
			return "^$"
		}

		return null
	}

	const passwordsMatch = () => {
		return state.password == state.repeatPassword
	}

	const containsCapitalLetter = (password) => {
		return /[A-ZÄÖÅ]/.test(password)
	}

	const containsSpecialCharacter = (password) => {
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
		return format.test(password)
	}

    return (
        <Container fluid className="auth-wrapper" style={containerStyle}>
            <Row>
                <Col md={{ span: 4, offset: 4 } }>
                    <Jumbotron className="auth-inner">
	                    <AlertComponent />
	                    <img src="/logo.png" style={{width: '100%', paddingTop: '1rem', paddingBottom: 40}}/>
	                    { !submitted &&
	                        <>
		                        <div style={{marginBottom: '40px'}}>
									{t('resetpassword.help')}
								</div>
		                        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
		                            <Form.Group controlId="password">
							            <Form.Label>
							                {t('resetpassword.password')}
							            </Form.Label>
							            <Form.Control
							                type="password"
							                placeholder={t('resetpassword.password')}
							                name="password"
							                value={state.password}
							                onChange={handleChange}
							                required={true}
							                minLength={8}
							                pattern={getPattern(state.password)}
							            />
							            <Form.Control.Feedback type="invalid">
							                <ul>
								                { state.password == '' &&
								                    <li>
								                        {t('profile.emptypassword')}
								                    </li>
								                }
								                { state.password.length < 8 &&
								                    <li>
								                        {t('profile.shortpassword')}
								                    </li>
								                }
								                { !passwordsMatch() &&
								                    <li>
								                        {t('profile.mismatchingpasswords')}
								                    </li>
								                }
								                { !containsCapitalLetter(state.password) &&
								                    <li>
								                        {t('profile.nocapitalletter')}
								                    </li>
								                }
								                { !containsSpecialCharacter(state.password) &&
								                    <li>
								                        {t('profile.nospecialcharacter')}
								                    </li>
								                }
							                </ul>
			                            </Form.Control.Feedback>
							        </Form.Group>
							        <Form.Group controlId="repeatpassword">
							            <Form.Label>
							                {t('resetpassword.repeatpassword')}
							            </Form.Label>
							            <Form.Control
							                type="password"
							                placeholder={t('resetpassword.repeatpassword')}
							                name="repeatPassword"
							                value={state.repeatPassword}
							                onChange={handleChange}
							                required={true}
							                minLength={8}
							                pattern={getPattern(state.repeatPassword)}
							            />
							            <Form.Control.Feedback type="invalid">
							                <ul>
								                { state.repeatPassword == '' &&
								                    <li>
								                        {t('profile.emptypassword')}
								                    </li>
								                }
								                { state.repeatPassword.length < 8 &&
								                    <li>
								                        {t('profile.shortpassword')}
								                    </li>
								                }
								                { !passwordsMatch() &&
								                    <li>
								                        {t('profile.mismatchingpasswords')}
								                    </li>
								                }
								                { !containsCapitalLetter(state.repeatPassword) &&
								                    <li>
								                        {t('profile.nocapitalletter')}
								                    </li>
								                }
								                { !containsSpecialCharacter(state.repeatPassword) &&
								                    <li>
								                        {t('profile.nospecialcharacter')}
								                    </li>
								                }
							                </ul>
			                            </Form.Control.Feedback>
							        </Form.Group>

			                        <Form.Group controlId="formLogin">
			                            <button className="btn btn-primary btn-block" disabled={loading}>
											<span>
												{t('resetpassword.button')}
											</span>
											{ loading && (
												<>
													&nbsp;
													<span className="spinner-border spinner-border-sm"></span>
												</>
											)}
			                            </button>
			                        </Form.Group>
			                    </Form>
		                    </>
	                    }
	                    { submitted && success &&
	                        <div>
	                            <span style={{marginBottom: '20px'}}>
									{t('resetpassword.success')}
								</span>
								<button
									className="btn btn-primary btn-block"
									onClick={() => history.push("/login")}
								>
									{t('login.login')}
								</button>
	                        </div>
	                    }
	                    { submitted && !success &&
	                        <div>
								{t('resetpassword.failure')}
	                        </div>
	                    }
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPassword;