import api from './api.service'

export const domainChangeLogService = {
    list,
    save,
    update,
    _delete,
    getObjectClasses
};

const BASE_URL = '/domainChangeLogs'

function list(params) {
    return api.post('/domainChangeLog/index', {...params, max : 1000, sort: 'dateCreated', order: 'desc'})
}

function save(domainChangeLog) {
    return api.post(BASE_URL, domainChangeLog)
}

function update(id, domainChangeLog) {
    return api.put(BASE_URL + "/" + id, domainChangeLog)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function getObjectClasses() {
    return api.get('/domainChangeLog/getObjectClasses')
}
