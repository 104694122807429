import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function UserList(props) {

	const [users, setUsers] = useState([])
	const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
    const {t} = useTranslation('common')

    useEffect(() => {
        if (props.users) {
            props.users.sort((a, b) => a.username.localeCompare(b.username))
        }
    }, []);

	const onClick = () => {
        setShow(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

	return (
		<>
			{ props.users.length > 0 &&
				<>
					{ props.users.length <= 3 &&
						props.users.map(user => user.username).join(", ")
					}
					{  props.users.length > 3 &&
						<span onClick={onClick} style={{cursor: 'pointer'}}>
							{props.users.slice(0, 3).map(user => user.username).join(", ") + "..."}
						</span>
					}
				</>
			}
			{ props.users.length == 0 &&
				<span>-</span>
			}
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('customer.columns.users')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul>
						{ props.users.map(user =>
							<li key={user.id}>{user.username}</li>
						)}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default UserList