import React from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function StatusFilter({statusFilterList, setStatusFilterList}) {

	const {t} = useTranslation('common');

	const toggleStatusFilter = (e) => {
		let name = e.target.name
		if (statusFilterList.includes(name)) {
			setStatusFilterList(statusFilterList.filter(it => it != name))
		} else {
			setStatusFilterList([...statusFilterList, name])
		}
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					<div>
						<label>
							<input type="checkbox" name="DELETED" checked={statusFilterList.includes('DELETED')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.deleted')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="COMPLETED" checked={statusFilterList.includes('COMPLETED')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.completed')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="PAUSED" checked={statusFilterList.includes('PAUSED')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.paused')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="DOWNLOADED" checked={statusFilterList.includes('DOWNLOADED')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.downloaded')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="ONGOING" checked={statusFilterList.includes('ONGOING')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.ongoing')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="HANDLED" checked={statusFilterList.includes('HANDLED')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.handled')}
						</label>
					</div>
					<div>
						<label>
							<input type="checkbox" name="WAITING_FOR_FILE" checked={statusFilterList.includes('WAITING_FOR_FILE')} onChange={toggleStatusFilter}/>
							&nbsp;
							{t('documentation.status.waitingforfile')}
						</label>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default StatusFilter;