import React, { useEffect } from 'react'
import { authService } from '../_services'
import moment from 'moment'

function RefreshToken() {
	useEffect(() => {
        const interval = setInterval(tick, 900000);
        //const interval = setInterval(tick, 10000); //10 seconds

        return () => {
            console.log('clearInterval')
            clearInterval(interval);
        }
	}, []);

	const tick = () => {
		if (authService.isAuthenticated()) {
			console.log('tick ' + moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
			var last_refresh = localStorage.getItem("last_refresh") || 0
			var now = Date.now()
			if (now - last_refresh > 600000) {
				authService.refreshToken().then(response => {
					if (response.access_token) {
		                localStorage.setItem("user", JSON.stringify(response));
		                localStorage.setItem("last_refresh", Date.now())
		            }
				})
			}
		}
	}

    return (
		<></>
    )
}

export default RefreshToken;