import api from './api.service'

export const deviceService = {
    list,
    get,
    _delete
};

const BASE_URL = '/devices'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000})
}

function get(id) {
    return api.get(BASE_URL + "/" + id)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
