import React, {useEffect, useState} from 'react'
import { mapService } from '../../_services'
import { useTranslation } from "react-i18next";
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow, HeatmapLayer, LatLng } from '@react-google-maps/api';
import moment from 'moment'
import { history } from '../../_helpers';

function Map() {

    const [documentations, setDocumentations] = useState([])
	const [map, setMap] = React.useState(null)
	const {t} = useTranslation('common');

    const containerStyle = {
		width: '100%',
		height: '85vh'
    };

    const onLoad = React.useCallback(function callback(map) {
        console.log('onLoad')
	    //const bounds = new window.google.maps.LatLngBounds();
	    //map.fitBounds(bounds);

	    setMap(map)

		var zoom = parseInt(localStorage.getItem("zoom"))
	    var lat = parseFloat(localStorage.getItem("lat"))
	    var lng = parseFloat(localStorage.getItem("lng"))

	    map.setZoom(zoom)
	    map.setCenter({ lat: lat, lng: lng })

	    mapService.list().then(documentations => {
	        console.log(documentations)
	        var existingLatLngs = []
	        documentations.forEach(documentation => {
	            var key = documentation.lat + '-' + documentation.lng
	            while (existingLatLngs.indexOf(key) >= 0) {
	                documentation.lng += 0.00001
	                key = documentation.lat + '-' + documentation.lng
	            }
	            existingLatLngs.push(key)
	        })

	        setDocumentations(documentations)

	        const bounds = new window.google.maps.LatLngBounds();
	        documentations.map(documentation => {
	            if (documentation.lat != 1000 && documentation.lng != 1000) {
	                bounds.extend(documentation)
	            }
	        })
	        map.fitBounds(bounds);

			localStorage.setItem("zoom", map.getZoom());
	        localStorage.setItem("lat", map.getCenter().lat());
	        localStorage.setItem("lng", map.getCenter().lng());
	    })
	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	const handleCenter = () => {
	    if (map === null)
	        return
    }

    const options = {
		imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
		maxZoom: 21
    }

    const onMarkerClick = documentation => {
		console.log(documentation)
		window.open("/documentation/" + documentation.id, '_blank');
		//history.push("/documentation/" + documentation.id);
    }

	return (
		<div>
			<h2>
                {t('map.label')}
            </h2>
            <GoogleMap
		        mapContainerStyle={containerStyle}
		        onLoad={onLoad}
		        onUnmount={onUnmount}
		        onDragEnd={handleCenter}
		        //onZoomChanged={() => console.log(map.getZoom())}
		        options={{ minZoom: 3 }}>
	            <MarkerClusterer options={options}>
		            {(clusterer) =>
		                documentations.filter(documentation => documentation.lat != 1000 && documentation.lng != 1000).map(documentation => (
		                    <Marker
			                    key={documentation.id}
			                    position={documentation}
			                    clusterer={clusterer}
			                    title={documentation.name}
			                    onClick={() => onMarkerClick(documentation)}
			                    marker={documentation}
			                />
		                ))
		            }
                </MarkerClusterer>
			</GoogleMap>
        </div>
	)
}

export default Map;