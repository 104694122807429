import React, { useState } from 'react'
import { Form, Button, Row, Col, Tabs, Tab, Card, Dropdown, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faX, faTableColumns, faArrowsRotate, faFont, faCamera,
	faImage, faChevronUp, faChevronDown, faCopy } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

function PDF({nodes, addNode, removeNode, copyNode, onDragEnd, toggleNode}) {

	const {t} = useTranslation('common');

	return (
		<div>
			<DragDropContext onDragEnd={result => onDragEnd(result)}>
				<Droppable droppableId="1">
					{ provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{ nodes.map((node, index) => {
								return <Draggable key={node.id} draggableId={node.id} index={index}>
									{ provided => (
										<Node
											provided={provided}
											node={node}
											toggleNode={toggleNode}
											addNode={addNode}
											removeNode={removeNode}
											copyNode={copyNode}
											onDragEnd={onDragEnd}
										/>
									)}
								</Draggable>
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
            </DragDropContext>
            <Dropdown>
				<Dropdown.Toggle variant="outline-primary">
					<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={e => addNode(e, null, 'EACH')}>
						{t('exportdocumentconfiguration.nodetypes.each')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'PAGE')}>
						{t('exportdocumentconfiguration.nodetypes.page')}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)

	function Node({node, parent, provided, handleNodeChanges, addNode, removeNode, copyNode, onDragEnd}) {

		const [state, setState] = useState(node.params)

	    const handleChange = e => {
	        var name = e.target.name
            var value = e.target.value

            var newState = {...state}

            if (name == 'DATA') {
                let file = e.target.files[0]

		        if (file) {
		            const reader = new FileReader()
		            reader.addEventListener('load', (event) => {
		                let binaryString = event.target.result;
		                let hexString = btoa(binaryString)
		                newState["DATA"] = hexString
		                newState["TYPE"] = file.type
		                node.params["DATA"] = hexString
		                node.params["TYPE"] = file.type
		                handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
		            });
		            reader.readAsBinaryString(file)
		        }
            } else {
				newState[name] = value
	            node.params[name] = value
            }

            setState(newState)
	    }

	    const addVariableToText = variable => {
	        var newState = {...state}
	        newState['TEXT'] = state['TEXT'] + '{' + variable + '}'
	        node.params['TEXT'] = state['TEXT'] + '{' + variable + '}'
	        setState(newState)
	    }

	    const getIcon = type => {
			switch (type) {
				case 'EACH':
					return <FontAwesomeIcon icon={faArrowsRotate}/>
				case 'PAGE':
					return <FontAwesomeIcon icon={faFile}/>
				case 'COLUMN':
					return <FontAwesomeIcon icon={faTableColumns}/>
				case 'TEXT':
					return <FontAwesomeIcon icon={faFont}/>
				case 'PHOTO':
					return <FontAwesomeIcon icon={faCamera}/>
				case 'IMAGE':
					return <FontAwesomeIcon icon={faImage}/>
				default:
					return <></>
			}
		}

	    return (
	        <div {...provided.draggableProps} ref={provided.innerRef}>
	            <Card>
	                <Card.Body>
		                <div className="clearfix">
							<div className="float-left">
								<span {...provided.dragHandleProps}>
									<FontAwesomeIcon icon={faGripVertical}/>
								</span>
								<span style={{paddingLeft: '20px', paddingRight: '5px'}}>
									{getIcon(node.type)}
								</span>
	                            {node.type}
							</div>
							<div className="float-right">
								{ node.type != 'PAGE_BREAK' &&
									<>
										<Button variant="outline-secondary" size="sm" onClick={e => toggleNode(node)}>
											<FontAwesomeIcon icon={node.collapsed ? faChevronDown : faChevronUp}/>
										</Button>
										&nbsp;
									</>
								}
								<Button variant="outline-primary" size="sm" onClick={e => copyNode(e, node, parent)}>
									<FontAwesomeIcon icon={faCopy}/>
								</Button>
	                            <Button variant="outline-danger" size="sm" onClick={e => removeNode(e, node, parent)}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
					        </div>
						</div>
						{ !node.collapsed &&
							<>
				                { node.type == 'EACH' &&
				                    <>
				                        <Form.Group controlId="in">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.iterateover')}
							                </Form.Label>
											<Form.Control required as="select" name="IN" value={node.params['IN']} onChange={handleChange}>
												<option value={'PHOTOS'}>{t('exportdocumentconfiguration.params.values.photos')}</option>
												<option value={'TASKS'}>{t('exportdocumentconfiguration.params.values.tasks')}</option>
												<option value={'GROUPS'}>{t('exportdocumentconfiguration.params.values.groups')}</option>
							                </Form.Control>
						                </Form.Group>
						                { node.params['IN'] == 'GROUPS' &&
							                <Form.Group controlId="type">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.type')}
								                </Form.Label>
												<Form.Control required as="select" name="POSITION" value={node.params['GROUPS_TYPE']} onChange={handleChange}>
													<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
													<option value={'PRE_POST'}>{t('exportdocumentconfiguration.params.values.prepost')}</option>
								                </Form.Control>
							                </Form.Group>
						                }
				                    </>
				                }
				                { node.type == 'TEXT' &&
				                    <>
					                    <Form.Group controlId="text">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.text')}
							                </Form.Label>
							                <InputGroup className="mb-3">
								                <TextareaAutosize required className="form-control" placeholder={t('exportdocumentconfiguration.params.keys.text')} name="TEXT" value={state['TEXT']} onChange={handleChange}/>
							                    <Dropdown>
											        <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
											        </Dropdown.Toggle>

											        <Dropdown.Menu>
												        <Dropdown.Item onClick={e => addVariableToText('DOCUMENTATION_NAME')}>
												            DOCUMENTATION_NAME
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('CREATOR')}>
												            CREATOR
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('DATE_CREATED')}>
												            DATE_CREATED
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('DATE_UPLOADED')}>
												            DATE_UPLOADED
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('PHOTO_NAME')}>
												            PHOTO_NAME
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('PHOTO_COMMENT')}>
												            PHOTO_COMMENT
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('TASK_NAME')}>
												            TASK_NAME
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('TASK_DESCRIPTION')}>
												            TASK_DESCRIPTION
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('TASK_ANSWER')}>
												            TASK_ANSWER
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('TEMPLATE_NAME')}>
												            TEMPLATE_NAME
												        </Dropdown.Item>
												        <Dropdown.Item onClick={e => addVariableToText('CUSTOMER_NAME')}>
												            CUSTOMER_NAME
												        </Dropdown.Item>
											        </Dropdown.Menu>
											    </Dropdown>
										    </InputGroup>
						                </Form.Group>
						                <Row>
						                    <Col md="2">
								                <Form.Group controlId="size">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.size')}
									                </Form.Label>
									                <Form.Control required type="number" name="SIZE" value={node.params['SIZE']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
							                <Col md="2">
								                <Form.Group controlId="color">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.color')}
									                </Form.Label>
													<Form.Control type="color" name="COLOR" value={node.params['COLOR'] || '#000000'} onChange={handleChange}/>
								                </Form.Group>
							                </Col>
							                <Col md="3">
								                <Form.Group controlId="position">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.position')}
									                </Form.Label>
													<Form.Control required as="select" name="POSITION" value={node.params['POSITION']} onChange={handleChange}>
														<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
														<option value={'TOP_LEFT'}>{t('exportdocumentconfiguration.params.values.topleft')}</option>
														<option value={'TOP'}>{t('exportdocumentconfiguration.params.values.top')}</option>
														<option value={'TOP_RIGHT'}>{t('exportdocumentconfiguration.params.values.topright')}</option>
														<option value={'LEFT'}>{t('exportdocumentconfiguration.params.values.left')}</option>
														<option value={'CENTER'}>{t('exportdocumentconfiguration.params.values.center')}</option>
														<option value={'RIGHT'}>{t('exportdocumentconfiguration.params.values.right')}</option>
														<option value={'BOTTOM_LEFT'}>{t('exportdocumentconfiguration.params.values.bottomleft')}</option>
														<option value={'BOTTOM'}>{t('exportdocumentconfiguration.params.values.bottom')}</option>
														<option value={'BOTTOM_RIGHT'}>{t('exportdocumentconfiguration.params.values.bottomright')}</option>
														<option value={'ABOVE_CENTER'}>{t('exportdocumentconfiguration.params.values.abovecenter')}</option>
														<option value={'BELOW_CENTER'}>{t('exportdocumentconfiguration.params.values.belowcenter')}</option>
									                </Form.Control>
								                </Form.Group>
							                </Col>
							                <Col md="3">
								                <Form.Group controlId="font">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.font')}
									                </Form.Label>
													<Form.Control required as="select" name="FONT" value={node.params['FONT']} onChange={handleChange}>
														<option value={'HELVETICA'}>Helvetica</option>
														<option value={'COURIER'}>Courier</option>
														<option value={'TIMES_ROMAN'}>Times New Roman</option>
									                </Form.Control>
								                </Form.Group>
							                </Col>
							                <Col md="2">
								                <Form.Group controlId="padding">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.padding')}
									                </Form.Label>
									                <Form.Control type="number" name="PADDING" value={node.params['PADDING']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
						                </Row>
				                    </>
				                }
				                { node.type == 'IMAGE' &&
				                    <>
				                        <Form.Group controlId="data">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.data')}
							                </Form.Label>
							                { node.params['DATA'] &&
							                    <div>
							                        <img src={'data:' + node.params['TYPE'] + ';base64, ' + node.params['DATA']} style={{width: '100px'}} alt=""/>
							                    </div>
							                }
											<Form.Control required={!node.params['DATA']} type="file" accept="image/png, image/jpeg" name="DATA" onChange={handleChange} style={{width: 'auto'}}/>
						                </Form.Group>
						                <Row>
						                    <Col md="3">
								                <Form.Group controlId="width">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.width')}
									                </Form.Label>
									                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
								            <Col md="3">
								                <Form.Group controlId="height">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.height')}
									                </Form.Label>
									                <Form.Control type="number" name="HEIGHT" value={node.params['HEIGHT']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
								            <Col md="3">
								                <Form.Group controlId="position">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.position')}
									                </Form.Label>
													<Form.Control required as="select" name="POSITION" value={node.params['POSITION']} onChange={handleChange}>
														<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
														<option value={'TOP_LEFT'}>{t('exportdocumentconfiguration.params.values.topleft')}</option>
														<option value={'TOP'}>{t('exportdocumentconfiguration.params.values.top')}</option>
														<option value={'TOP_RIGHT'}>{t('exportdocumentconfiguration.params.values.topright')}</option>
														<option value={'LEFT'}>{t('exportdocumentconfiguration.params.values.left')}</option>
														<option value={'CENTER'}>{t('exportdocumentconfiguration.params.values.center')}</option>
														<option value={'RIGHT'}>{t('exportdocumentconfiguration.params.values.right')}</option>
														<option value={'BOTTOM_LEFT'}>{t('exportdocumentconfiguration.params.values.bottomleft')}</option>
														<option value={'BOTTOM'}>{t('exportdocumentconfiguration.params.values.bottom')}</option>
														<option value={'BOTTOM_RIGHT'}>{t('exportdocumentconfiguration.params.values.bottomright')}</option>
														<option value={'ABOVE_CENTER'}>{t('exportdocumentconfiguration.params.values.abovecenter')}</option>
														<option value={'BELOW_CENTER'}>{t('exportdocumentconfiguration.params.values.belowcenter')}</option>
									                </Form.Control>
								                </Form.Group>
								            </Col>
								            <Col md="3">
								                <Form.Group controlId="padding">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.padding')}
									                </Form.Label>
									                <Form.Control type="number" name="PADDING" value={node.params['PADDING']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
						                </Row>
				                    </>
				                }
				                { node.type != 'TEXT' && node.type != 'PHOTO' && node.type != 'IMAGE' &&
				                    <>
				                        <label>
				                            {t('exportdocumentconfiguration.children')}:
				                        </label>
						                <div>
						                    <DragDropContext onDragEnd={result => onDragEnd(result, node)}>
						                        <Droppable droppableId={node.id}>
						                            { provided => (
						                                <div ref={provided.innerRef} {...provided.droppableProps}>
										                    { node.children.map((child, index) => {
										                        return <Draggable key={child.id} draggableId={child.id} index={index}>
										                            { provided => (
											                            <Node
												                            provided={provided}
																			node={child}
																			parent={node}
																			handleNodeChanges={handleNodeChanges}
																			addNode={addNode}
																			removeNode={removeNode}
																			copyNode={copyNode}
																			onDragEnd={onDragEnd}
																		/>
																	)}
																</Draggable>
										                    })}
										                    {provided.placeholder}
									                    </div>
						                            )}
						                        </Droppable>
						                    </DragDropContext>
						                </div>
						                <Dropdown>
											<Dropdown.Toggle variant="outline-primary">
												<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{ node.type == 'EACH' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'PAGE')}>
															{t('exportdocumentconfiguration.nodetypes.page')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'PAGE' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'COLUMN')}>
															{t('exportdocumentconfiguration.nodetypes.column')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'COLUMN' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'PHOTO')}>
															{t('exportdocumentconfiguration.nodetypes.photo')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'IMAGE')}>
															{t('exportdocumentconfiguration.nodetypes.image')}
														</Dropdown.Item>
													</>
												}
											</Dropdown.Menu>
										</Dropdown>
									</>
								}
							</>
						}
					</Card.Body>
				</Card>
			</div>
	    )
	}
}

export default PDF