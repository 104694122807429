import React from 'react';
import CrudTable from '../table/crudtable'
import { documentationAliasService } from '../../_services'
import DocumentationAliasForm from './documentationaliasform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';

function DocumentationAlias() {

  const {t} = useTranslation('common');

  const initialState = {
		customerId: -1,
		name: '',
		alias: ''
	}

  const columns = [
    {
      dataField: 'customerName',
      text: t('documentationalias.columns.customer'),
      sort: true
    },
    {
      dataField: 'name',
      text: t('documentationalias.columns.name'),
      sort: true
    },
    {
      dataField: 'alias',
      text: t('documentationalias.columns.alias'),
      sort: true
    }
  ]

	return (
		<div>
			<h2>
				{t('documentationalias.label')}
            </h2>
            <CrudTable
		        service={documentationAliasService}
		        columns={columns}
		        initialState={initialState}
		        form={<DocumentationAliasForm />}
		        sort={{dataField: 'customerName', order: 'asc'}}
		        modalSize="lg"
			/>
		</div>
    )
}

export default DocumentationAlias;