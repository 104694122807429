import React from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

function ClassFilter({objectClasses, selectedObjectClasses, setSelectedObjectClasses}) {

	const formatObjectClass = (objectClass) => {
		return objectClass.split('.').pop()
	}

	const toggleObjectClass = (e) => {
		let name = e.target.name
		var newSelectedObjectClasses = []
		if (selectedObjectClasses.includes(name)) {
			newSelectedObjectClasses = selectedObjectClasses.filter(objectClass => objectClass != name)
		} else {
			newSelectedObjectClasses = [...selectedObjectClasses, name]
		}
		setSelectedObjectClasses(newSelectedObjectClasses)
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					<div style={{paddingBottom: '10px'}}>
						<Button variant="outline-dark" size="sm" onClick={e => setSelectedObjectClasses([...objectClasses])}>
							Välj alla
						</Button>
						<Button variant="outline-dark" size="sm" onClick={e => setSelectedObjectClasses([])}>
							Välj ingen
						</Button>
					</div>
					{ objectClasses.sort((a, b) => formatObjectClass(a).localeCompare(formatObjectClass(b))).map(objectClass => {
						return <div key={objectClass}>
							<label>
								<input type="checkbox" name={objectClass} checked={selectedObjectClasses.includes(objectClass)} onChange={toggleObjectClass}/>
								&nbsp;
								{formatObjectClass(objectClass)}
							</label>
						</div>
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default ClassFilter;