import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { photoService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function DeletePhotoButton(props) {

	const [state, setState] = useState(props.data)
    const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false);
    const {t} = useTranslation('common');

    const handleConfirm = () => {
        handleClose()
        photoService._delete(state.id).then(result => {
            props.onDelete(state.id)
        })
    }

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    return (
        <>
	        <Button
	            onClick={() => setShow(true)}
	            title={t("crudtable.delete.header")}
	            size="sm"
	            color="info"
	            variant="outline-danger">
	            <FontAwesomeIcon icon={faTrash}/>
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('crudtable.delete.header')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('crudtable.delete.confirmation')}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={handleConfirm}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )

}

export default DeletePhotoButton