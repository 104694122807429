import api from './api.service'

export const suggestionListService = {
    list,
    getAll,
    get,
    save,
    update,
    _delete,
    copy
};

const BASE_URL = '/suggestionLists'

function list(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function getAll(max) {
    return api.get('/suggestionList/getAll', {max : max || 100})
}

function get(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(suggestionList) {
    return api.post(BASE_URL, suggestionList)
}

function update(id, suggestionList) {
    return api.put(BASE_URL + "/" + id, suggestionList)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function copy(suggestionList, customer, name) {
    return api.post("/suggestionList/copy?suggestionList=" + suggestionList + "&customer=" + customer + "&name=" + name, suggestionList)
}
