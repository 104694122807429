import React from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

function DateFilter({dateRange, setDateRange}) {

	const formatDate = (date) => {
		return date.toISOString().substring(0, 10)
	}

	const changeStartDate = (e) => {
		let value = e.target.value
		setDateRange([value, dateRange[1]])
	}

	const changeEndDate = (e) => {
		let value = e.target.value
		setDateRange([dateRange[0], value])
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					<label>
						Startdatum
					</label>
					<br/>
					<input type="date" value={dateRange[0]} min="2021-01-01" max={dateRange[1]} onChange={changeStartDate}/>
					<Button variant="outline-dark" size="sm" onClick={e => setDateRange([new Date().toISOString().substring(0, 10), dateRange[1]])}>
						Idag
					</Button>
					<Button variant="outline-dark" size="sm" onClick={e => setDateRange(["2021-01-01", dateRange[1]])}>
						Återställ
					</Button>
					<br/>
					<br/>
					<label>
						Slutdatum
					</label>
					<br/>
					<input type="date" value={dateRange[1]} min={dateRange[0]} max={formatDate(new Date())} onChange={changeEndDate}/>
					<Button variant="outline-dark" size="sm" onClick={e => setDateRange([dateRange[0], new Date().toISOString().substring(0, 10)])}>
						Idag
					</Button>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default DateFilter;