import axios from "axios"
import { SERVER_URL } from '../config'

export const resetPasswordService = {
    requestPasswordChange,
    changePassword
}

function requestPasswordChange(username) {
    return axios
        .post(SERVER_URL + "/resetPassword/requestPasswordChange", {
            username: username
        })
        .then(response => {
            return response.data
        })
}

function changePassword(passwordObject) {
	return axios
        .post(SERVER_URL + "/resetPassword/changePassword", passwordObject)
        .then(response => {
            return response.data
        })
}
