import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { documentationGroupService, templateService } from '../../_services'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

function DocumentationGroupList() {

	const [documentationGroups, setDocumentationGroups] = useState([])
	const [templates, setTemplates] = useState([])
	const [columns, setColumns] = useState([])
	const {t} = useTranslation('common');

    useEffect(() => {
        documentationGroupService.list(10000).then(documentations => {
            var documentationGroupMap = new Map()
            documentations.forEach(documentation => {
                var documentationGroup = documentationGroupMap.get(documentation.name)
				if (!documentationGroup) {
					documentationGroup = []
					documentationGroupMap.set(documentation.name, documentationGroup)
				}
				documentationGroup.push(documentation)
            })

			templateService.list(1000).then(templates => {
	            templates.sort((a, b) => {
	                if (a.position != b.position) {
	                    return a.position - b.position
	                }
	                return a.name.localeCompare(b.name)
	            })
	            setTemplates(templates)

	            var documentationGroups = Array.from(documentationGroupMap, ([name, documentations]) => {
	                var documentationGroup = {
	                    name: name
	                }
					templates.forEach(template => {
						var templateDocumentations = documentations.filter(documentation => documentation.templateId == template.id)
						if (templateDocumentations && templateDocumentations.length > 0) {
							var dates = templateDocumentations.map(templateDocumentation => templateDocumentation.dateCreated)
							dates.sort()
							documentationGroup[template.id] = moment(dates[0]).format('yyyy-MM-DD')
							documentationGroup.alias = templateDocumentations[0].alias
						}
					})
	                return documentationGroup
	            })
	            setDocumentationGroups(documentationGroups)

	            var columns = []
	            columns.push({
					dataField: 'name',
					text: t('documentation.columns.name'),
					formatter: formatName,
					sort: true
				})
				columns.push({
					dataField: 'alias',
					text: t('documentation.columns.alias'),
					formatter: formatAlias,
					sort: true
				})
	            templates.forEach(template => {
	                columns.push({
						dataField: template.id,
						text: template.name,
						formatter: formatColumn,
						sort: true
					})
	            })
	            setColumns(columns)
	        })
        })
    }, [])

    const formatName = function(cell, row) {
		return <a href={'/documentationGroup/' + cell}>{cell}</a>
	}

	const formatAlias = function(cell, row) {
		return cell || '-'
	}

    const formatColumn = function(cell, row) {
		return cell || '-'
	}

    const { SearchBar } = Search;

    const pagination = paginationFactory({
		sizePerPage: 25,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		onPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		onSizePerPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		paginationTotalRenderer: (from, to, size) => {
			return ' ' + t('crudtable.total', {from: from, to: to, size: size})
		},
		nextPageTitle: t('crudtable.next'), // the title of next page button
		prePageTitle: t('crudtable.previous'), // the title of previous page button
		firstPageTitle: t('crudtable.first'), // the title of first page button
		lastPageTitle: t('crudtable.last')
    })

	return (
		<div>
			{ columns.length > 0 && documentationGroups.length > 0 &&
				<ToolkitProvider
					bootstrap4
					keyField='name'
					data={documentationGroups}
					columns={columns}
					search
				>
	                { toolkitprops => (
	                    <div>
	                        <SearchBar {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
	                        <BootstrapTable
								remote={{ filter: false, search: false, pagination: false }}
								condensed={true}
								pagination={pagination}
								{...toolkitprops.baseProps}
								onTableChange={() => console.log("onTableChange")}
								sort={{dataField: 'name', order: 'asc'}}
	                        />
	                    </div>
	                )}
		        </ToolkitProvider>
	        }
        </div>
    )
}

export default DocumentationGroupList;