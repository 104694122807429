import React, { useState } from 'react'
import { Form, Button, Row, Col, Tabs, Tab, Card, Dropdown, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faX, faTableColumns, faArrowsRotate, faFont, faCamera,
	faImage, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

function PowerPoint({nodes, addNode, removeNode, onDragEnd, toggleNode}) {

	const {t} = useTranslation('common');

	return (
		<div>
			<DragDropContext onDragEnd={result => onDragEnd(result)}>
				<Droppable droppableId="1">
					{ provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{ nodes.map((node, index) => {
								return <Draggable key={node.id} draggableId={node.id} index={index}>
									{ provided => (
										<Node
											provided={provided}
											node={node}
											toggleNode={toggleNode}
											addNode={addNode}
											removeNode={removeNode}
											onDragEnd={onDragEnd}
										/>
									)}
								</Draggable>
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
            </DragDropContext>
            <Dropdown>
				<Dropdown.Toggle variant="outline-primary">
					<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={e => addNode(e, null, 'EACH')}>
						{t('exportdocumentconfiguration.nodetypes.each')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'SLIDE')}>
						{t('exportdocumentconfiguration.nodetypes.slide')}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)

	function Node({node, parent, provided, handleNodeChanges, addNode, removeNode, onDragEnd}) {

		const [state, setState] = useState(node.params)

	    const handleChange = e => {
	        var name = e.target.name
            var value = e.target.value

            var newState = {...state}

            if (name == 'DATA') {
                let file = e.target.files[0]

		        if (file) {
		            const reader = new FileReader()
		            reader.addEventListener('load', (event) => {
		                let binaryString = event.target.result;
		                let hexString = btoa(binaryString)
		                newState["DATA"] = hexString
		                newState["TYPE"] = file.type
		                node.params["DATA"] = hexString
		                node.params["TYPE"] = file.type
		                handleChange({target: {type: "object", name: "nodes", value: state.nodes}})
		            });
		            reader.readAsBinaryString(file)
		        }
            } else {
				newState[name] = value
	            node.params[name] = value
            }

            setState(newState)
	    }

	    const getIcon = type => {
			switch (type) {
				case 'EACH':
					return <FontAwesomeIcon icon={faArrowsRotate}/>
				case 'SLIDE':
					return <FontAwesomeIcon icon={faSquare}/>
				case 'COLUMN':
					return <FontAwesomeIcon icon={faTableColumns}/>
				case 'TEXT':
					return <FontAwesomeIcon icon={faFont}/>
				case 'PHOTO':
					return <FontAwesomeIcon icon={faCamera}/>
				case 'IMAGE':
					return <FontAwesomeIcon icon={faImage}/>
				default:
					return <></>
			}
		}

	    return (
	        <div {...provided.draggableProps} ref={provided.innerRef}>
	            <Card>
	                <Card.Body>
		                <div className="clearfix">
							<div className="float-left">
								<span {...provided.dragHandleProps}>
									<FontAwesomeIcon icon={faGripVertical}/>
								</span>
								<span style={{paddingLeft: '20px', paddingRight: '5px'}}>
									{getIcon(node.type)}
								</span>
	                            {node.type}
							</div>
							<div className="float-right">
								{ node.type != 'PAGE_BREAK' &&
									<>
										<Button variant="outline-secondary" size="sm" onClick={e => toggleNode(node)}>
											<FontAwesomeIcon icon={node.collapsed ? faChevronDown : faChevronUp}/>
										</Button>
										&nbsp;
									</>
								}
	                            <Button variant="outline-danger" size="sm" onClick={e => removeNode(e, node, parent)}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
					        </div>
						</div>
						{ !node.collapsed &&
							<>
				                { node.type == 'EACH' &&
				                    <>
				                        <Form.Group controlId="in">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.iterateover')}
							                </Form.Label>
											<Form.Control required as="select" name="IN" value={node.params['IN']} onChange={handleChange}>
												<option value={'PHOTOS'}>{t('exportdocumentconfiguration.params.values.photos')}</option>
												<option value={'TASKS'}>{t('exportdocumentconfiguration.params.values.tasks')}</option>
												<option value={'GROUPS'}>{t('exportdocumentconfiguration.params.values.groups')}</option>
							                </Form.Control>
						                </Form.Group>
						                { node.params['IN'] == 'GROUPS' &&
							                <Form.Group controlId="type">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.type')}
								                </Form.Label>
												<Form.Control required as="select" name="POSITION" value={node.params['GROUPS_TYPE']} onChange={handleChange}>
													<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
													<option value={'PRE_POST'}>{t('exportdocumentconfiguration.params.values.prepost')}</option>
								                </Form.Control>
							                </Form.Group>
						                }
				                    </>
				                }
				                { (node.type == 'SLIDE' || node.type == 'COLUMN') &&
				                    <Row>
				                        <Col md="4">
					                        <Form.Group controlId="BACKGROUND_COLOR">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.backgroundcolor')}
								                </Form.Label>
								                <InputGroup>
													<Form.Control type="color" name="BACKGROUND_COLOR" value={node.params['BACKGROUND_COLOR'] || '#FFFFFF'} onChange={handleChange}/>
							                        { node.params['BACKGROUND_COLOR'] &&
								                        <Button variant="outline-secondary" onClick={e => handleChange({target: {name: 'BACKGROUND_COLOR', value: ''}})}>
								                            <FontAwesomeIcon icon={faX} style={{color: 'red'}}/>
				                                        </Button>
			                                        }
							                    </InputGroup>
							                </Form.Group>
						                </Col>
				                    </Row>
				                }
				                { node.type == 'TEXT' &&
				                    <>
					                    <Form.Group controlId="text">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.text')}
							                </Form.Label>
							                <TextareaAutosize required className="form-control" placeholder={t('exportdocumentconfiguration.params.keys.text')} name="TEXT" value={state['TEXT']} onChange={handleChange}/>
						                </Form.Group>
						                <Row>
						                    <Col md="4">
								                <Form.Group controlId="size">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.size')}
									                </Form.Label>
									                <Form.Control required type="number" name="SIZE" value={node.params['SIZE']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
							                <Col md="4">
								                <Form.Group controlId="color">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.color')}
									                </Form.Label>
													<Form.Control type="color" name="COLOR" value={node.params['COLOR'] || '#000000'} onChange={handleChange}/>
								                </Form.Group>
							                </Col>
							                <Col md="4">
								                <Form.Group controlId="position">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.position')}
									                </Form.Label>
													<Form.Control required as="select" name="POSITION" value={node.params['POSITION']} onChange={handleChange}>
														<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
														<option value={'TOP_LEFT'}>{t('exportdocumentconfiguration.params.values.topleft')}</option>
														<option value={'TOP'}>{t('exportdocumentconfiguration.params.values.top')}</option>
														<option value={'TOP_RIGHT'}>{t('exportdocumentconfiguration.params.values.topright')}</option>
														<option value={'LEFT'}>{t('exportdocumentconfiguration.params.values.left')}</option>
														<option value={'CENTER'}>{t('exportdocumentconfiguration.params.values.center')}</option>
														<option value={'RIGHT'}>{t('exportdocumentconfiguration.params.values.right')}</option>
														<option value={'BOTTOM_LEFT'}>{t('exportdocumentconfiguration.params.values.bottomleft')}</option>
														<option value={'BOTTOM'}>{t('exportdocumentconfiguration.params.values.bottom')}</option>
														<option value={'BOTTOM_RIGHT'}>{t('exportdocumentconfiguration.params.values.bottomright')}</option>
														<option value={'ABOVE_CENTER'}>{t('exportdocumentconfiguration.params.values.abovecenter')}</option>
														<option value={'BELOW_CENTER'}>{t('exportdocumentconfiguration.params.values.belowcenter')}</option>
									                </Form.Control>
								                </Form.Group>
							                </Col>
						                </Row>
				                    </>
				                }
				                { node.type == 'IMAGE' &&
				                    <>
				                        <Form.Group controlId="data">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.data')}
							                </Form.Label>
							                { node.params['DATA'] &&
							                    <div>
							                        <img src={'data:' + node.params['TYPE'] + ';base64, ' + node.params['DATA']} style={{width: '100px'}} alt=""/>
							                    </div>
							                }
											<Form.Control required={!node.params['DATA']} type="file" accept="image/png, image/jpeg" name="DATA" onChange={handleChange}/>
						                </Form.Group>
						                <Row>
						                    <Col md="3">
								                <Form.Group controlId="width">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.width')}
									                </Form.Label>
									                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
								            <Col md="3">
								                <Form.Group controlId="height">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.height')}
									                </Form.Label>
									                <Form.Control type="number" name="HEIGHT" value={node.params['HEIGHT']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
								            <Col md="3">
								                <Form.Group controlId="position">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.position')}
									                </Form.Label>
													<Form.Control required as="select" name="POSITION" value={node.params['POSITION']} onChange={handleChange}>
														<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
														<option value={'TOP_LEFT'}>{t('exportdocumentconfiguration.params.values.topleft')}</option>
														<option value={'TOP'}>{t('exportdocumentconfiguration.params.values.top')}</option>
														<option value={'TOP_RIGHT'}>{t('exportdocumentconfiguration.params.values.topright')}</option>
														<option value={'LEFT'}>{t('exportdocumentconfiguration.params.values.left')}</option>
														<option value={'CENTER'}>{t('exportdocumentconfiguration.params.values.center')}</option>
														<option value={'RIGHT'}>{t('exportdocumentconfiguration.params.values.right')}</option>
														<option value={'BOTTOM_LEFT'}>{t('exportdocumentconfiguration.params.values.bottomleft')}</option>
														<option value={'BOTTOM'}>{t('exportdocumentconfiguration.params.values.bottom')}</option>
														<option value={'BOTTOM_RIGHT'}>{t('exportdocumentconfiguration.params.values.bottomright')}</option>
														<option value={'ABOVE_CENTER'}>{t('exportdocumentconfiguration.params.values.abovecenter')}</option>
														<option value={'BELOW_CENTER'}>{t('exportdocumentconfiguration.params.values.belowcenter')}</option>
									                </Form.Control>
								                </Form.Group>
								            </Col>
								            <Col md="3">
								                <Form.Group controlId="padding">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.padding')}
									                </Form.Label>
									                <Form.Control type="number" name="PADDING" value={node.params['PADDING']} onChange={handleChange}/>
								                </Form.Group>
								            </Col>
						                </Row>
				                    </>
				                }
				                { node.type != 'TEXT' && node.type != 'PHOTO' && node.type != 'IMAGE' &&
				                    <>
				                        <label>
				                            {t('exportdocumentconfiguration.children')}:
				                        </label>
						                <div>
						                    <DragDropContext onDragEnd={result => onDragEnd(result, node)}>
						                        <Droppable droppableId={node.id}>
						                            { provided => (
						                                <div ref={provided.innerRef} {...provided.droppableProps}>
										                    { node.children.map((child, index) => {
										                        return <Draggable key={child.id} draggableId={child.id} index={index}>
										                            { provided => (
											                            <Node
												                            provided={provided}
																			node={child}
																			parent={node}
																			handleNodeChanges={handleNodeChanges}
																			addNode={addNode}
																			removeNode={removeNode}
																			onDragEnd={onDragEnd}
																		/>
																	)}
																</Draggable>
										                    })}
										                    {provided.placeholder}
									                    </div>
						                            )}
						                        </Droppable>
						                    </DragDropContext>
						                </div>
						                <Dropdown>
											<Dropdown.Toggle variant="outline-primary">
												<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{ node.type == 'EACH' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'SLIDE')}>
															{t('exportdocumentconfiguration.nodetypes.slide')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'SLIDE' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'COLUMN')}>
															{t('exportdocumentconfiguration.nodetypes.column')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'COLUMN' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'PHOTO')}>
															{t('exportdocumentconfiguration.nodetypes.photo')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'IMAGE')}>
															{t('exportdocumentconfiguration.nodetypes.image')}
														</Dropdown.Item>
													</>
												}
											</Dropdown.Menu>
										</Dropdown>
									</>
								}
							</>
						}
					</Card.Body>
				</Card>
			</div>
	    )
	}
}

export default PowerPoint