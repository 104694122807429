import api from './api.service'

export const documentationGroupService = {
    list,
    findAllByDocumentationName
};

const BASE_URL = '/documentationGroups'

function list(max) {
    return api.get(BASE_URL, {max: max || 1000, sort: 'dateCreated', order: 'desc'})
}

function findAllByDocumentationName(name) {
    return api.get('/documentationGroup/findAllByDocumentationName/' + name, {max: 100, sort: 'dateCreated', order: 'asc'})
}
