import React, { useEffect, useState } from 'react';
import CrudTable from '../table/crudtable'
import { documentationService, authService, templateService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ChangeTemplateList() {

	const [templates, setTemplates] = useState([]);
	const [templatesDownloaded, setTemplatesDownloaded] = useState(false);
	const {t} = useTranslation('common');

	useEffect(() => {
		templateService.list(10000).then(data => {
            setTemplates(data)
            setTemplatesDownloaded(true)
        })
	}, [])

	const formatName = function(cell, row) {
		return <a href={'/changeTemplate/' + row.id}>{row.name}</a>
	}

	const formatTemplate = function(cell) {
		var template = templates.find(template => template.id == cell)
		if (template) {
			return template.name
		}
		return '-'
	}

	const formatCreator = function(cell, row) {
		if (row.deviceId && row.deviceId.length >= 4) {
			return cell + ' (' + row.deviceId.substring(0, 4) + ')'
		}
		return cell
	}

	const formatDate = function(cell) {
		if (cell) {
			return moment(cell).format('YYYY-MM-DD HH:mm:ss')
		}
		return '-'
	}

	const dateUploadedSortValue = function(cell) {
		if (cell) {
			return moment(cell).format('YYYY-MM-DD HH:mm:ss')
		}
		return 'A'
	}

	const formatCompleted = function(cell, row) {
		if (cell) {
			return t('crudtable.yes')
		}
		return t('crudtable.no') + ' (' + row.uploadedPhotos + '/' + row.numberOfPhotos + ')'
	}

    const columns = [
		{
			dataField: 'name',
			text: t('documentation.columns.name'),
			formatter: formatName,
			sort: true
		},
		{
			dataField: 'customerName',
			text: t('documentation.columns.customer'),
			sort: true,
			hidden: !authService.hasRoles(['ROLE_SUPER_ADMIN'])
		},
		{
			dataField: 'templateId',
			text: t('documentation.columns.template'),
			formatter: formatTemplate,
			filterValue: formatTemplate,
			sort: true
		},
		{
			dataField: 'creator',
			text: t('documentation.columns.creator'),
			formatter: formatCreator,
			filterValue: formatCreator,
			sort: true
		},
		{
			dataField: 'dateUploaded',
			text: t('documentation.columns.dateuploaded'),
			formatter: formatDate,
			filterValue: dateUploadedSortValue,
			sort: true
		},
		{
			dataField: 'dateCreated',
			text: t('documentation.columns.datecreated'),
			formatter: formatDate,
			filterValue: formatDate,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				Byt mall
			</h2>
			{ templatesDownloaded &&
				<CrudTable
					service={documentationService}
					columns={columns}
					sort={{dataField: 'dateUploaded', order: 'desc'}}
					editable={false}
					creatable={false}
					deletable={false}
				/>
			}
		</div>
	)
}

export default ChangeTemplateList;