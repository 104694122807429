import React from 'react';
import CrudTable from '../table/crudtable'
import { suggestionListService, authService } from '../../_services'
import SuggestionListForm from './suggestionlistform.component'
import { useTranslation } from "react-i18next";

function SuggestionList() {

    const {t} = useTranslation('common');

    const initialState = {
        customerId: 1,
        name: '',
        description: '',
        type: 'DOCUMENTATION_NAMES',
        deleteSuggestionIfDocumentationExists: false,
        requiredDocumentationStatus: '',
        suggestions: []
    }

    const descriptionFormatter = function(cell) {
        return cell ? cell : '-'
    }

    const typeFormatter = function(cell) {
        if (cell == 'DOCUMENTATION_NAMES') {
            return t('suggestionlist.types.documentationnames')
        } else if (cell == 'TASK_OPTIONS') {
            return t('suggestionlist.types.taskoptions')
        }
        return '-'
    }

    const numberOfSuggestionsFormatter = function(cell, row) {
	    return row.suggestions.length
    }

    const getSort = () => {
	    if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        return {dataField: 'customerName', order: 'asc'}
	    }
	    return {dataField: 'name', order: 'asc'}
    }

    const columns = [
        {
            dataField: 'customerName',
            text: t('suggestionlist.columns.customer'),
            sort: true,
            hidden: !authService.hasRoles(['ROLE_SUPER_ADMIN'])
        },
        {
            dataField: 'name',
            text: t('suggestionlist.columns.name'),
            sort: true
        },
        {
            dataField: 'description',
            text: t('suggestionlist.columns.description'),
            formatter: descriptionFormatter,
            sort: true
        },
        {
            dataField: 'type',
            text: t('suggestionlist.columns.type'),
            formatter: typeFormatter,
            sort: true
        },
        {
            dataField: 'numberOfSuggestions',
            text: t('suggestionlist.columns.numberofsuggestions'),
            formatter: numberOfSuggestionsFormatter,
            sort: true
        }
    ]

  return (
    <div>
      <h2>
        {t('suggestionlist.label')}
      </h2>
      <CrudTable
        service={suggestionListService}
        columns={columns}
        initialState={initialState}
        form={<SuggestionListForm />}
        remote={{"filter": false, "search": false, "pagination": false}}
        sort={getSort()}
        modalSize="lg"
        creatable={authService.hasRoles(['ROLE_SUGGESTION_LIST_WRITE'])}
        editable={authService.hasRoles(['ROLE_SUGGESTION_LIST_WRITE'])}
        deletable={authService.hasRoles(['ROLE_SUGGESTION_LIST_WRITE'])}
      />
      </div>
  )
}

export default SuggestionList;