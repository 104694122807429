import React, {useEffect} from 'react';
import { authService } from '../_services'

function Home() {

	useEffect(() => {
		if (authService.hasRoles(['ROLE_SWITCH_CUSTOMER'])) {
			window.location.href = '/switchCustomer';
		} else if (authService.hasRoles(['ROLE_DOCUMENTATION_READ'])) {
			window.location.href = '/documentation';
		}
    }, [])

  return (
    <>

    </>
  )
}

export default Home;