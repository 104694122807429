import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { documentationService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function ToggleHiddenButton(props) {

	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');

	const onClick = () => {
        setShow(true)
    }

    const handleSubmit = (event) => {
	    event.preventDefault()
	    documentationService.toggleHidden(props.documentation.id).then(result => {
	        handleClose()
			props.onToggleHidden(result.hidden)
        })
	}

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

	return (
        <>
	        <Button
	            onClick={onClick}
	            title={props.documentation.hidden ? t('documentation.togglehidden.show') : t('documentation.togglehidden.hide')}
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={props.documentation.hidden ? faEye : faEyeSlash}/> {props.documentation.hidden ? t('documentation.togglehidden.show') : t('documentation.togglehidden.hide')}
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.documentation.hidden ? t('documentation.togglehidden.show') : t('documentation.togglehidden.hide')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.documentation.hidden ? t('documentation.togglehidden.showmessage') : t('documentation.togglehidden.hidemessage')}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' variant="primary" onClick={handleSubmit}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default ToggleHiddenButton