import React, { useEffect, useState } from 'react';
import CrudTable from '../table/crudtable'
import { domainChangeLogService, userService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { customFilter } from 'react-bootstrap-table2-filter';
import DateFilter from './datefilter'
import ClassFilter from './classfilter'

function DomainChangeLog() {

	const [users, setUsers] = useState([])
	const [usersDownloaded, setUsersDownloaded] = useState(false)
	const [dateRange, setDateRange] = useState(["2021-01-01", new Date().toISOString().substring(0, 10)])
	const [objectClasses, setObjectClasses] = useState([])
	const [selectedObjectClasses, setSelectedObjectClasses] = useState([])
    const {t} = useTranslation('common')

    useEffect(() => {
        console.log('useEffect')

        userService.list().then(users => {
            setUsers(users)
            setUsersDownloaded(true)
        })

        domainChangeLogService.getObjectClasses().then(data => {
            let blackList = [
                'se.iqmtel.telcodox.photo.Photo',
                'se.iqmtel.telcodox.photo.PhotoData',
                'se.iqmtel.telcodox.Photo',
                'se.iqmtel.telcodox.PhotoData'
            ]
            setObjectClasses(data)
            setSelectedObjectClasses(data.filter(objectClass => !blackList.includes(objectClass)))
        })
    }, [])

	const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const formatUser = function(cell) {
		if (cell != -1) {
			var user = users.find(user => user.id === cell)
			if (user) {
				return user.username
			}
		}
		return '-'
	}

	const formatClass = function(cell) {
		if (cell.indexOf(".") != -1) {
			var split = cell.split(".")
			return <span title={cell}>{split[split.length-1]}</span>
		}
		return cell
	}

	const formatParams = function(cell) {
		if (cell) {
			var keys = Object.keys(cell)
			if (keys && keys.length > 0) {
				var result = []
		        keys.forEach((key, index) => {
		            result.push(key + ": " + cell[key])
		        })
		        return result.join(", ")
			}
	    }
	    return '-'
	}

	const columns = [
		{
		    dataField: 'dateCreated',
		    text: t('domainchangelog.columns.datecreated'),
            formatter: formatDate,
            filterValue: formatDate,
			sort: true,
            filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <DateFilter dateRange={dateRange} setDateRange={setDateRange}/>
			}
		},
		{
			dataField: 'userId',
			text: t('domainchangelog.columns.username'),
			formatter: formatUser,
            filterValue: formatUser,
			sort: true
		},
        {
            dataField: 'objectClass',
            text: t('domainchangelog.columns.class'),
            formatter: formatClass,
            sort: true,
            filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <ClassFilter objectClasses={objectClasses} selectedObjectClasses={selectedObjectClasses} setSelectedObjectClasses={setSelectedObjectClasses}/>
			}
        },
		{
			dataField: 'objectId',
			text: t('domainchangelog.columns.id'),
			sort: true
		},
		{
			dataField: 'params',
			text: t('domainchangelog.columns.params'),
            formatter: formatParams,
            filterValue: formatParams,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('domainchangelog.label')}
			</h2>
			{ usersDownloaded && objectClasses.length > 0 &&
				<CrudTable
					service={domainChangeLogService}
					serviceParams={{dateRange: dateRange, objectClasses: selectedObjectClasses}}
					columns={columns}
					remote={{"filter": false, "search": false, "pagination": false}}
					creatable={false}
					editable={false}
					deletable={true}
					sort={{dataField: 'dateCreated', order: 'desc'}}
				/>
			}
		</div>
	)
}

export default DomainChangeLog;