import React, { useState, useEffect } from 'react'
import { licenseService } from '../../_services'
import { Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Select from 'react-select'
import { SERVER_URL } from '../../config';

function PhotoDump(props) {

	const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
    const {t} = useTranslation('common')

	const onClick = () => {
		setShow(true)
		setBackdrop(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const confirm = () => {
        onHide()

		const token = 'RZrtT2Uyva2tQ34U6OE9hQkluE44VoHk1yu0vfYDv0zz0zW6m42yAFWKgdtCg0Fy'
        window.location.href = SERVER_URL + '/customer/downloadPhotoDump/photos.zip?customerId=' + props.customerId + '&token=' + token
    }

	return (
		<>
			<Button title="Skapa fotodump" variant="outline-secondary" size="sm" onClick={onClick}>
				<FontAwesomeIcon icon={faDownload}/>
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="sm"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>Fotodump</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Är du säker på att du vill skapa en fotodump för den här kunden?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={confirm}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PhotoDump