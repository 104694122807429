import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { suggestionListService, authService, customerService } from '../../_services'

const SuggestionListForm = ({data, handleChange, handleSubmit, validated}) => {

	const [suggestionLists, setSuggestionLists] = useState([])
	const [customers, setCustomers] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        customerService.list().then(data => {
	            data.sort((a, b) => {
	                return a.name.localeCompare(b.name)
	            })
	            setCustomers(data)
	        })
        }
        suggestionListService.list().then(suggestionLists => {
            suggestionLists.sort((a, b) => {
                if (a.name != b.name) {
                    return a.name.localeCompare(b.name)
                }
                return a.customerName.localeCompare(b.customerName)
            })
			setSuggestionLists(suggestionLists)
        })
    }, [])

    function addSuggestion(e) {
        e.preventDefault();

        var suggestion = {
            id: uuidv4(),
            customerId: data.customerId,
            text: '',
            imported: false,
            lat: 1000,
            lng: 1000
        }

        let newSuggestions = [...data.suggestions, suggestion]

        handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
    }

    function removeSuggestion(suggestion, e) {
        e.preventDefault();

        var newSuggestions = data.suggestions.filter(t => t.id !== suggestion.id)

        handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
    }

    function removeAll(e) {
        e.preventDefault();

        let newSuggestions = []

        handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
    }

    function handleCustomerChange(e) {
        var customerId = e.target.value
        data.suggestions.forEach(suggestion => suggestion.customerId = customerId)
		handleChange(e)
    }

    function handleSuggestionListChange(e) {
        var suggestionList = suggestionLists.find(suggestionlist => suggestionlist.id == e.target.value)
        handleChange({target: {type: "text", name: "name", value: suggestionList.name}})
        handleChange({target: {type: "text", name: "description", value: suggestionList.description}})
        handleChange({target: {type: "text", name: "type", value: suggestionList.type}})
        var suggestions = []
        suggestionList.suggestions.forEach(suggestion => {
            var suggestionsCopy = {
                ...suggestion,
	            id: uuidv4(),
	            customerId: data.customerId,
	            dateCreated: null,
	            lastUpdated: null
	        }
	        suggestions.push(suggestionsCopy)
        })
        handleChange({target: {type: "object", name: "suggestions", value: suggestions}})
    }

    function handleSuggestionChanges(suggestion, e) {
        var value = e.target.value
        if (e.target.name == 'text') {
            var invalidChars = ['~', '"', '#', '%', '&', '*', ':', '<', '>', '?', '/', '\\', '{', '|', '}']
	        invalidChars.forEach(invalidChar => value = value.replaceAll(invalidChar, ''))
        } else if (e.target.name == 'lat' || e.target.name == 'lng') {
            value = parseFloat(value)
        }
        suggestion[e.target.name] = value
        handleChange({target: {type: "object", name: "suggestions", value: data.suggestions}})
    }

    function handleFileChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                const result = reader.result
                var rows = result.split(/\r?\n/)
                var newSuggestions = [...data.suggestions]
                var existingSuggestions = newSuggestions.map(suggestion => suggestion.text)
                rows.forEach(row => {
                    if (!existingSuggestions.includes(row) && row != '') {
                        var rowSplit = row.split(';')
                        console.log(rowSplit)
	                    var suggestion = {
				            id: uuidv4(),
				            customerId: data.customerId,
				            text: rowSplit[0],
				            lat: 1000,
				            lng: 1000
				        }
				        if (rowSplit.length == 3) {
				            var lat = parseFloat(rowSplit[1])
				            var lng = parseFloat(rowSplit[2])
				            if (lat) {
				                suggestion.lat = lat
				            }
				            if (lng) {
				                suggestion.lng = lng
				            }
				        }
				        newSuggestions.push(suggestion)
                    }
                })
                handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
            });
            reader.readAsText(file)
        }
    }

    const isDuplicate = suggestion => {
        var otherSuggestions = data.suggestions.filter(otherSuggestion => otherSuggestion.text.trim() === suggestion.text.trim())
        return otherSuggestions.length > 1
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            <Row>
	            <Col sm="6">
	                { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
	                    <Form.Group controlId="customer">
		                    <Form.Label>
			                    {t('suggestionlist.columns.customer')}
			                </Form.Label>
							<Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleCustomerChange} disabled={data.dateCreated}>
			                    {customers.map(customer => (
			                        <option key={customer.id} value={customer.id}>{customer.name}</option>
			                    ))}
			                </Form.Control>
	                    </Form.Group>
	                }
	                { !data.dateCreated &&
	                    <Form.Group controlId="suggestionlist">
		                    <Form.Label>
			                    {t('suggestionlist.copyfrom')}
			                </Form.Label>
							<Form.Control required as="select" name="suggestionlist" value={-1} onChange={handleSuggestionListChange} disabled={suggestionLists.length == 0}>
								<option value={-1}>{t('suggestionlist.choosesuggestionlist')}</option>
			                    { suggestionLists.map(suggestionList => (
			                        <option key={suggestionList.id} value={suggestionList.id}>
			                            {suggestionList.name}
			                            { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
			                                ' (' + suggestionList.customerName + ')'
			                            }
			                        </option>
			                    ))}
			                </Form.Control>
	                    </Form.Group>
	                }
		            <Form.Group controlId="name">
		                <Form.Label>
		                    {t('suggestionlist.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('suggestionlist.columns.name')} name="name" value={data.name} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="description">
		                <Form.Label>
		                    {t('suggestionlist.columns.description')}
		                </Form.Label>
		                <TextareaAutosize className="form-control" placeholder={t('suggestionlist.columns.description')} name="description" value={data.description || ''} onChange={handleChange}/>
		            </Form.Group>
		            <Form.Group controlId="type">
	                    <Form.Label>
		                    {t('suggestionlist.columns.type')}
		                </Form.Label>
						<Form.Control required as="select" name="type" value={data.type} onChange={handleChange}>
							<option value="DOCUMENTATION_NAMES">{t('suggestionlist.types.documentationnames')}</option>
							<option value="TASK_OPTIONS">{t('suggestionlist.types.taskoptions')}</option>
		                </Form.Control>
                    </Form.Group>
		        </Col>
		        { data.type == 'DOCUMENTATION_NAMES' &&
			        <Col sm="6">
			            <Form.Group controlId="deleteSuggestionIfDocumentationExists" style={{marginBottom: 0}}>
			                <Form.Label>
			                    <Form.Check type="checkbox" name="deleteSuggestionIfDocumentationExists" label={t('suggestionlist.columns.deletesuggestionifdocumentationexists')} checked={data.deleteSuggestionIfDocumentationExists} onChange={handleChange} />
			                </Form.Label>
			            </Form.Group>
			            <Form.Group controlId="requiredDocumentationStatus">
			                <Form.Label>
			                    {t('suggestionlist.columns.requireddocumentationstatus')}
			                </Form.Label>
			                <Form.Control as="select" name="type" value={data.requiredDocumentationStatus} onChange={handleChange} disabled={!data.deleteSuggestionIfDocumentationExists}>
			                    <option value="">{t('suggestionlist.nostatus')}</option>
			                    <option>ONGOING</option>
			                    <option>PAUSED</option>
			                    <option>DOWNLOADED</option>
			                    <option>COMPLETED</option>
			                    <option>DELETED</option>
			                    <option>HANDLED</option>
			                    <option>WAITING_FOR_FILE</option>
			                </Form.Control>
			            </Form.Group>
			        </Col>
		        }
		    </Row>
		    <Row>
		        <Col sm="12">
		            <Form.Group controlId="suggestions">
		                <Form.Label>
		                    {t('suggestionlist.columns.suggestions')}
		                </Form.Label>
						<table className="table table-bordered table-sm">
							<thead>
			                    <tr>
			                        <th>
			                            {t('suggestion.columns.name')} *
			                        </th>
			                        { data.type == 'DOCUMENTATION_NAMES' &&
			                            <>
					                        <th style={{width: 100}}>
					                            {t('suggestion.columns.lat')}
					                        </th>
					                        <th style={{width: 100}}>
					                            {t('suggestion.columns.lng')}
					                        </th>
				                        </>
			                        }
			                        <th style={{width: 20}}>
			                            {t('suggestion.columns.imported')}
			                        </th>
			                        <th style={{width: 20}}></th>
			                    </tr>
			                </thead>
							<tbody>
								{ data.suggestions.map((suggestion, index) => {
									return <tr key={suggestion.id}>
										<td>
											<Form.Control
												required
												type="text"
												placeholder={t('suggestion.columns.name')}
												name="text"
												value={suggestion.text}
												onChange={e => handleSuggestionChanges(suggestion, e)}
												pattern={isDuplicate(suggestion) ? '' : null}
											/>
										</td>
										{ data.type == 'DOCUMENTATION_NAMES' &&
											<>
												<td>
													<Form.Control required type="number" step="0.1" placeholder={t('suggestion.columns.lat')} name="lat" value={suggestion.lat} onChange={e => handleSuggestionChanges(suggestion, e)}/>
												</td>
												<td>
													<Form.Control required type="number" step="0.1" placeholder={t('suggestion.columns.lng')} name="lng" value={suggestion.lng} onChange={e => handleSuggestionChanges(suggestion, e)}/>
												</td>
											</>
										}
										<td>
											{suggestion.imported ? t('crudtable.yes') : t('crudtable.no')}
										</td>
										<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
											<Button
									            onClick={(e) => removeSuggestion(suggestion, e)}
									            title={t("crudtable.add")}
									            size="sm"
									            color="info"
									            variant="outline-danger">
									            <FontAwesomeIcon icon={faTrash}/>
									        </Button>
										</td>
									</tr>
								})}
							</tbody>
						</table>
						<Row>
							<Col md="6">
				                <Button
				                    onClick={addSuggestion}
				                    title={t('suggestionlist.addsuggestion')}
				                    color="info"
				                    variant="outline-primary">
				                    <FontAwesomeIcon icon={faPlus}/> {t('suggestionlist.addsuggestion')}
				                </Button>
				                &nbsp;
				                <Button
				                    onClick={removeAll}
				                    title={t('suggestionlist.removeall')}
				                    variant="outline-danger">
				                    <FontAwesomeIcon icon={faTrash}/> {t('suggestionlist.removeall')}
				                </Button>
			                </Col>
			                <Col md="6">
			                    <Form.Control className="float-right" style={{width: 'auto'}} type="file" accept=".txt,.csv" name="text" onChange={handleFileChange} />
			                </Col>
		                </Row>
		            </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default SuggestionListForm