import api from './api.service'

export const documentationTaskFilterService = {
    list,
    get,
    save,
    update,
    _delete
};

const BASE_URL = '/documentationTaskFilters'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000})
}

function get(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(documentationTaskFilter) {
    return api.post(BASE_URL, documentationTaskFilter)
}

function update(id, documentationTaskFilter) {
    return api.put(BASE_URL + "/" + id, documentationTaskFilter)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
