import api from './api.service'

export const tempFileTokenService = {
	list,
	_delete
};

function list() {
    return api.get('/uploadToken/')
}

function _delete(id) {
    return api.delete('/uploadToken/delete/' + id)
}
