import api from './api.service'

export const mapService = {
    list
};

const BASE_URL = '/map'

function list() {
    return api.get(BASE_URL, {max: 1000, sort: 'dateUploaded', order: 'desc'})
}