import api from './api.service'

export const fileService = {
    list,
    getAllDocumentationFiles,
    findAllByCustomerId,
    get,
    save,
    saveWithLicenseCode,
    upload,
    update,
    _delete,
    importFile
};

const BASE_URL = '/files'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000, sort: 'dateCreated', order: 'asc'})
}

function getAllDocumentationFiles(documentationId, max) {
    return api.get(BASE_URL + '?documentation=' + documentationId, {max : max || 1000, sort: 'name', order: 'asc'})
}

function findAllByDocumentationName(documentationName, max) {
	return api.get('/file/findAllByDocumentationName/' + documentationName, {max : max || 1000, sort: 'name', order: 'asc'})
}

function findAllByCustomerId(customerId) {
	return api.get('/file/findAllByCustomerId/' + customerId)
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(file) {
    return api.post(BASE_URL, file)
}

function saveWithLicenseCode(file, licenseCode) {
    return api.post('/file/saveWithLicenseCode?licenseCode=' + licenseCode, file)
}

function upload(fileUploadObject, fileId, licenseCode) {
    return api.post('/file/upload/' + fileId + '?licenseCode=' + licenseCode, fileUploadObject)
}

function update(id, file) {
    return api.put(BASE_URL + "/" + id, file)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function importFile(importRequest) {
    return api.put('/file/importFile/', importRequest)
}
