import React, {useState} from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { documentationService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function RenameButton(props) {

	const [name, setName] = useState(props.documentation.name)
	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const {t} = useTranslation('common')

	const onClick = () => {
        setShow(true)
    }

    const handleSubmit = (event) => {
	    event.preventDefault()
	    documentationService.rename(props.documentation.id, name).then(result => {
	        handleClose()
			props.onRenamed(result.name)
        })
	}

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    const handleChange = (e) => {
		setName(e.target.value)
    }

	return (
        <>
	        <Button
	            onClick={onClick}
	            title={t('documentation.rename')}
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={faPenToSquare}/> {t('documentation.rename')}
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('documentation.rename')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="name">
		                <Form.Label>
		                    {t('documentation.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('documenation.columns.name')} name="name" value={name} onChange={handleChange} />
		            </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' variant="primary" onClick={handleSubmit}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default RenameButton