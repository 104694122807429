import React, {useState} from 'react'
import { Button, Modal, Form, Col, Alert } from 'react-bootstrap'
import { photoService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import TextareaAutosize from 'react-textarea-autosize';

function PhotoImportButton(props) {

	const [documentation, setDocumentation] = useState(props.documentation)
	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const [selectedTask, setSelectedTask] = useState("")
	const [data, setData] = useState("")
	const [name, setName] = useState("")
	const [comment, setComment] = useState("")
	const [showNoTaskSelectedError, setShowNoTaskSelectedError] = useState(false)
	const [showNoFileSelectedError, setShowNoFileSelectedError] = useState(false)
	const {t} = useTranslation('common')

	const toggle = () => {
		console.log('toggle')

		setShow(true)

		console.log(props.documentationTasks)
    }

    const onHide = () => {
        setSelectedTask("")
        setData("")
		setName("")
		setComment("")
		setShowNoTaskSelectedError(false)
		setShowNoFileSelectedError(false)

		setShow(false)
		setBackdrop(false)
    }

    const handleFileChange = (e) => {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
				setData(hexString)
            });
            reader.readAsBinaryString(file)

            setShowNoFileSelectedError(false)
        }
    }

    const handleTaskChange = (e) => {
        let taskId = e.target.value

        setSelectedTask(taskId)

        if (taskId) {
	        let task = props.documentationTasks.find(task => task.id == taskId)
	        let taskPhotos = props.photos.filter(photo => photo.documentationTaskId == taskId)

	        var index = 1
	        var photoName = task.name + '_01'

	        while (taskPhotos.some(photo => photo.name == photoName)) {
	            index++
	            photoName = task.name + '_'
	            if (index < 10) {
	                photoName += '0'
	            }
	            photoName += index
	        }

	        setName(photoName)

	        setShowNoTaskSelectedError(false)
        } else {
            setName("")
        }
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value)
    }

    const doImport = () => {
        console.log('doImport')

        setShowNoTaskSelectedError(false)
        setShowNoFileSelectedError(false)

        if (selectedTask.length == 0) {
            setShowNoTaskSelectedError(true)
            return
        }

        if (data.length == 0) {
            setShowNoFileSelectedError(true)
            return
        }

        var importRequest = {
            customerId: documentation.customerId,
            documentationId: documentation.id,
            taskId: selectedTask,
            photoData: data,
            name: name,
            comment: comment
        }

        photoService.importPhoto(importRequest).then(newPhoto => {
            if (props.callback) {
                props.callback(newPhoto)
            }

            onHide()
        })
    }

	return (
		<>
			<Button variant="outline-dark" onClick={toggle}>
				<FontAwesomeIcon icon={faFileImport} style={{color: 'gray'}}/> {t('documentation.importphoto')}
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('documentation.importfile')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="task">
						<Form.Label>
		                    {t('documentation.task')}
		                </Form.Label>
	                    <Form.Control required as="select" name="task" value={selectedTask} onChange={handleTaskChange}>
	                        <option value="">{t('documentation.choosetask')}</option>
	                        { props.documentationTasks.filter(task => task.type == "PHOTO").sort((a, b) => a.name.localeCompare(b.name)).map(task => {
	                            return <option value={task.id} key={task.id}>{task.name}</option>
	                        })}
		                </Form.Control>
		                { showNoTaskSelectedError &&
							<Col sm="12">
								<Alert variant="danger" style={{marginTop: '5px'}}>
				                    {t('documentation.notaskselectederror')}
				                </Alert>
			                </Col>
						}
	                </Form.Group>
	                <Form.Group controlId="name">
						<Form.Label>
		                    {t('photo.columns.name')}
		                </Form.Label>
		                <input className="form-control" value={name} disabled={true}/>
	                </Form.Group>
	                <Form.Group controlId="comment">
						<Form.Label>
		                    {t('photo.columns.comment')}
		                </Form.Label>
		                <TextareaAutosize className="form-control" placeholder={t('photo.columns.comment')} name="comment" value={comment} onChange={handleCommentChange}/>
	                </Form.Group>
	                <Form.Group controlId="file">
						<Form.Label>
		                    {t('documentation.photo')}
		                </Form.Label>
		                <Form.Control type="file" accept=".jpg,.jpeg" name="file" onChange={handleFileChange} enabled="false"/>
		                { showNoFileSelectedError &&
							<Col sm="12">
								<Alert variant="danger" style={{marginTop: '5px'}}>
				                    {t('documentation.nofileselectederror')}
				                </Alert>
			                </Col>
						}
	                </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-dark" onClick={doImport}>
						{t('documentation.import')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PhotoImportButton