import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import Select from 'react-select'

function DocumentationTaskFilter({filters, setFilters}) {
	const [showModal, setShowModal] = useState(false)
	const [backdrop, setBackdrop] = useState(false)

	const {t} = useTranslation('common')

	const onHideModal = () => {
		setShowModal(false)
		setBackdrop(false)
    }

    const toggleFilter = (filter) => {
		filter.active = !filter.active
		let newFilters = [...filters]
		setFilters(newFilters)
    }

	return (
		<>
			<Button variant="outline-dark" onClick={(e) => setShowModal(true)}>
				{t('documentation.taskfilter')}
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHideModal}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('documentation.taskfilter')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ filters.sort((a, b) => a.position - b.position).map(filter => {
						return <Form.Group controlId={'active-' + filter.id} key={filter.id} style={{marginBottom: '0px'}}>
							<Form.Label>
								<Form.Check type="checkbox" name="active" label={filter.name} checked={filter.active} onChange={(e) => toggleFilter(filter)} />
					        </Form.Label>
					    </Form.Group>
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={(e) => onHideModal(true)}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default DocumentationTaskFilter