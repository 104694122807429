import React, {useState, useEffect} from 'react';
import { customerService, documentationService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import Select from 'react-select'
import moment from 'moment'

function DeleteDocumentations() {

	const [customers, setCustomers] = useState([])
	const [documentations, setDocumentations] = useState([])
	const [selectedCustomer, setSelectedCustomer] = useState("")
	const [selectedDocumentations, setSelectedDocumentations] = useState([])
	const [deleting, setDeleting] = useState(false)
	const dispatch = useDispatch();
	const {t} = useTranslation('common')

	useEffect(() => {
	    customerService.getOptions().then(customers => {
		    customers.sort((a, b) => a.name.localeCompare(b.name))
			setCustomers(customers)
			if (!selectedCustomer && customers.length > 0) {
                setSelectedCustomer(customers[0].id)
            }
	    })
	}, [])

	useEffect(() => {
		if (selectedCustomer) {
		    documentationService.findAllByCustomerId(selectedCustomer).then(documentations => {
				documentations.sort((a, b) => a.name.localeCompare(b.name))
				setDocumentations(documentations)
		    })
	    }
	}, [selectedCustomer])

	const handleCustomerChange = (e) => {
	    const target = e.target
	    const value = target.value
	    setSelectedCustomer(value)
	    setSelectedDocumentations([])
	}

	const handleDocumentationChange = (e) => {
		const target = e.target
		const value = target.value
		const name = target.name
		if (selectedDocumentations.indexOf(name) == -1) {
			var newSelectedDocumentations = [...selectedDocumentations]
			newSelectedDocumentations.push(name)
			setSelectedDocumentations(newSelectedDocumentations)
		} else {
			var newSelectedDocumentations = selectedDocumentations.filter(documentationId => documentationId != name)
			setSelectedDocumentations(newSelectedDocumentations)
		}
	}

	const submit = (e) => {
		console.log('submit')

		e.preventDefault()
        e.stopPropagation()

        setDeleting(true)

        var promises = []

        selectedDocumentations.forEach(documentationId => {
            console.log(documentationId)

			promises.push(documentationService._delete(documentationId))
        })

        Promise.all(promises).then((values) => {
			setDeleting(false)

			dispatch(alertActions.success(t('alerts.deletesuccessful')))

			documentationService.findAllByCustomerId(selectedCustomer).then(documentations => {
				documentations.sort((a, b) => a.name.localeCompare(b.name))
				setDocumentations(documentations)
		    })
        })
	}

	const selectAll = () => {
		setSelectedDocumentations(documentations.filter(documentation => documentation.customerId == selectedCustomer).map(documentation => documentation.id))
	}

	const selectNone = () => {
		setSelectedDocumentations([])
	}

	const isChecked = (documentation) => {
		return selectedDocumentations.indexOf(documentation.id) != -1
	}

	const isRequired = (documentation) => {
		return selectedDocumentations.length == 0
	}

	const formatDate = (date) => {
		if (date) {
			return moment(date).format('YYYY-MM-DD HH:mm:ss')
		}
		return '-'
	}

	return (
		<Container>
			<h2>
				{t('deletedocumentations.label')}
			</h2>
			{ documentations && customers &&
				<Row>
					<Col md={{ span: 6, offset: 0 }}>
						<Form id="data-form" onSubmit={submit}>
				            <Form.Group controlId="customer">
				                <Form.Label>
				                    {t('deletedocumentations.columns.customer')} *
				                </Form.Label>
				                <Form.Control required as="select" placeholder={t('deletedocumentations.columns.customer')} name="customer" onChange={handleCustomerChange}>
				                    { customers.map(customer => (
				                        <option key={customer.id} value={customer.id}>{customer.name}</option>
				                    ))}
				                </Form.Control>
				            </Form.Group>
				            <Form.Group controlId="documentations">
				                <Form.Label>
				                    {t('deletedocumentations.columns.documentations')} *
				                </Form.Label>
				                <br/>
				                <Button onClick={(e) => selectAll()} variant="outline-primary">
									{t('deletedocumentations.buttons.selectall')}
								</Button>
								<Button onClick={(e) => selectNone()} variant="outline-primary">
									{t('deletedocumentations.buttons.selectnone')}
								</Button>
								<br/>
				                { documentations.filter(documentation => documentation.customerId == selectedCustomer).map(documentation => (
				                    <Form.Group key={documentation.id} controlId={documentation.id} style={{marginBottom: 0}}>
					                    <Form.Label>
						                    <Form.Check
						                        type="checkbox"
						                        name={documentation.id}
						                        label={documentation.name + ' (' + formatDate(documentation.dateCreated) + ')'}
						                        checked={isChecked(documentation)}
						                        required={isRequired()}
						                        onChange={handleDocumentationChange}/>
						                </Form.Label>
					                </Form.Group>
			                    ))}
				            </Form.Group>
						</Form>
						<Button form='data-form' type="submit" variant="primary" disabled={documentations.length == 0}>
							{t('deletedocumentations.buttons.delete')}
							{ deleting &&
								<>
									&nbsp;
						            <Spinner animation="border" role="status" size="sm">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</>
							}
						</Button>
					</Col>
				</Row>
			}
		</Container>
	)
}

export default DeleteDocumentations;