import React, { useContext } from 'react'
import { Form, Row, Col, Card, Dropdown, Button, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faFileZipper, faImage, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

function Node({node, templateExportConfiguration, exportConfiguration, parent, exportDocumentTypes, exportDocumentConfigurations, addNode, removeNode, handleNodeParamsChange}) {

	const {t} = useTranslation('common')

	const getNodeIcon = (node) => {
	    switch (node.type) {
			case 'FOLDER':
				return <FontAwesomeIcon icon={faFolder}/>
			case 'ZIP_FILE':
				return <FontAwesomeIcon icon={faFileZipper}/>
			case 'PHOTOS':
				return <FontAwesomeIcon icon={faImage}/>
			case 'FILES':
			case 'EXTERNAL_FILES':
				return <FontAwesomeIcon icon={faFile}/>
			case 'EXPORT_DOCUMENT_CONFIGURATION':
				return <FontAwesomeIcon icon={faFile}/>
			case 'EXPORT_DOCUMENT_TYPE':
				return <FontAwesomeIcon icon={faFile}/>
		}
		return <FontAwesomeIcon icon={faFile}/>
    }

    const getExportDocumentTypeOptionLabel = (exportDocumentType) => {
        switch (exportDocumentType) {
            case 'PHOTO_POWERPOINT_PRESENTATION':
                return t('exportdocumenttype.photopowerpointpresentation')
            case 'PHOTO_WORD_DOCUMENT':
                return t('exportdocumenttype.photowordocument')
            case 'PHOTO_EXCEL_WORKBOOK':
                return t('exportdocumenttype.photoexcelworkbook')
            case 'FORM_POWERPOINT_PRESENTATION':
                return t('exportdocumenttype.formpowerpointpresentation')
            case 'FORM_WORD_DOCUMENT':
                return t('exportdocumenttype.formwordocument')
            case 'FORM_EXCEL_WORKBOOK':
                return t('exportdocumenttype.formexcelworkbook')
            case 'FORM_3GIS_PM':
                return 'Formulär 3GIS PM'
        }
        return exportDocumentType
    }

    return (
        <Card>
	        <Card.Body>
	            <div className="clearfix">
					<div className="float-left">
	                    {getNodeIcon(node)} {node.type}
	                </div>
	                <div className="float-right">
						<Button
				            onClick={(e) => removeNode(e, templateExportConfiguration, parent, node)}
				            title={t("crudtable.delete.button")}
				            size="sm"
				            color="info"
				            variant="outline-danger">
				            <FontAwesomeIcon icon={faTrash}/>
				        </Button>
	                </div>
				</div>
				<div id="params">
					{ node.type != 'PHOTOS' && node.type != 'FILES' && node.type != 'EXTERNAL_FILES' &&
						<Form.Group controlId="name">
							<Form.Label>
			                    {t('templateexportconfiguration.params.name')}
			                </Form.Label>
			                <InputGroup>
				                <Form.Control
				                    type="text"
				                    placeholder={t('templateexportconfiguration.params.name')}
				                    name="name"
				                    value={node.params.name || ''}
				                    onChange={e => handleNodeParamsChange(e, node)}
				                />
				                <Dropdown>
                                    <Dropdown.Toggle variant="primary">
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        { ['DOCUMENTATION_NAME', 'TEMPLATE_NAME', 'CREATOR', 'EXPORT_DATE', 'EXPORT_TIME', 'DATE_CREATED', 'DATE_UPLOADED', 'TASK_ANSWER'].map(item => {
                                            return <Dropdown.Item
                                                key={item}
                                                onClick={e => handleNodeParamsChange({target: {name: 'name', value: (node.params.name || '') + '{' + item + '}'}}, node)}
                                            >
                                                {item}
                                            </Dropdown.Item>
                                        })}
									</Dropdown.Menu>
                                </Dropdown>
			                </InputGroup>
						</Form.Group>
					}
					{ node.type == 'PHOTOS' &&
						<Form.Group controlId="compressPhotos">
							<Form.Label>
                                <Form.Check
                                    id={node.id + "-compressPhotos"}
                                    type="checkbox"
                                    name="compressPhotos"
                                    label={t('templateexportconfiguration.params.compressphotos')}
                                    checked={node.params.compressPhotos || false}
                                    onChange={e => handleNodeParamsChange(e, node)}
                                />
                            </Form.Label>
						</Form.Group>
					}
					{ node.type == 'EXPORT_DOCUMENT_TYPE' &&
						<Form.Group controlId="exportDocumentType">
							<Form.Label>
			                    {t('templateexportconfiguration.params.exportdocumenttype')}
			                </Form.Label>
			                <Form.Control as="select" name="exportDocumentType" value={node.params['exportDocumentType'] || ''} onChange={e => handleNodeParamsChange(e, node)} required>
			                    <option value="">{t('templateexportconfiguration.choose')}</option>
			                    { exportDocumentTypes.map(exportDocumentType => {
			                        return <option key={exportDocumentType} value={exportDocumentType}>
			                            {getExportDocumentTypeOptionLabel(exportDocumentType)}
			                        </option>
			                    })}
			                </Form.Control>
						</Form.Group>
					}
					{ node.type == 'EXPORT_DOCUMENT_CONFIGURATION' &&
						<Form.Group controlId="exportDocumentConfiguration">
							<Form.Label>
			                    {t('templateexportconfiguration.params.exportdocumentconfiguration')}
			                </Form.Label>
			                <Form.Control as="select" name="exportDocumentConfiguration" value={node.params['exportDocumentConfiguration'] || ''} onChange={e => handleNodeParamsChange(e, node)} required>
			                    <option value="">{t('templateexportconfiguration.choose')}</option>
			                    { exportDocumentConfigurations.map(exportDocumentConfiguration => {
			                        return <option key={exportDocumentConfiguration.id} value={exportDocumentConfiguration.id}>
			                            {exportDocumentConfiguration.name}
			                        </option>
			                    })}
			                </Form.Control>
						</Form.Group>
					}
				</div>
				{ node.children.map(child => {
                    return <Node
                        key={child.id}
                        node={child}
                        templateExportConfiguration={templateExportConfiguration}
                        exportConfiguration={exportConfiguration}
                        parent={node}
                        exportDocumentTypes={exportDocumentTypes}
                        exportDocumentConfigurations={exportDocumentConfigurations}
                        addNode={addNode}
                        removeNode={removeNode}
                        handleNodeParamsChange={handleNodeParamsChange}
                    />
                })}
                { (node.type == 'FOLDER' || node.type == 'ZIP_FILE') &&
	                <Dropdown>
			            <Dropdown.Toggle variant="outline-primary">
							{t('templateexportconfiguration.addnode')}
						</Dropdown.Toggle>
						<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
							{ node.type == 'FOLDER' &&
								<>
									<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'FOLDER')}>
										{t('templateexportconfiguration.nodes.folder')}
									</Dropdown.Item>
									<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'ZIP_FILE')}>
										{t('templateexportconfiguration.nodes.zipfile')}
									</Dropdown.Item>
								</>
							}
							<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'PHOTOS')}>
								{t('templateexportconfiguration.nodes.photos')}
							</Dropdown.Item>
							<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'FILES')}>
								{t('templateexportconfiguration.nodes.files')}
							</Dropdown.Item>
							<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'EXTERNAL_FILES')}>
								{t('templateexportconfiguration.nodes.externalfiles')}
							</Dropdown.Item>
							<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'EXPORT_DOCUMENT_CONFIGURATION')}>
								{t('templateexportconfiguration.nodes.exportdocumentconfiguration')}
							</Dropdown.Item>
							<Dropdown.Item onClick={e => addNode(e, templateExportConfiguration, node, 'EXPORT_DOCUMENT_TYPE')}>
								{t('templateexportconfiguration.nodes.exportdocumenttype')}
							</Dropdown.Item>
						</Dropdown.Menu>
			        </Dropdown>
		        }
	        </Card.Body>
		</Card>
    )
}

export default Node