import React, {useState} from 'react'
import { Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap'
import { documentationService, exportEventService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faTrash, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { SERVER_URL } from '../../config';
import moment from 'moment'

function ExportButton(props) {

	const [documentation, setDocumentation] = useState(props.documentation)
	const [template, setTemplate] = useState(props.template)
	const [photos, setPhotos] = useState(props.photos)
	const [files, setFiles] = useState(props.files)
	const [tasks, setTasks] = useState(props.tasks)
	const [exportEvents, setExportEvents] = useState([])
	const [selectedTemplateExportConfigurations, setSelectedTemplateExportConfigurations] = useState([])
	const [selectedPhotos, setSelectedPhotos] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([])
	const [showNoExportConfigurationsSelectedError, setShowNoExportConfigurationsSelectedError] = useState(false)
	const [showNoPhotosSelectedError, setShowNoPhotosSelectedError] = useState(false)
	const [showRequiredUserParamError, setShowRequiredUserParamError] = useState(false)
	const [showUnhandledFileTasksError, setShowUnhandledFileTasksError] = useState(false)
	const [externalFiles, setExternalFiles] = useState([])
	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const [modalType, setModalType] = useState('CONFIG')
	const [timer, setTimer] = useState(null)
	const [exportStartTime, setExportStartTime] = useState('')
	const {t} = useTranslation('common')

	const onClick = () => {
		console.log('ExportButton onClick')

		if (documentation.status == 'PAUSED' || documentation.status == 'DOWNLOADED') {
			setModalType('PAUSED_ERROR')
		} else {
			setModalType('CONFIG')

			setSelectedTemplateExportConfigurations(template.templateExportConfigurations.map(exportConfiguration => exportConfiguration.id))
			setSelectedPhotos(photos.map(photo => photo.id))
			setSelectedFiles(files.map(file => file.id))
		}

		setShow(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
		if (timer) {
			clearInterval(timer)
		}
		setModalType('CONFIG')
    }

    const getUserParams = () => {
        let userParams = []
        template.templateExportConfigurations.forEach(templateExportConfiguration => {
            var exportConfiguration = props.exportConfigurations.find(exportConfiguration => exportConfiguration.id == templateExportConfiguration.exportConfiguration)
            exportConfiguration.userParams.forEach(userParam => {
                let other = userParams.find(other => other.name === userParam.name)
                if (other) {
                    other.required = other.required || userParam.required
                } else {
                    userParams.push(userParam)
                }
            })
        })
        return userParams
    }

    const handleUserParamChange = (e, userParam) => {
        userParam.value = e.target.value
    }

    const handleExternalFilesChange = (e) => {
        let files = e.target.files
        if (files && files.length > 0) {
            var promises = []
            for (var i = 0; i < files.length; i++) {
                let file = files[i]
                let promise = new Promise((resolve, reject) => {
                    let fileReader = new FileReader()
                    fileReader.onload = function() {
                        let binaryString = fileReader.result;
                        let hexString = btoa(binaryString)
                        console.log(file)
                        var externalFile = {
							name: file.name,
							size: file.size,
							data: hexString,
							type: file.type
						}
                        resolve(externalFile)
                    }

                    fileReader.onerror = function() {
                        reject(fileReader)
                    }

                    fileReader.readAsBinaryString(file)
                })
                promises.push(promise)
            }

            Promise.all(promises).then(values => {
                var newExternalFiles = [...externalFiles]
                values.forEach(value => {
                    if (!newExternalFiles.some(externalFile => externalFile.name == value.name)) {
                        newExternalFiles.push(value)
                    }
                })
                setExternalFiles(newExternalFiles)
            })
        }
    }

    const removeExternalFile = (e, externalFile) => {
        e.preventDefault()

        var newExternalFiles = externalFiles.filter(otherExternalFile => otherExternalFile != externalFile)
        setExternalFiles(newExternalFiles)
    }

    const toggleTemplateExportConfiguration = (templateExportConfigurationId) => {
        var templateExportConfigurationIds = [...selectedTemplateExportConfigurations]
        var indexOfExportConfigurationId = templateExportConfigurationIds.indexOf(templateExportConfigurationId)
        if (indexOfExportConfigurationId == -1) {
            templateExportConfigurationIds.push(templateExportConfigurationId)
            setShowNoExportConfigurationsSelectedError(false)
        } else {
			templateExportConfigurationIds.splice(indexOfExportConfigurationId, 1);
        }
        setSelectedTemplateExportConfigurations(templateExportConfigurationIds)
    }

    const toggleExportPhoto = (photoId) => {
        var photoIds = [...selectedPhotos]
        var indexOfPhotoId = photoIds.indexOf(photoId)
        if (indexOfPhotoId == -1) {
            photoIds.push(photoId)
        } else {
			photoIds.splice(indexOfPhotoId, 1);
        }
        setSelectedPhotos(photoIds)
    }

    const toggleExportFile = (fileId) => {
        var fileIds = [...selectedFiles]
        var indexOfFileId = fileIds.indexOf(fileId)
        if (indexOfFileId == -1) {
            fileIds.push(fileId)
        } else {
			fileIds.splice(indexOfFileId, 1);
        }
        setSelectedFiles(fileIds)
    }

    const selectAllTemplateExportConfigurations = () => {
		setSelectedTemplateExportConfigurations(template.templateExportConfigurations.map(exportConfiguration => exportConfiguration.id))
    }

    const selectNoTemplateExportConfigurations = () => {
		setSelectedTemplateExportConfigurations([])
    }

    const getExportStatus = (exportConfigurationId) => {
        var tmp = exportEvents.filter(exportEvent => {
            return exportEvent.exportConfigurationId == exportConfigurationId && moment(exportEvent.dateCreated).isSameOrAfter(exportStartTime, 'second')
        })
        if (tmp.length > 0) {
            if (tmp.some(exportEvent => !exportEvent.success)) {
                return <FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
            } else {
                return <FontAwesomeIcon icon={faCheck} style={{color: '#1f6e43'}}/>
            }
        }
        return <img src="/spinner.gif"/>
    }

    const doExport = () => {
        console.log('doExport')

        setShowNoExportConfigurationsSelectedError(false)
        setShowNoPhotosSelectedError(false)
        setShowRequiredUserParamError(false)
        setShowUnhandledFileTasksError(false)

        if (selectedTemplateExportConfigurations.length == 0) {
            setShowNoExportConfigurationsSelectedError(true)
            return
        }

//        if (selectedPhotos.length == 0) {
//            setShowNoPhotosSelectedError(true)
//            return
//        }

        var params = {}
        var userParams = getUserParams()
        var missingUserParam = false
        userParams.forEach(userParam => {
            params[userParam.name] = userParam.value
            if (userParam.required && !userParam.value) {
                missingUserParam = true
            }
        })

        if (missingUserParam) {
            setShowRequiredUserParamError(true)
            return
        }

        var fileTasks = tasks.filter(task => task.type == 'FILE')
        var unhandledFileTasks = fileTasks.some(task => {
            return task.required == 'NO_WITH_LATER_UPLOAD' && !files.some(file => file.documentationTaskId === task.id)
        })

		if (unhandledFileTasks) {
			setShowUnhandledFileTasksError(true)
			return
		}

        var exportRequest = {
            customerId: documentation.customerId,
            documentationId: documentation.id,
            templateExportConfigurationIds: selectedTemplateExportConfigurations,
            photoIds: selectedPhotos,
            fileIds: selectedFiles,
            externalFiles: externalFiles,
            params: params
        }

        setModalType('EXPORTING')
        setExportStartTime(moment())

        var timer = setInterval(() => {
            exportEventService.findAllByDocumentation(documentation.id).then(data => {
	            console.log(data)
	            data.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated))
	            setExportEvents(data)
	            if (props.onUpdateExportEvents) {
	                props.onUpdateExportEvents(data)
	            }
	        })
        }, 5000)
        setTimer(timer)
        documentationService.manualExport(exportRequest).then(data => {
            console.log(data)
        })
    }

	return (
		<>
			<Button variant="outline-dark" onClick={onClick}>
				<FontAwesomeIcon icon={faFileExport} style={{color: 'gray'}}/> {t('documentation.export')}
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('documentation.export')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ modalType == 'CONFIG' &&
						<>
							<Row>
								<Col sm="12">
				                    {t('exportconfiguration.label')}
				                    &nbsp;
				                    <Button variant="outline-dark" size="sm" onClick={selectAllTemplateExportConfigurations}>
										{t('documentation.selectall')}
									</Button>
									&nbsp;
									<Button variant="outline-dark" size="sm" onClick={selectNoTemplateExportConfigurations}>
										{t('documentation.selectnone')}
									</Button>
				                </Col>
			                </Row>
							{ template && template.templateExportConfigurations.length > 0 &&
								<Row style={{marginBottom: '1rem'}}>
									<Col sm="12">
										{ template.templateExportConfigurations.sort((a, b) => a.position - b.position).map(templateExportConfiguration => {
											return <Form.Group controlId={templateExportConfiguration.id} key={templateExportConfiguration.id} style={{marginBottom: '0px'}}>
												<Form.Label>
				                                    <Form.Check
				                                        type="checkbox"
				                                        name="template-export-configuration"
				                                        label={templateExportConfiguration.exportConfigurationName}
				                                        checked={selectedTemplateExportConfigurations.indexOf(templateExportConfiguration.id) != -1}
				                                        onChange={(e) => toggleTemplateExportConfiguration(templateExportConfiguration.id)}
				                                    />
				                                </Form.Label>
				                            </Form.Group>
										})}
									</Col>
									{ showNoExportConfigurationsSelectedError &&
										<Col sm="12">
											<Alert variant="danger" style={{marginTop: '5px'}}>
							                    {t('documentation.noexportconfigurationsselectederror')}
							                </Alert>
						                </Col>
									}
									<Col sm="12">
										<table>
											<tbody>
												{ getUserParams().map(userParam => {
													return <tr key={userParam.id}>
														<td>
															{userParam.name} {userParam.required ? '*' : ''}
														</td>
														<td>
															<Form.Control required={userParam.required} type="text" placeholder={userParam.name} name={userParam.name} value={userParam.value} onChange={e => handleUserParamChange(e, userParam)} />
														</td>
													</tr>
												})}
											</tbody>
										</table>
									</Col>
									{ showRequiredUserParamError &&
										<Col sm="12">
											<Alert variant="danger" style={{marginTop: '5px'}}>
							                    {t('documentation.requireduserparamerror')}
							                </Alert>
						                </Col>
									}
								</Row>
							}
							{ files.length > 0 &&
								<Row style={{marginBottom: '1rem'}}>
									<Col sm="12">
					                    {t('documentation.columns.files')}
					                    &nbsp;
					                    <Button variant="outline-dark" size="sm" onClick={e => setSelectedFiles(files.map(file => file.id))}>
											{t('documentation.selectall')}
										</Button>
										&nbsp;
										<Button variant="outline-dark" size="sm" onClick={e => setSelectedFiles([])}>
											{t('documentation.selectnone')}
										</Button>
									</Col>
									<Col sm="12">
										{ files.map(file => {
											return <Form.Group controlId={file.id} key={file.id} style={{marginBottom: '0px'}}>
					                            <Form.Label>
					                                <Form.Check type="checkbox" name="file" label={file.name} checked={selectedFiles.indexOf(file.id) != -1} onChange={(e) => toggleExportFile(file.id)} />
					                            </Form.Label>
						                    </Form.Group>
										})}
									</Col>
								</Row>
							}
							{ template && template.includeExternalFiles &&
								<Row style={{marginBottom: '1rem'}}>
									<Col sm="12">
										<Form.Group controlId="externalFiles">
											<Form.Label>
							                    {t('documentation.externalfiles')}
							                </Form.Label>
											<Form.Control type="file" multiple name="externalFiles" onChange={handleExternalFilesChange} style={{color: 'transparent'}}/>
			                            </Form.Group>
									</Col>
									<Col sm="12">
										<table>
											<tbody>
												{ externalFiles.map(externalFile => {
													return <tr key={externalFile.name}>
														<td>
															{externalFile.name}
														</td>
														<td style={{ paddingLeft: 10 }}>
															<a href="#" onClick={e => removeExternalFile(e, externalFile)}>
																<FontAwesomeIcon icon={faTrash} style={{color: 'red'}}/>
															</a>
														</td>
													</tr>
												})}
											</tbody>
										</table>
									</Col>
								</Row>
							}
							<Row>
								<Col sm="12">
					                {t('photo.label')}
				                    &nbsp;
				                    <Button variant="outline-dark" size="sm" onClick={e => setSelectedPhotos(photos.map(photo => photo.id))}>
										{t('documentation.selectall')}
									</Button>
									&nbsp;
									<Button variant="outline-dark" size="sm" onClick={e => setSelectedPhotos([])}>
										{t('documentation.selectnone')}
									</Button>
				                </Col>
			                </Row>
							<Row>
				                { photos.map(photo => {
				                    return <Col lg="3" key={photo.id}>
				                        <Form.Group controlId={photo.id}>
				                            <Form.Label>
				                                <Form.Check type="checkbox" name="export-photo" label={photo.alias || photo.name} checked={selectedPhotos.indexOf(photo.id) != -1} onChange={(e) => toggleExportPhoto(photo.id)} />
				                            </Form.Label>
				                            <a onClick={e => toggleExportPhoto(photo.id)}>
					                            <img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: '100%'}}/>
					                        </a>
					                    </Form.Group>
				                    </Col>
				                })}
				                { showNoPhotosSelectedError &&
									<Col sm="12">
										<Alert variant="danger" style={{marginTop: '5px'}}>
						                    {t('documentation.nophotosselectederror')}
						                </Alert>
					                </Col>
								}
				            </Row>
				            { showUnhandledFileTasksError &&
				                <Row style={{marginTop: '20px'}}>
									<Col sm="12">
										<Alert variant="danger" style={{marginTop: '5px'}}>
						                    {t('documentation.unhandledfiletaskserror')}
						                </Alert>
					                </Col>
				                </Row>
							}
			            </>
		            }
		            { modalType == 'EXPORTING' &&
						<table className="table">
							<tbody>
								{ selectedTemplateExportConfigurations.sort((a, b) => a.position - b.position).map(templateExportConfigurationId => {
									var templateExportConfiguration = template.templateExportConfigurations.find(templateExportConfiguration => templateExportConfiguration.id == templateExportConfigurationId)
									return <tr key={"exporting-table-tr-" + templateExportConfiguration.id}>
										<td style={{"border": "0px"}}>
											{templateExportConfiguration.exportConfigurationName}
										</td>
										<td style={{"border": "0px"}}>
											{getExportStatus(templateExportConfiguration.exportConfiguration)}
										</td>
									</tr>
								})}
							</tbody>
						</table>
					}
					{ modalType == 'PAUSED_ERROR' &&
						<div>
							{t('documentation.exportpauseddocumentationerror')}
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					{ modalType == 'CONFIG' &&
						<Button variant="outline-dark" onClick={doExport}>
							{t('documentation.export')}
						</Button>
					}
					{ (modalType == 'EXPORTING' || modalType == 'PAUSED_ERROR') &&
						<Button variant="outline-dark" onClick={onHide}>
							{t('crudtable.ok')}
						</Button>
					}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ExportButton