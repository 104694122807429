import React, { useEffect, useState } from 'react';
import CrudTable from '../table/crudtable'
import { exportDocumentConfigurationService, documentationService, customerService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { Modal, Button, Form } from 'react-bootstrap'
import { history } from '../../_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import TestConfiguration from './testconfiguration'
import CopyConfiguration from './copyconfiguration'

function ExportDocumentConfigurationList() {

	const [customers, setCustomers] = useState([]);
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');

	useEffect(() => {
        customerService.list().then(data => {
            setCustomers(data)
        })
    }, []);

	const initialState = {
		name: '',
		description: '',
		type: 'POWERPOINT',
		nodes: []
	}

    const onAddClick = (row) => {
	    console.log('onAddClick')
		history.push("/exportDocumentConfiguration/create")
    }

    const onEditClick = (row) => {
	    console.log('onEditClick')
		history.push("/exportDocumentConfiguration/" + row.id)
    }

	const columns = [
		{
			dataField: 'name',
			text: t('exportdocumentconfiguration.columns.name'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('exportdocumentconfiguration.columns.description'),
			formatter: cell => cell || '-',
			sort: true
		},
		{
			dataField: 'type',
			text: t('exportdocumentconfiguration.columns.type'),
			sort: true
		},
		{
			dataField: 'customers',
			text: 'Kunder',
			formatter: (cell, row) => {
				return customers.filter(customer => customer.exportDocumentConfigurations.some(conf => conf.id == row.id)).length
			}
		},
		{
			dataField: 'id',
			text: '',
			style: {
		        width: '85px'
		    },
			formatter: (cell, row) => {
				return <span>
					<TestConfiguration configuration={row}/>
					<span>&nbsp;&nbsp;</span>
					<CopyConfiguration configuration={row}/>
				</span>
			}
		}
	]

	return (
		<div>
			<h2>
				{t('exportdocumentconfiguration.label')}
			</h2>
			{ customers && customers.length > 0 &&
				<CrudTable
					service={exportDocumentConfigurationService}
					columns={columns}
					initialState={initialState}
					//form={<ExportDocumentConfigurationForm />}
					sort={{dataField: 'name', order: 'asc'}}
					modalSize="lg"
					onAddClick={onAddClick}
					onEditClick={onEditClick}
				/>
			}
		</div>
	)
}

export default ExportDocumentConfigurationList;