import api from './api.service'

export const documentationEventService = {
    list,
    findAllByDocumentationName
};

const BASE_URL = '/documentationEvents'

function list(documentationId, max) {
    return api.get(BASE_URL + '?documentation=' + documentationId, {max : max || 100})
}

function findAllByDocumentationName(documentationName, max) {
	return api.get('/documentationEvent/findAllByDocumentationName/' + documentationName, {max : max || 1000, sort: 'name', order: 'asc'})
}
