import axios from "axios";
import { SERVER_URL } from '../config';
import { history, authHeader } from '../_helpers';
import api from './api.service'

export const authService = {
    login,
    logout,
    isAuthenticated,
    getCurrentUser,
    getToken,
    hasRoles,
    hasProducts,
    hasExportDocumentTypes,
    switchUser,
    switchBack,
    refreshToken,
    updatePassword,
    getSwitchOptions,
    switchCustomer
};

function login(username, password, code) {
    return axios
		.post(SERVER_URL + "/login", {
	        username: username,
	        password: password,
	        code: code
        })
        .then(response => {
	        if (response.data.access_token) {
	            localStorage.setItem("user", JSON.stringify(response.data));
	        }
            return response.data;
        });
}

function hasRoles(roles) {
	const userRoles = new Set(getCurrentUser()?.roles || [])
	return roles.every(role => userRoles.has(role))
}

function hasProducts(products) {
	const userProducts = new Set(getCurrentUser()?.products || [])
	return products.every(product => userProducts.has(product))
}

function hasExportDocumentTypes(exportDocumentTypes) {
    const userExportDocumentTypes = new Set(getCurrentUser()?.exportDocumentTypes || [])
    return exportDocumentTypes.every(exportDocumentType => userExportDocumentTypes.has(exportDocumentType))
}

function isAuthenticated() {
	return !(localStorage.getItem("user") === null)
}

function getCurrentUser() {
	return JSON.parse(localStorage.getItem('user'))
}

function getToken() {
	return this.getCurrentUser().access_token
}

function logout(from) {
	var headers = {
		'Content-Type': 'application/json',
        'Authorization': authHeader()
    }
    axios
        .post(SERVER_URL + "/logout", {}, { headers: headers })
        .then(response => {
            console.log(response)
        })
        .catch(error => {
			console.log(error);
		});

    // remove user from local storage to log user out
    localStorage.removeItem('user');
    const relay = from || history.location
    history.push("/login", { from: relay })
}

function switchUser(user) {
	var headers = {
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    }
    return axios
        .get(SERVER_URL + "/switch/index/" + user, { headers: headers })
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

function switchCustomer(user) {
	var headers = {
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    }
    return axios
        .get(SERVER_URL + "/switch/switchCustomer/" + user, { headers: headers })
        .then(response => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

function switchBack() {
	var headers = {
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    }
    return axios
        .get(SERVER_URL + "/switch/switchBack/", { headers: headers })
        .then(
            response => {
	            if (response.data.access_token) {
	                localStorage.setItem("user", JSON.stringify(response.data));
	            }
	            return response.data;
	        }
        );
}

function refreshToken() {
	var data = {
        refresh_token: this.getCurrentUser().refresh_token
    }
	return api.post("/accessToken/refreshToken", data)
}

function updatePassword(state) {
    return api.put("/user/updatePassword", state)
}

function getSwitchOptions() {
    return api.get("/user/getSwitchOptions")
}
