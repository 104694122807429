import React, { useState } from 'react';
import { Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Forbidden() {

	const {t} = useTranslation('common')
	const location = useLocation()
	//const { from } = location.state
	const [ redirect, setRedirect ] = useState(false);

    const onClick = () => {
        setRedirect(true)
    }

	return (
		<div>
			<h2>
				{t('forbidden.title')}
			</h2>
			<p>
				{t('forbidden.message')}
			</p>
			<Button variant="outline-danger" onClick={onClick}>
                {t('navbar.logout')}
            </Button>
            { redirect &&
                <Redirect to={{ pathname: '/login', state: { from: location ? location.state : null } }} />
            }
		</div>
	)
}

export default Forbidden;