import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { documentationService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function HandledButton(props) {

	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');

	const onClick = () => {
        setShow(true)
    }

    const handleSubmit = (event) => {
	    event.preventDefault()
	    documentationService.changeStatus(props.documentation.id, 'HANDLED').then(result => {
	        handleClose()
			props.onStatusChanged(result.status)
        })
	}

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

	return (
        <>
            { props.documentation.status == 'COMPLETED' &&
                <Button
		            onClick={onClick}
		            title={t('documentation.handled.button')}
		            variant="outline-secondary">
		            <FontAwesomeIcon icon={faCheck}/> {t('documentation.handled.button')}
		        </Button>
            }
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('documentation.handled.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('documentation.handled.message')}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' variant="primary" onClick={handleSubmit}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default HandledButton