import api from './api.service'

export const exportDocumentConfigurationService = {
    list,
    get,
    save,
    update,
    _delete,
    copy
};

const BASE_URL = '/exportDocumentConfigurations'

function list(max) {
    return api.get(BASE_URL, {max: max || 1000, sort: 'name', order: 'asc'})
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(exportDocumentConfiguration) {
    return api.post(BASE_URL, exportDocumentConfiguration)
}

function update(id, exportDocumentConfiguration) {
    return api.put(BASE_URL + "/" + id, exportDocumentConfiguration)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function copy(id, name) {
    return api.get('/exportDocumentConfiguration/copy/' + id + '?name=' + name)
}
