import api from './api.service'

export const expectedAnswerListService = {
    list,
    get,
    save,
    update,
    _delete
};

const BASE_URL = '/expectedAnswerLists'

function list(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function get(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(suggestionList) {
    return api.post(BASE_URL, suggestionList)
}

function update(id, suggestionList) {
    return api.put(BASE_URL + "/" + id, suggestionList)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
