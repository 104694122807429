import React from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function CreatorFilter({creators, creatorFilterList, setCreatorFilterList}) {

	const {t} = useTranslation('common');

	const toggleCreatorFilter = (e) => {
		let name = e.target.name
		var newCreatorFilterList = []
		if (creatorFilterList.includes(name)) {
			newCreatorFilterList = creatorFilterList.filter(creator => creator != name)
		} else {
			newCreatorFilterList = [...creatorFilterList, name]
		}
		setCreatorFilterList(newCreatorFilterList)
	}

	const selectAll = () => {
		setCreatorFilterList([...creators])
	}

	const selectNone = () => {
		setCreatorFilterList([])
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '350px'}}>
					<div style={{paddingBottom: '20px'}}>
						<Button variant="outline-dark" size="sm" onClick={selectAll}>
							{t('documentation.filter.selectall')}
						</Button>
						<Button variant="outline-dark" size="sm" onClick={selectNone}>
							{t('documentation.filter.selectnone')}
						</Button>
					</div>
					{ creators.sort(Intl.Collator().compare).map(creator => {
						return <div key={creator}>
							<label>
								<input type="checkbox" name={creator} checked={creatorFilterList.includes(creator)} onChange={toggleCreatorFilter}/>
								&nbsp;
								{creator}
							</label>
						</div>
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default CreatorFilter;