import React, {useState} from 'react'
import { documentationService } from '../../../_services'
import { Button, Modal, Form, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faTrash, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { SERVER_URL } from '../../../config';
import moment from 'moment'

function ExportButton(props) {

	const [show, setShow] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const [options, setOptions] = useState(['template', 'name'])
	const {t} = useTranslation('common')

	const onClick = () => {
		console.log('ExportButton onClick')

		setShow(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const toggleOptions = e => {
		var newOptions = [...options]
		if (e.target.checked) {
			newOptions.push(e.target.name)
		} else {
			newOptions = newOptions.filter(option => option != e.target.name)
		}
		setOptions(newOptions)
	}

	const exportExcel = e => {
		if (options.length == 0) {
			return
		}

		onHide()
		documentationService.requestExcelExport().then(token => {
			console.log(token)
			window.location.href = SERVER_URL + '/documentation/exportExcel/' + token + '?options=' + options.join(';')
		})
	}

	const selectAll = e => {
		e.preventDefault()
		setOptions(['template', 'name', 'dateUploaded', 'dateCreated', 'creator', 'status'])
	}

	const selectNone = e => {
		e.preventDefault()
		setOptions([])
	}

	return (
		<>
			<Button variant="outline-dark" onClick={onClick}>
				<FontAwesomeIcon icon={faFileExport} style={{color: 'black'}}/> {t('documentation.export')}
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('documentation.export')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('documentation.exportmessage')}
					<br/>
					<br/>
					<a href="#" onClick={selectAll}>
						{t('documentation.selectall')}
					</a>
					&nbsp;/&nbsp;
					<a href="#" onClick={selectNone}>
						{t('documentation.selectnone')}
					</a>
					<br/>
					<label>
						<input type="checkbox" name="template" checked={options.includes('template')} onChange={toggleOptions}/>
						&nbsp;
						{t('documentation.columns.template')}
					</label>
					<br/>
					<label>
						<input type="checkbox" name="name" checked={options.includes('name')} onChange={toggleOptions}/>
						&nbsp;
						{t('documentation.columns.name')}
					</label>
					<br/>
					<label>
						<input type="checkbox" name="dateUploaded" checked={options.includes('dateUploaded')} onChange={toggleOptions}/>
						&nbsp;
						{t('documentation.columns.dateuploaded')}
					</label>
					<br/>
					<label>
						<input type="checkbox" name="dateCreated" checked={options.includes('dateCreated')} onChange={toggleOptions}/>
						&nbsp;
						{t('documentation.columns.datecreated')}
					</label>
					<br/>
					<label>
						<input type="checkbox" name="creator" checked={options.includes('creator')} onChange={toggleOptions}/>
						&nbsp;
						{t('documentation.columns.creator')}
					</label>
					<br/>
					<label>
						<input type="checkbox" name="status" checked={options.includes('status')} onChange={toggleOptions}/>
						&nbsp;
						{t('documentation.columns.status')}
					</label>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="primary" onClick={exportExcel}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ExportButton