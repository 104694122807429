import api from './api.service'

export const photoAliasService = {
    list,
    get,
    save,
    update,
    _delete
};

const BASE_URL = '/photoAlias'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000})
}

function get(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(photoAlias) {
    return api.post(BASE_URL, photoAlias)
}

function update(id, photoAlias) {
    return api.put(BASE_URL + "/" + id, photoAlias)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
