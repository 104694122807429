import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from "./translations/en/common.json";
import common_sv from "./translations/sv/common.json";
import { initReactI18next } from 'react-i18next';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		interpolation: { escapeValue: false },
        fallbackLng: 'en',
        resources: {
            sv: {
                common: common_sv
            },
            en: {
                common: common_en
            }
        }
	});

export default i18n;