import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { authService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

function DelayedSearch({query, setQuery}) {

	const [internalQuery, setInternalQuery] = useState("")
	const {t} = useTranslation('common')
	const timeoutLength = 500

	useEffect(() => {
		const currentUser = authService.getCurrentUser()
		const queryKey = 'query-' + currentUser.id

		if (sessionStorage.getItem(queryKey)) {
            setInternalQuery(sessionStorage.getItem(queryKey))
        }
	}, [])

	useEffect(() => {
		const currentUser = authService.getCurrentUser()
		const queryKey = 'query-' + currentUser.id
		sessionStorage.setItem(queryKey, internalQuery)

	    const timeOutId = setTimeout(() => {
	        setQuery(internalQuery)
	    }, timeoutLength)
	    return () => {
	        clearTimeout(timeOutId)
	    }
	}, [internalQuery])

	return (
		<>
			<input
				placeholder={t('documentation.searchplaceholder')}
				type="text"
				className="form-control"
                value={internalQuery}
                onChange={event => setInternalQuery(event.target.value)}
                style={{width: '300px', marginBottom: '.5rem', display: 'inline-block'}}
            />
            { internalQuery &&
                <FontAwesomeIcon
                    icon={faXmark}
                    onClick={e => setInternalQuery('')}
                    style={{cursor: 'pointer', color: 'red', position: 'relative', left: '-22px', top: '4px', fontSize: '20px'}}/>
            }
		</>
	)
}

export default DelayedSearch;