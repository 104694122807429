import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../config';
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { documentationService, templateService, photoService, documentationTaskService } from '../../_services'
import moment from 'moment'

function ChangeTemplateDetails() {

	const {uuid} = useParams();
	const [documentation, setDocumentation] = useState(null);
	const [documentationTasks, setDocumentationTasks] = useState([]);
	const [photos, setPhotos] = useState([]);
	const [templates, setTemplates] = useState([])
	const [selectedTemplate, setSelectedTemplate] = useState(null)
	const [alias, setAlias] = useState('');
    const {t} = useTranslation('common');
    const dateFormat = 'yyyy-MM-DD HH:mm:ss'

    useEffect(() => {
		documentationService.get(uuid).then(documentation => {
			documentation.newTemplate = -1
            setDocumentation(documentation)

            templateService.findAllByCustomerId(documentation.customerId).then(templates => {
                templates.sort((a, b) => {
	                if (a.position != b.position) {
	                    return a.position - b.position
	                }
	                return a.name.localeCompare(b.name)
	            })
	            setTemplates(templates)
	        })
        })

        documentationTaskService.findAllByDocumentation(uuid).then(data => {
            data.sort((task1, task2) => task1.position - task2.position)
            data.forEach(task => {
                task.newTask = -1
                task.newName = ''
            })
            setDocumentationTasks(data)
        })

        photoService.getAllDocumentationPhotos(uuid).then(data => {
            data.sort((photo1, photo2) => photo1.name.localeCompare(photo2.name))
            data.forEach(photo => {
                photo.newTask = -1
            })
            setPhotos(data)
        })
    }, [])

    function handleTemplateChange(e) {
        let template = templates.find(template => template.id == e.target.value)
        setSelectedTemplate(template)
        documentation.newTemplate = template.id
        documentationTasks.forEach(task => {
            task.newTask = -1
            task.newName = ''
            if (template) {
                let match = template.tasks.find(templateTask => task.name == templateTask.name)
                if (match) {
                    task.newTask = match.id
                    task.newName = match.name
                } else if (template.tasks.length > 0) {
					task.newTask = template.tasks[0].id
                    task.newName = template.tasks[0].name
                }
            }
        })
        setDocumentationTasks(documentationTasks)
        photos.forEach(photo => {
            photo.newTask = -1
            if (template) {
                let match = template.tasks.find(templateTask => photo.name.startsWith(templateTask.name))
                if (match) {
                    photo.newTask = match.id
                } else if (template.tasks.length > 0) {
					photo.newTask = template.tasks[0].id
                }
            }
        })
        setPhotos(photos)
    }

    function handleTaskChange(documentationTask, e) {
		let templateTask = selectedTemplate.tasks.find(templateTask => templateTask.id == e.target.value)
		if (templateTask) {
			documentationTask.newTask = templateTask.id
			documentationTask.newName = templateTask.name
		} else {
			documentationTask.newTask = null
			documentationTask.newName = documentationTask.name
		}
		setDocumentationTasks([...documentationTasks])
    }

    function handlePhotoChange(photo, e) {
		let templateTask = selectedTemplate.tasks.find(templateTask => templateTask.id == e.target.value)
		photo.newTask = templateTask.id
		setPhotos([...photos])
    }

    const getTemplateName = () => {
        let template = templates.find(template => template.id == documentation.templateId)
        if (template) {
            return template.name
        }
		return 'N/A'
    }

    function update() {
		console.log('update')

		var tmp = {
			id: documentation.id,
			templateId: documentation.newTemplate
		}

		tmp.tasks = documentationTasks.map(task => {
			return {
				id: task.id,
				templateTaskId: task.newTask,
				name: task.newName || task.name
			}
		})

		let usedNames = new Set()
		tmp.photos = photos.map(photo => {
			let task = selectedTemplate.tasks.find(task => task.id == photo.newTask)
			var index = 1
			var photoName = task.name + "_" + (index < 10 ? '0' : '') + index
			while (usedNames.has(photoName)) {
				index++
				photoName = task.name + "_" + (index < 10 ? '0' : '') + index
			}
			usedNames.add(photoName)
			return {
				id: photo.id,
				templateTaskId: photo.newTask,
				name: photoName
			}
		})

		console.log(tmp)

		documentationService.update(tmp.id, tmp).then(data => {
            console.log(data)
        })
    }

	return (
		<div>
			{ documentation &&
				<>
					<h1>
						{documentation.name} {alias ? '(' + alias + ')' : ''}
					</h1>
					<div>
						Nuvarande mall: {getTemplateName()}
					</div>
					<Row style={{marginBottom: '50px'}}>
						<Col sm="3">
							<Form.Label>
				                Byt mall till
			                </Form.Label>
							<Form.Control required as="select" name="template" value={selectedTemplate != null ? selectedTemplate.id : -1} onChange={handleTemplateChange}>
								<option value={-1}>{t('template.choosetemplate')}</option>
			                    { templates.map(template => (
			                        <option key={template.id} value={template.id}>
			                            {template.name}
			                        </option>
			                    ))}
			                </Form.Control>
						</Col>
					</Row>
					<Row>
						<Col sm="3">
							<h2>
								Uppgifter
							</h2>
							{ documentationTasks.map(documentationTask => {
								return <div key={documentationTask.id}>
									<Row >
										<Col sm="12">
											<h3>
												{documentationTask.name}
											</h3>
										</Col>
									</Row>
									{ selectedTemplate &&
										<Row style={{marginBottom: '10px'}}>
											<Col sm="12">
												<Form.Label>
									                Ny malluppgift
								                </Form.Label>
												<Form.Control required as="select" name="task" value={documentationTask.newTask} onChange={e => handleTaskChange(documentationTask, e)}>
								                    {selectedTemplate.tasks.map(task => (
								                        <option key={task.id} value={task.id}>
								                            {task.name}
								                        </option>
								                    ))}
								                    <option value={-1}>
							                            Ingen malluppgift
							                        </option>
								                </Form.Control>
											</Col>
										</Row>
									}
								</div>
							})}
						</Col>
						<Col sm="9">
							<h2>
								Foton
							</h2>
							<Row>
								{ photos.map(photo => {
									return <Col key={photo.id} sm="3">
										<b>
											{photo.name}
										</b>
										<img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: '100%'}}/>
						                { selectedTemplate &&
						                    <div>
							                    <Form.Label>
									                Ny malluppgift
								                </Form.Label>
												<Form.Control required as="select" name="task" value={photo.newTask} onChange={e => handlePhotoChange(photo, e)}>
								                    {selectedTemplate.tasks.map(task => (
								                        <option key={task.id} value={task.id}>
								                            {task.name}
								                        </option>
								                    ))}
								                </Form.Control>
							                </div>
						                }
					                </Col>
								})}
							</Row>
						</Col>
					</Row>
					<div className="clearfix">
						<div className="float-right">
							<Button variant="outline-dark" onClick={update}>
								Uppdatera
							</Button>
						</div>
					</div>
					<div style={{height: '50px'}}>
					</div>
				</>
			}
        </div>
    )
}

export default ChangeTemplateDetails;