import api from './api.service'

export const customerService = {
    list,
    getOptions,
    show,
    save,
    update,
    _delete,
    photoDump
};

const BASE_URL = '/customers'

function list(max) {
    return api.get(BASE_URL, {max : max || 100, details : true})
}

function getOptions(max) {
    return api.get(BASE_URL, {max : max || 100, details : false})
}

function show(customerId) {
    return api.get(BASE_URL + '/' + customerId)
}

function save(customer) {
    return api.post(BASE_URL, customer)
}

function update(id, customer) {
    return api.put(BASE_URL + "/" + id, customer)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function photoDump(id) {
    return api.get('/customer/photoDump/' + id)
}
