import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { productService, exportDocumentConfigurationService } from '../../_services'

const CustomerForm = ({data, handleChange, handleSubmit, validated}) => {

	const [products, setProducts] = useState([])
	const [exportDocumentConfigurations, setExportDocumentConfigurations] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        productService.getAll().then(products => {
            setProducts(products)
        })
        exportDocumentConfigurationService.list().then(exportDocumentConfigurations => {
            setExportDocumentConfigurations(exportDocumentConfigurations)
        })
    }, [])

    const handleProductChange = (e) => {
		const target = e.target

	    if (target.checked) {
	        var product = products.find(product => product.id == target.value)
	        data.products.push(product)
	    } else {
	        data.products = data.products.filter(product => product.id != target.value)
	    }
	    handleChange({target: {type: "object", name: "products", value: data.products}})
	    console.log(data.products)
    }

    const handleExportDocumentTypeChange = (e) => {
		const target = e.target

	    if (target.checked) {
	        data.exportDocumentTypes.push(target.value)
	    } else {
	        data.exportDocumentTypes = data.exportDocumentTypes.filter(exportDocumentType => exportDocumentType != target.value)
	    }
	    handleChange({target: {type: "object", name: "exportDocumentTypes", value: data.exportDocumentTypes}})
	    console.log(data.exportDocumentTypes)
    }

    const handleExportDocumentConfigurationChange = (e) => {
		const target = e.target

	    if (target.checked) {
	        var exportDocumentConfiguration = exportDocumentConfigurations.find(exportDocumentConfiguration => exportDocumentConfiguration.id === target.value)
	        data.exportDocumentConfigurations.push(exportDocumentConfiguration)
	    } else {
	        data.exportDocumentConfigurations = data.exportDocumentConfigurations.filter(exportDocumentConfiguration => exportDocumentConfiguration.id != target.value)
	    }
	    handleChange({target: {type: "object", name: "exportDocumentConfigurations", value: data.exportDocumentConfigurations}})
	    console.log(data.exportDocumentConfigurations)
    }

    const getExportDocumentTypes = () => {
        return [
            'PHOTO_POWERPOINT_PRESENTATION',
			'PHOTO_WORD_DOCUMENT',
			'PHOTO_EXCEL_WORKBOOK',
			'FORM_WORD_DOCUMENT',
			'FORM_3GIS_PM',
			'FORM_SHEAB_WORD_DOCUMENT',
			'PHOTO_AXIANS_POWERPOINT_PRESENTATION',
			'RAMSITE_POWERPOINT'
			//'FORM_POWERPOINT_PRESENTATION',
			//'FORM_EXCEL_WORKBOOK'
        ]
    }

    const getExportDocumentTypeOptionLabel = (exportDocumentType) => {
        switch (exportDocumentType) {
            case 'PHOTO_POWERPOINT_PRESENTATION':
                return t('exportdocumenttype.photopowerpointpresentation')
            case 'PHOTO_WORD_DOCUMENT':
                return t('exportdocumenttype.photowordocument')
            case 'PHOTO_EXCEL_WORKBOOK':
                return t('exportdocumenttype.photoexcelworkbook')
            case 'PHOTO_AXIANS_POWERPOINT_PRESENTATION':
                return 'Axians PowerPoint'
            case 'FORM_POWERPOINT_PRESENTATION':
                return t('exportdocumenttype.formpowerpointpresentation')
            case 'FORM_WORD_DOCUMENT':
                return t('exportdocumenttype.formwordocument')
            case 'FORM_EXCEL_WORKBOOK':
                return t('exportdocumenttype.formexcelworkbook')
            case 'FORM_3GIS_PM':
                return '3GIS PM'
            case 'FORM_SHEAB_WORD_DOCUMENT':
                return 'SHEAB Checklista'
            case 'RAMSITE_POWERPOINT':
                return 'Ramsite PowerPoint'
        }
        return exportDocumentType
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('customer.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('customer.columns.name')}  name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t('customer.columns.products')}
                </Form.Label>
                { products.map(product => {
                    return <Form.Check
                        key={product.id}
                        id={product.id}
                        type="checkbox"
                        label={product.name}
                        value={product.id}
                        checked={data.products.some(p => p.id == product.id)}
                        onChange={handleProductChange}/>
                })}
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t('customer.columns.exportdocumenttypes')}
                </Form.Label>
                { getExportDocumentTypes().map(exportDocumentType => {
                    return <Form.Check
                        key={exportDocumentType}
                        id={exportDocumentType}
                        type="checkbox"
                        label={getExportDocumentTypeOptionLabel(exportDocumentType)}
                        value={exportDocumentType}
                        checked={data.exportDocumentTypes.includes(exportDocumentType)}
                        onChange={handleExportDocumentTypeChange}/>
                })}
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    {t('customer.columns.exportdocumentconfigurations')}
                </Form.Label>
                { exportDocumentConfigurations.map(exportDocumentConfiguration => {
                    return <Form.Check
                        key={exportDocumentConfiguration.id}
                        id={exportDocumentConfiguration.id}
                        type="checkbox"
                        label={exportDocumentConfiguration.name}
                        value={exportDocumentConfiguration.id}
                        checked={data.exportDocumentConfigurations.some(e => e.id === exportDocumentConfiguration.id)}
                        onChange={handleExportDocumentConfigurationChange}
                    />
                })}
            </Form.Group>
        </Form>
    )
}


export default CustomerForm