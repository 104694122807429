import React from 'react';
import CrudTable from '../table/crudtable'
import { graphicService, authService } from '../../_services'
import GraphicForm from './graphicform.component'
import { useTranslation } from "react-i18next";

function Graphic() {

    const {t} = useTranslation('common');

	const initialState = {
		id: '',
		name: '',
		description: '',
		data: null
	}

	const descriptionFormatter = (cell) => {
		return cell || '-'
	}

	const columns = [
		{
			dataField: 'customerName',
			text: t('graphic.columns.customer'),
			sort: true,
			hidden: !authService.hasRoles(['ROLE_SUPER_ADMIN'])
		},
		{
			dataField: 'name',
			text: t('graphic.columns.name'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('graphic.columns.description'),
			formatter: descriptionFormatter,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('graphic.label')}
			</h2>
			<CrudTable
				service={graphicService}
				columns={columns}
				initialState={initialState}
				form={<GraphicForm />}
				remote={{"filter": false, "search": false, "pagination": false}}
				sort={{dataField: 'name', order: 'asc'}}
				creatable={authService.hasRoles(['ROLE_GRAPHIC_WRITE'])}
				editable={authService.hasRoles(['ROLE_GRAPHIC_WRITE'])}
				deletable={authService.hasRoles(['ROLE_GRAPHIC_WRITE'])}
			/>
		</div>
	)
}

export default Graphic;