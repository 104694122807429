import React from 'react';
import Login from './components/login.component'
import ForgotPassword from './components/forgotpassword.component'
import ResetPassword from './components/resetpassword.component'
import DefaultContainer from './components/default.component'
import {
  Route,
  Switch,
  Router
} from 'react-router-dom';
import { history } from './_helpers';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/forgotpassword/" component={ForgotPassword}/>
        <Route exact path="/resetpassword/:token" component={ResetPassword}/>
        <Route component={DefaultContainer} />   
      </Switch>
    </Router> 
  );
}

export default App;