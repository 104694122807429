import React, {useState, useEffect} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { expectedAnswerListService, authService, customerService } from '../../_services'

const ExpectedAnswerListForm = ({data, handleChange, handleSubmit, validated}) => {

	const [expectedAnswerLists, setExpectedAnswerLists] = useState([])
	const [customers, setCustomers] = useState([])
	const [paramKeys, setParamKeys] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        customerService.list().then(data => {
	            data.sort((a, b) => {
	                return a.name.localeCompare(b.name)
	            })
	            setCustomers(data)
	        })
        }
        expectedAnswerListService.list().then(expectedAnswerLists => {
            expectedAnswerLists.sort((a, b) => {
                if (a.name != b.name) {
                    return a.name.localeCompare(b.name)
                }
                return a.customerName.localeCompare(b.customerName)
            })
			setExpectedAnswerLists(expectedAnswerLists)
        })

        var paramKeys = new Set()
        data.expectedAnswers.forEach(expectedAnswer => {
            Object.entries(expectedAnswer.params).forEach(param => {
                paramKeys.add(param[0])
            })
        })
        setParamKeys(Array.from(paramKeys).sort())
    }, [])

    function addExpectedAnswer(e) {
        e.preventDefault();

        var expectedAnswer = {
            id: uuidv4(),
            customerId: data.customerId,
            text: '',
            imported: false
        }

        let newExpectedAnswers = [...data.expectedAnswers, expectedAnswer]

        handleChange({target: {type: "object", name: "expectedAnswers", value: newExpectedAnswers}})
    }

    function removeExpectedAnswer(expectedAnswer, e) {
        e.preventDefault();

        var newExpectedAnswers = data.expectedAnswers.filter(t => t.id !== expectedAnswer.id)

        handleChange({target: {type: "object", name: "expectedAnswers", value: newExpectedAnswers}})
    }

    function handleCustomerChange(e) {
        var customerId = e.target.value
        data.expectedAnswers.forEach(expectedAnswer => expectedAnswer.customerId = customerId)
		handleChange(e)
    }

    function handleExpectedAnswerListChange(e) {
        var expectedAnswerList = expectedAnswerLists.find(expectedAnswerList => expectedAnswerList.id == e.target.value)
        handleChange({target: {type: "text", name: "name", value: expectedAnswerList.name}})
        handleChange({target: {type: "text", name: "description", value: expectedAnswerList.description}})
        var expectedAnswers = []
        expectedAnswerList.expectedAnswers.forEach(expectedAnswer => {
            var expectedAnswerCopy = {
                ...expectedAnswer,
	            id: uuidv4(),
	            customerId: data.customerId,
	            dateCreated: null,
	            lastUpdated: null
	        }
	        expectedAnswers.push(expectedAnswerCopy)
        })
        handleChange({target: {type: "object", name: "expectedAnswers", value: expectedAnswers}})
    }

    function handleExpectedAnswerChanges(expectedAnswer, e) {
        var value = e.target.value
        if (e.target.name == 'text') {
            var invalidChars = ['~', '"', '#', '%', '&', '*', ':', '<', '>', '?', '/', '\\', '{', '|', '}']
	        invalidChars.forEach(invalidChar => value = value.replaceAll(invalidChar, ''))
        }
        expectedAnswer[e.target.name] = value
        handleChange({target: {type: "object", name: "expectedAnswers", value: data.expectedAnswers}})
    }

    function handleParamsChanges(expectedAnswer, e) {
        var name = e.target.name
        var value = e.target.value
        expectedAnswer.params[name] = value
        handleChange({target: {type: "object", name: "expectedAnswers", value: data.expectedAnswers}})
    }

    function handleFileChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                const result = reader.result
                var rows = result.split(/\r?\n/)
                var newExpectedAnswers = [...data.expectedAnswers]
                var existingExpectedAnswers = newExpectedAnswers.map(expectedAnswer => expectedAnswer.text)
                rows.forEach(row => {
                    if (!existingExpectedAnswers.includes(row) && row != '') {
	                    var expectedAnswer = {
				            id: uuidv4(),
				            customerId: data.customerId,
				            text: row
				        }
				        newExpectedAnswers.push(expectedAnswer)
                    }
                })
                handleChange({target: {type: "object", name: "expectedAnswers", value: newExpectedAnswers}})
            });
            reader.readAsText(file)
        }
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            <Row>
	            <Col sm="6">
	                { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
	                    <Form.Group controlId="customer">
		                    <Form.Label>
			                    {t('expectedanswerlist.columns.customer')}
			                </Form.Label>
							<Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleCustomerChange} disabled={data.dateCreated}>
			                    {customers.map(customer => (
			                        <option key={customer.id} value={customer.id}>{customer.name}</option>
			                    ))}
			                </Form.Control>
	                    </Form.Group>
	                }
	                { !data.dateCreated &&
	                    <Form.Group controlId="expectedAnswerList">
		                    <Form.Label>
			                    {t('expectedanswerlist.copyfrom')}
			                </Form.Label>
							<Form.Control required as="select" name="expectedAnswerList" value={-1} onChange={handleExpectedAnswerListChange} disabled={expectedAnswerLists.length == 0}>
								<option value={-1}>{t('expectedanswerlist.chooseexpectedanswerlist')}</option>
			                    { expectedAnswerLists.map(expectedAnswerList => (
			                        <option key={expectedAnswerList.id} value={expectedAnswerList.id}>
			                            {expectedAnswerList.name}
			                            { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
			                                ' (' + expectedAnswerList.customerName + ')'
			                            }
			                        </option>
			                    ))}
			                </Form.Control>
	                    </Form.Group>
	                }
		            <Form.Group controlId="name">
		                <Form.Label>
		                    {t('expectedanswerlist.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('expectedanswerlist.columns.name')} name="name" value={data.name} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="description">
		                <Form.Label>
		                    {t('expectedanswerlist.columns.description')}
		                </Form.Label>
		                <TextareaAutosize className="form-control" placeholder={t('expectedanswerlist.columns.description')} name="description" value={data.description || ''} onChange={handleChange}/>
		            </Form.Group>
		        </Col>
		    </Row>
		    <Row>
		        <Col sm="12">
		            <Form.Group controlId="expectedAnswers">
		                <Form.Label>
		                    {t('expectedanswerlist.columns.expectedanswers')}
		                </Form.Label>
						<table className="table table-bordered table-sm">
							<thead>
			                    <tr>
			                        <th>
			                            {t('expectedanswer.columns.answer')} *
			                        </th>
			                        { paramKeys.map(key => {
			                            return <th key={'th-' + key}>
				                            {key}
				                        </th>
			                        })}
			                        <th style={{width: 20}}>
			                            {t('expectedanswer.columns.imported')}
			                        </th>
			                        <th style={{width: 20}}></th>
			                    </tr>
			                </thead>
							<tbody>
								{ data.expectedAnswers.map((expectedAnswer, index) => {
									return <tr key={expectedAnswer.id}>
										<td>
											<Form.Control required type="text" placeholder={t('expectedanswer.columns.answer')} name="text" value={expectedAnswer.answer} onChange={e => handleExpectedAnswerChanges(expectedAnswer, e)}/>
										</td>
										{ paramKeys.map(key => {
				                            return <td key={'td-' + key + '-' + expectedAnswer.id}>
					                            <Form.Control required type="text" placeholder={key} name={key} value={expectedAnswer.params[key]} onChange={e => handleParamsChanges(expectedAnswer, e)}/>
					                        </td>
				                        })}
										<td>
											{expectedAnswer.imported ? t('crudtable.yes') : t('crudtable.no')}
										</td>
										<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
											<Button
									            onClick={(e) => removeExpectedAnswer(expectedAnswer, e)}
									            title={t("crudtable.add")}
									            size="sm"
									            color="info"
									            variant="outline-danger">
									            <FontAwesomeIcon icon={faTrash}/>
									        </Button>
										</td>
									</tr>
								})}
							</tbody>
						</table>
						<Row>
							<Col md="6">
				                <Button
				                    onClick={addExpectedAnswer}
				                    title={t('expectedanswerlist.addexpectedanswer')}
				                    color="info"
				                    variant="outline-primary">
				                    <FontAwesomeIcon icon={faPlus}/> {t('expectedanswerlist.addexpectedanswer')}
				                </Button>
			                </Col>
			                <Col md="6">
			                    <Form.Control className="float-right" style={{width: 'auto'}} type="file" accept=".txt,.csv" name="text" onChange={handleFileChange} />
			                </Col>
		                </Row>
		            </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default ExpectedAnswerListForm