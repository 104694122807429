import React, {useState, useEffect} from 'react'
import { Form, Button, InputGroup, Dropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { authService, customerService, exportConfigurationService } from '../../_services'
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const ExportConfigurationForm = ({data, handleChange, handleSubmit, validated}) => {

	const [customers, setCustomers] = useState([])
	const [exportConfigurations, setExportConfigurations] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (customers.length === 0) {
            customerService.getOptions().then(customers => {
                customers.sort((a, b) => a.name.localeCompare(b.name))
	            setCustomers(customers)
	            if (data.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
        if (exportConfigurations.length === 0) {
	        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
		        exportConfigurationService.list().then(data => {
		            data.sort((a, b) => a.name.localeCompare(b.name))
		            setExportConfigurations(data)
		        })
	        }
        }
    })

    function handleCustomerChange(e) {
        let value = e.target.value

        if (data.userParams) {
            data.userParams.forEach(userParam => {
                userParam.customerId = value
            })
        }

        handleChange(e)
    }

    function handleTypeChange(e) {
        e.preventDefault()

		var newParams = {}
        if (e.target.value === 'SHAREPOINT') {
            newParams.clientId = ''
            newParams.tenantId = ''
			newParams.siteUrl = ''
			newParams.scope = ''
			newParams.userColumn = ''
			newParams.commentColumn = ''
			newParams.path = 'Shared Documents'
        } else if (e.target.value === 'FTP') {
			newParams.host = ''
            newParams.username = ''
			newParams.password = ''
			newParams.directory = ''
        } else if (e.target.value === 'WEBDAV') {
			newParams.url = ''
            newParams.username = ''
			newParams.password = ''
        } else if (e.target.value == 'STOREGATE') {
			newParams.url = ''
            newParams.username = ''
			newParams.password = ''
        } else if (e.target.value == 'RAMSITE_3GIS') {
			newParams.apiKey = ''
			newParams.documentStructureId = '4657'
        } else if (e.target.value == 'EMAIL') {
			newParams.staticEmail = ''
			newParams.emailKey = ''
			newParams.body = ''
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
        handleChange({target: {type: "select", name: "type", value: e.target.value}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...data.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handleExportConfigurationChange(e) {
        var value = e.target.value
        if (value != -1) {
            var exportConfiguration = exportConfigurations.find(exportconfiguration => exportconfiguration.id == e.target.value)
	        handleChange({target: {type: "text", name: "name", value: exportConfiguration.name}})
	        handleChange({target: {type: "text", name: "type", value: exportConfiguration.type}})
	        handleChange({target: {type: "object", name: "params", value: {...exportConfiguration.params}}})
	        if (exportConfiguration.userParams) {
	            var userParams = []
	            exportConfiguration.userParams.forEach(userParam => {
	                userParams.push({
	                    id: uuidv4(),
	                    customerId: data.customerId,
	                    name: userParam.name,
                        required: userParam.required
	                })
	            })
	            handleChange({target: {type: "object", name: "userParams", value: userParams}})
	        }
        }
    }

    function handleUserParamChange(e, userParam) {
        let name = e.target.name
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        userParam[name] = value

        let newUserParams = [...data.userParams]

        handleChange({target: {type: "object", name: "userParams", value: newUserParams}})
    }

    function addUserParam() {
        let userParam = {
            id: uuidv4(),
            customerId: data.customerId,
            name: '',
            required: false
        }
		let newUserParams = [...data.userParams, userParam]
		handleChange({target: {type: "object", name: "userParams", value: newUserParams}})
    }

    function removeUserParam(e, userParam) {
        e.preventDefault()

        var newUserParams = data.userParams.filter(otherUserParam => otherUserParam != userParam)
        handleChange({target: {type: "object", name: "userParams", value: newUserParams}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            { !data.dateCreated &&
                <Form.Group controlId="exportConfiguration">
                    <Form.Label>
	                    {t('exportconfiguration.copyfrom')}
	                </Form.Label>
					<Form.Control required as="select" name="template" value={-1} onChange={handleExportConfigurationChange}>
						<option value={-1}>{t('exportconfiguration.chooseexportconfiguration')}</option>
	                    {exportConfigurations.map(exportConfiguration => (
	                        <option key={exportConfiguration.id} value={exportConfiguration.id}>
	                            {exportConfiguration.name} ({exportConfiguration.customerName})
	                        </option>
	                    ))}
	                </Form.Control>
                </Form.Group>
            }
            <Form.Group controlId="customerId">
                <Form.Label>
                    {t('exportconfiguration.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleCustomerChange}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('exportconfiguration.columns.name')} *
                </Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder={t('template.columns.name')}
                    name="name"
                    value={data.name}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('exportconfiguration.columns.type')}
                </Form.Label>
                <Form.Control
                    required as="select"
                    placeholder={t('exportconfiguration.columns.type')}
                    name="type"
                    value={data.type}
                    onChange={handleTypeChange}
                >
                    <option>SHAREPOINT</option>
                    <option>FTP</option>
                    <option>WEBDAV</option>
                    <option>STOREGATE</option>
                    <option>RAMSITE_3GIS</option>
                    <option>EMAIL</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="params">
                <Form.Label>
                    {t('exportconfiguration.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(data.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th>
	                                {t('exportconfiguration.params.key')}
	                            </th>
	                            <th>
	                                {t('exportconfiguration.params.value')}
	                            </th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(data.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									{key}
								</td>
								<td>
									<Form.Control
										type="text"
										placeholder={t('exportconfiguration.params.value')}
										name="value"
										value={value}
										onChange={(e) => handleValueChange(e, key)}
									/>
								</td>
							</tr>
		                })}
	                </tbody>
                </table>
            </Form.Group>
            <Form.Group controlId="userParams">
                <Form.Label>
                    {t('exportconfiguration.columns.userparams')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th>
                                {t('exportconfiguration.userparams.name')}
                            </th>
                            <th>
                                {t('exportconfiguration.userparams.required')}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
		                { data.userParams.map(userParam => {
							return <tr key={userParam.id}>
								<td>
									<Form.Control
										type="text"
										placeholder={t('exportconfiguration.userparams.name')}
										name="name"
										value={userParam.name}
										onChange={(e) => handleUserParamChange(e, userParam)}
									/>
								</td>
								<td>
									<Form.Group controlId="required">
						                <Form.Check type="checkbox" label={t('crudtable.yes')} name="required" checked={userParam.required} onChange={(e) => handleUserParamChange(e, userParam)} />
									</Form.Group>
								</td>
								<td>
									<Button variant="outline-danger" onClick={e => removeUserParam(e, userParam)}>
										<FontAwesomeIcon icon={faTrash} style={{color: 'red'}}/>
									</Button>
								</td>
							</tr>
		                })}
	                </tbody>
                </table>
                <Button variant="outline-primary" onClick={() => addUserParam()}>
					{t('exportconfiguration.adduserparam')}
				</Button>
            </Form.Group>
        </Form>
    )
}

export default ExportConfigurationForm