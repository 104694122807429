import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollUpButton() {

	const [show, setShow] = useState(false);

	useEffect(() => {
			window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [])

	function handleScroll(e) {
		setShow(window.scrollY > (window.innerHeight / 2))
	}

    return (
        <>
	        { show &&
	            <Button style={{position: 'fixed', bottom: '15px', left: '15px'}} onClick={e => window.scrollTo({top: 0, behavior: 'smooth'})}>
		            <FontAwesomeIcon icon={faArrowUp}/>
		        </Button>
	        }
        </>
    )
}

export default ScrollUpButton;