import React, { useEffect, useState } from 'react';
import CrudTable from '../table/crudtable'
import { Tabs, Tab } from 'react-bootstrap'
import { tempFileService, tempFileTokenService, customerService, licenseService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';

function TempFile() {

	const [tempFiles, setTempFiles] = useState([])
	const [customers, setCustomers] = useState([])
	const [licenses, setLicenses] = useState([])
	const [downloaded, setDownloaded] = useState(false)
    const {t} = useTranslation('common')

    useEffect(() => {
        var promises = [customerService.getOptions(), licenseService.simpleList()]
        Promise.all(promises).then((values) => {
            setCustomers(values[0])
            setLicenses(values[1])
            setDownloaded(true)
        })
    }, [])

    const formatName = function(cell, row) {
        var license = licenses.find(license => license.id == row.licenseId)
        if (license) {
			return <a href={SERVER_URL + '/tempFile/getTempFile/' + row.id + '?licenseCode=' + license.licenseCode} target="_blank">
				{cell}
			</a>
		}
		return cell
	}

	const formatCustomer = function(cell) {
        var customer = customers.find(customer => customer.id == cell)
        if (customer) {
            return customer.name
        }
		return cell
	}

	const formatLicense = function(cell) {
        var license = licenses.find(license => license.id == cell)
        if (license) {
            if (license.name) {
                return license.name + ' (' + license.licenseCode + ')'
            }
            return license.licenseCode
        }
		return cell
	}

	const formatDataSize = function(cell, row) {
	    if (cell) {
			var i = Math.floor( Math.log(cell) / Math.log(1000) );
	        return ( cell / Math.pow(1000, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
	    }
	    return 0
    }

    const formatType = function(cell) {
	    if (cell) {
	        return cell
	    }
	    return '-'
    }

    const formatCreator = function(cell) {
	    if (cell) {
	        return cell
	    }
	    return '-'
    }

    const formatRemoteAddress = function(cell) {
	    if (cell) {
	        return cell
	    }
	    return '-'
    }

	const formatDate = function(cell) {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const columns = [
		{
		    dataField: 'name',
		    text: 'Namn',
            formatter: formatName,
            filterValue: formatName,
			sort: true
		},
		{
		    dataField: 'customerId',
		    text: 'Kund',
		    formatter: formatCustomer,
			sort: true
		},
		{
		    dataField: 'documentationId',
		    text: 'Dokumentation',
			sort: true
		},
		{
		    dataField: 'licenseId',
		    text: 'Licens',
		    formatter: formatLicense,
			sort: true
		},
		{
		    dataField: 'dataSize',
		    text: 'Storlek',
		    formatter: formatDataSize,
			sort: true
		},
		{
		    dataField: 'type',
		    text: 'Typ',
		    formatter: formatType,
			sort: true
		},
		{
		    dataField: 'tokenCreator',
		    text: 'Token-skapare',
		    formatter: formatCreator,
			sort: true
		},
		{
		    dataField: 'remoteAddr',
		    text: 'Adress',
		    formatter: formatRemoteAddress,
			sort: true
		},
		{
		    dataField: 'dateCreated',
		    text: 'Skapades',
            formatter: formatDate,
            filterValue: formatDate,
			sort: true
		}
	]

	const tokenColumns = [
		{
		    dataField: 'id',
		    text: 'Id',
			sort: true
		},
		{
		    dataField: 'documentationId',
		    text: 'Dokumentation',
			sort: true
		},
		{
		    dataField: 'licenseId',
		    text: 'Licens',
		    formatter: formatLicense,
			sort: true
		},
		{
		    dataField: 'creator',
		    text: 'Skapare',
		    formatter: formatCreator,
			sort: true
		},
		{
		    dataField: 'expireDate',
		    text: 'Slutdatum',
            formatter: formatDate,
            filterValue: formatDate,
			sort: true
		}
	]

	return (
		<div>
			<h2>
				{t('tempfile.label', 'Tempfiler')}
			</h2>
			<Tabs>
				<Tab eventKey="photos" title={t('tempfile.files', 'Filer')} style={{paddingTop: '10px'}}>
					{ downloaded &&
						<CrudTable
							service={tempFileService}
							columns={columns}
							remote={{"filter": false, "search": false, "pagination": false}}
							creatable={false}
							editable={false}
							deletable={true}
							sort={{dataField: 'dateCreated', order: 'desc'}}
						/>
					}
				</Tab>
				<Tab eventKey="tokens" title={t('tempfile.tokens', 'Tokens')} style={{paddingTop: '10px'}}>
					{ downloaded &&
						<CrudTable
							service={tempFileTokenService}
							columns={tokenColumns}
							remote={{"filter": false, "search": false, "pagination": false}}
							creatable={false}
							editable={false}
							deletable={true}
							sort={{dataField: 'expireDate', order: 'desc'}}
						/>
					}
				</Tab>
			</Tabs>
		</div>
	)
}

export default TempFile;