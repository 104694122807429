import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap'
import { quartzService } from '../../_services'
import moment from 'moment'

function Quartz() {

	const [triggers, setTriggers] = useState([]);

    useEffect(() => {
		quartzService.list().then(data => {
			setTriggers(data)
        })
    }, [])

    const runNow = (trigger) => {
        console.log(trigger)
        quartzService.runNow(trigger.jobGroup, trigger.jobName).then(data => {
			console.log(data)
        })
    }

	return (
		<Container>
			<table className="table">
				<thead>
					<tr>
						<th>Namn</th>
						<th>Föregående schemalagda körning</th>
						<th>Nästa schemalagda körning</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ triggers.map(trigger => {
						return <tr key={trigger.name}>
							<td>
								{trigger.name}
							</td>
							<td>
								{trigger.previousFireTime ? moment(trigger.previousFireTime).format('yyyy-MM-DD HH:mm:ss') : '-'}
							</td>
							<td>
								{trigger.nextFireTime ? moment(trigger.nextFireTime).format('yyyy-MM-DD HH:mm:ss') : '-'}
							</td>
							<td>
								<Button onClick={e => runNow(trigger)}>Kör nu</Button>
							</td>
						</tr>
					})}
				</tbody>
			</table>
        </Container>
    )
}

export default Quartz;