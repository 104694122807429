import React, { useEffect, useState } from 'react';
import { Container, Button, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { userService, authService } from '../../_services'
import { history } from '../../_helpers';
import Select from 'react-select'

function SwitchUser() {

	const [users, setUsers] = useState([]);
	const [userId, setUserId] = useState(null);
    const {t} = useTranslation('common');

    useEffect(() => {
		userService.list().then(data => {
			data.sort((a, b) => a.username.localeCompare(b.username))
			data = data.filter(user => user.id !== 1)
            setUsers(data)
//            if (data.length > 0) {
//                setUserId(data[0].id)
//            }
        })
    }, [])

    const onClick = () => {
        if (userId) {
			authService.switchUser(userId).then(response => {
				history.push("/")
			})
        }
    }

    const handleChange = (user) => {
        console.log(user)
        setUserId(user.id)
        //setUserId(event.target.value)
    }

    const getOptionLabel = (user) => {
        return user.username + " (" + user.customerName + ")"
    }

	return (
		<Container>
			<Row>
				<Col md={{ span: 4, offset: 0 }}>
		            <Select placeholder={"Välj användare"} isLoading={users.length === 0} options={users} getOptionLabel={getOptionLabel} getOptionValue={user => user.id} onChange={handleChange}/>
	            </Col>
            </Row>
            <Row style={{paddingTop: '10px'}}>
				<Col md={{ span: 4, offset: 0 }}>
					<Button onClick={onClick}>{t('switchuser.switchuser')}</Button>
				</Col>
			</Row>
        </Container>
    )
}

export default SwitchUser;