import api from './api.service'

export const photoService = {
    list,
    getAllDocumentationPhotos,
    findAllByDocumentationName,
    findAllByCustomerId,
    get,
    save,
    saveWithLicenseCode,
    upload,
    update,
    importPhoto,
    _delete
};

const BASE_URL = '/photos'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000, sort: 'dateCreated', order: 'asc'})
}

function getAllDocumentationPhotos(documentationId, max) {
    return api.get(BASE_URL + '?documentation=' + documentationId, {max : max || 1000, sort: 'name', order: 'asc'})
}

function findAllByDocumentationName(documentationName, max) {
	return api.get('/photo/findAllByDocumentationName/' + documentationName, {max : max || 1000, sort: 'name', order: 'asc'})
}

function findAllByCustomerId(customerId) {
	return api.get('/photo/findAllByCustomerId/' + customerId)
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(photo) {
    return api.post(BASE_URL, photo)
}

function saveWithLicenseCode(photo, licenseCode) {
    return api.post('/photo/saveWithLicenseCode?licenseCode=' + licenseCode, photo)
}

function upload(photoUploadObject, photoId, licenseCode) {
    return api.post('/photo/upload/' + photoId + '?licenseCode=' + licenseCode + '&compressPhoto=true', photoUploadObject)
}

function update(id, photo) {
    return api.put(BASE_URL + '/' + id, photo)
}

function importPhoto(importRequest) {
    return api.put('/photo/importPhoto/', importRequest)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
