import api from './api.service'

export const graphicService = {
    list,
    getAll,
    save,
    update,
    _delete,
    copy
};

const BASE_URL = '/graphics'

function list(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function getAll() {
    return api.get('/graphic/getAll')
}

function save(graphic) {
    return api.post(BASE_URL, graphic)
}

function update(id, graphic) {
    return api.put(BASE_URL + "/" + id, graphic)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function copy(graphic, customer, name) {
    return api.post("/graphic/copy?graphic=" + graphic + "&customer=" + customer + "&name=" + name, graphic)
}
