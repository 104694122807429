import React, {useState, useEffect} from 'react';
import { templateService, customerService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import Select from 'react-select'

function CopyTemplate() {

	const [templates, setTemplates] = useState([])
	const [selectedTemplates, setSelectedTemplates] = useState([])
	const [customers, setCustomers] = useState([])
	const [state, setState] = useState({'name': ''})
	const [showTemplatesEmptyError, setShowTemplatesEmptyError] = useState(false)
	const [showNameEmptyError, setShowNameEmptyError] = useState(false)
	const [showNameNotUniqueError, setShowNameNotUniqueError] = useState(false)
	const dispatch = useDispatch();
	const {t} = useTranslation('common')

	useEffect(() => {
		templateService.getAll().then(templates => {
			templates.sort((a, b) => a.name.localeCompare(b.name))
			setTemplates(templates)
	    })
	    customerService.getOptions().then(customers => {
		    customers.sort((a, b) => a.name.localeCompare(b.name))
			setCustomers(customers)
			if (!state.customer && customers.length > 0) {
                handleChange({target: {type: "select", name: "customer", value: customers[0].id}})
            }
	    })
	}, [])

	const getCustomer = (customerId) => {
		var customer = customers.find(customer => customer.id == customerId)
	    if (customer) {
	        return customer.name
	    }
	    return '-'
	}

	const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.value
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
	    }))
	}

	const handleTemplateChange = (templates) => {
		if (templates) {
			setSelectedTemplates(templates)
			let templateIds = templates.map(template => template.id)
			handleChange({target: {type: "object", name: "templates", value: templateIds}})
			setShowTemplatesEmptyError(false)
		} else {
			setSelectedTemplates([])
			handleChange({target: {type: "object", name: "templates", value: []}})
		}
	}

	const submit = function(event) {
		console.log('submit')

		event.preventDefault()
        event.stopPropagation()

        console.log(state.templates)
        console.log(state.customer)
        console.log(state.name)

		setShowTemplatesEmptyError(false)
        if (!state.templates || state.templates.length == 0) {
            setShowTemplatesEmptyError(true)
            return
        }

		setShowNameNotUniqueError(false)
        var existingTemplate = templates.find(template => template.name == state.name && template.customerId == state.customer)
        if (existingTemplate) {
            setShowNameNotUniqueError(true)
            return
        }

        templateService.copy(state).then(data =>  {
			console.log(data)
			dispatch(alertActions.success(t('alerts.createsuccessful')))

			templateService.getAll().then(templates => {
				templates.sort((a, b) => a.name.localeCompare(b.name))
				setTemplates(templates)
		    })
		})
	}

	const getOptionLabel = (template) => {
        return template.name + " (" + getCustomer(template.customerId) + ")"
    }

	return (
		<Container>
			<h2>
				{t('copytemplate.label')}
			</h2>
			{ templates && customers &&
				<Row>
					<Col md={{ span: 6, offset: 0 }}>
						<Form id="data-form" onSubmit={submit}>
				            <Form.Group controlId="template">
				                <Form.Label>
				                    {t('copytemplate.columns.template')} *
				                </Form.Label>
				                <Select
				                    placeholder={t('copytemplate.columns.template')}
				                    isLoading={templates.length === 0}
				                    options={templates}
				                    value={selectedTemplates}
				                    getOptionLabel={getOptionLabel}
				                    getOptionValue={template => template.id}
				                    isMulti={true}
				                    closeMenuOnSelect={false}
				                    onChange={handleTemplateChange}
				                    required/>
				                { showTemplatesEmptyError &&
				                    <Alert variant="danger" style={{marginTop: '5px'}}>
				                        Du måste välja minst en mall
				                    </Alert>
				                }
				            </Form.Group>
				            <Form.Group controlId="customer">
				                <Form.Label>
				                    {t('copytemplate.columns.customer')} *
				                </Form.Label>
				                <Form.Control required as="select" placeholder={t('copytemplate.columns.customer')} name="customer" onChange={handleChange}>
				                    { customers.map(customer => (
				                        <option key={customer.id} value={customer.id}>{customer.name}</option>
				                    ))}
				                </Form.Control>
				            </Form.Group>
				            <Form.Group controlId="name">
				                <Form.Label>
				                    {t('copytemplate.columns.name')}
				                </Form.Label>
				                <Form.Control type="text" placeholder={t('copytemplate.columns.name')} name="name" value={state.name} onChange={handleChange} />
				                { showNameNotUniqueError &&
				                    <Alert variant="danger" style={{marginTop: '5px'}}>
				                        Det finns redan en mall med det namnet för den kunden
				                    </Alert>
				                }
				            </Form.Group>
						</Form>
						<Button form='data-form' type="submit" variant="primary">
							{t('copytemplate.copy')}
						</Button>
					</Col>
				</Row>
			}
		</Container>
	)
}

export default CopyTemplate;