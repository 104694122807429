import api from './api.service'

export const suggestionImportConfigurationService = {
    list,
    get,
    save,
    update,
    _delete,
    triggerNow
};

const BASE_URL = '/suggestionImportConfigurations'

function list(max) {
    return api.get(BASE_URL, {max: max || 100, sort: 'id', order: 'asc'})
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(suggestionImportConfiguration) {
    return api.post(BASE_URL, suggestionImportConfiguration)
}

function update(id, suggestionImportConfiguration) {
    return api.put(BASE_URL + "/" + id, suggestionImportConfiguration)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function triggerNow(id) {
    return api.get('/suggestionImportConfiguration/triggerNow/' + id)
}
