import React, {useState, useEffect} from 'react';
import { licenseService, templateService, documentationService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';

function Demo() {

	const columns = [
	    {
			dataField: 'name',
			text: 'Namn',
			sort: true
	    },
	    {
			dataField: 'licenseCode',
			text: 'Licenskod',
			formatter: col => {
				return col.includes('#') ? col.substring(0, Math.min(col.indexOf('#') + 10, col.length)) + '...' : col
			},
			sort: true,
			title: col => col
	    },
	    {
			dataField: 'dateCreated',
			text: 'Skapades',
			formatter: (col, row) => col ? moment(col).format('yyyy-MM-DD HH:mm') : '-',
			sort: true
	    },
	    {
			dataField: 'templates',
			text: 'Mallar',
			formatter: (col, row) => {
				if (row && row.templates && row.templates.length > 0) {
					return <a href="#" onClick={e => showTemplates(row)}>{row.templates.length}</a>
				}
				return 0
			},
			sort: true
	    },
	    {
			dataField: 'documentations',
			text: 'Dokumentationer',
			formatter: (col, row) => {
				if (row && row.documentations && row.documentations.length > 0) {
					return <a href="#" onClick={e => showDocumentations(row)}>{row.documentations.length}</a>
				}
				return 0
			},
			sort: true
	    },
	    {
			dataField: 'email',
			text: 'Email',
			formatter: (col, row) => row.params && row.params['email'] ? row.params['email'] : '-',
			sort: true,
			sortValue: (col, row) => row.params && row.params['email'] ? row.params['email'] : '-',
			filterValue: (col, row) => row.params && row.params['email'] ? row.params['email'] : '-',
	    },
	    {
			dataField: 'company',
			text: 'Företag',
			formatter: (col, row) => row.params && row.params['company'] ? row.params['company'] : '-',
			sort: true,
			sortValue: (col, row) => row.params && row.params['company'] ? row.params['company'] : '-',
			filterValue: (col, row) => row.params && row.params['company'] ? row.params['company'] : '-'
	    },
	    {
			dataField: 'verified',
			text: 'Verifierad',
			formatter: (col, row) => row.params && row.params['verified'] ? 'Ja' : 'Nej',
			sort: true,
			sortValue: (col, row) => row.params && row.params['verified'] ? 'Ja' : 'Nej'
	    },
	    {
			dataField: 'verificationCode',
			text: 'Kod',
			formatter: (col, row) => row.params && row.params['verificationCode'] ? row.params['verificationCode'] : '-',
			sort: true,
			sortValue: (col, row) => row.params && row.params['verificationCode'] ? row.params['verificationCode'] : '-',
	    },
	    {
			dataField: 'expireDate',
			text: 'Utgångsdatum',
			formatter: (col, row) => col ? moment(col).format('yyyy-MM-DD') : '-',
			sort: true
	    },
	    {
			dataField: 'id',
			text: '',
			formatter: col => {
				return <Button title="Ta bort" variant="outline-danger" size="sm" onClick={e => deleteLicense(col)}>
					<FontAwesomeIcon icon={faTrash}/>
				</Button>
			}
	    }
	]

	const [licenses, setLicenses] = useState([])
	const [templates, setTemplates] = useState([])
	const [groups, setGroups] = useState([])
	const [columnState, setColumnState] = useState(columns)
	const [selectedGroups, setSelectedGroups] = useState([])
	const [selectedLicense, setSelectedLicense] = useState(null)
	const [showDeleteLicenseModal, setShowDeleteLicenseModal] = useState(false)
	const [showTemplatesModal, setShowTemplatesModal] = useState(false)
	const [showDocumentationsModal, setShowDocumentationsModal] = useState(false)
	const [showExpiredLicenses, setShowExpiredLicenses] = useState(true)
	const [loading, setLoading] = useState(false);
	const [backdrop, setBackdrop] = useState(false)
	const dispatch = useDispatch();
	const {t} = useTranslation('common')

	useEffect(() => {
		licenseService.list().then(licenses => {
			var demoLicense = licenses.find(license => license.demo)
			if (demoLicense) {
				var filteredLicenses = licenses.filter(license => license.customerId === demoLicense.customerId && !license.demo)
				filteredLicenses.sort((a, b) => a.dateCreated > b.dateCreated)
				filteredLicenses.sort((a, b) => a.name.localeCompare(b.name))

				var groups = new Set()
				filteredLicenses.forEach(license => {
					groups.add(license.name)
				})

				setGroups([...groups])
				setSelectedGroups([...groups])

				var templatePromise = templateService.findAllByCustomerId(demoLicense.customerId)
				var documentationPromise = documentationService.findAllByCustomerId(demoLicense.customerId)

				Promise.all([templatePromise, documentationPromise]).then(values => {
					var templates = values[0]
					setTemplates(templates)
					filteredLicenses.forEach(license => {
						license.templates = templates.filter(template => template.templateLicenses.find(templateLicense => templateLicense.license === license.id))
					})

					var documentations = values[1]
					documentations.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated))
					filteredLicenses.forEach(license => {
						license.documentations = documentations.filter(documentation => documentation.licenseId === license.id)
					})

					setLicenses(filteredLicenses)
				})

//				templateService.findAllByCustomerId(demoLicense.customerId).then(templates => {
//	                setTemplates(templates)
//
//					filteredLicenses.forEach(license => {
//						license.templates = templates.filter(template => template.templateLicenses.find(templateLicense => templateLicense.license === license.id))
//					})
//
//					setLicenses(filteredLicenses)
//	            })
//
//	            documentationService.findAllByCustomerId(demoLicense.customerId).then(documentations => {
//	                filteredLicenses.forEach(license => {
//						license.documentations = documentations.filter(documentation => documentation.licenseId === license.id)
//					})
//	            })
			}
	    })
	}, [])

	const toggleGroup = group => {
		console.log('toggleGroup ' + group)
		var newSelectedGroups = [...selectedGroups]
		if (selectedGroups.includes(group)) {
			newSelectedGroups = newSelectedGroups.filter(g => g != group)
		} else {
			newSelectedGroups.push(group)
		}
		setSelectedGroups(newSelectedGroups)
	}

	const deleteLicense = license => {
		console.log('deleteLicense ' + license)
		setSelectedLicense(license)
		setShowDeleteLicenseModal(true)
	}

	const confirmDeleteLicense = () => {
		console.log('confirmLicense ' + selectedLicense)
		licenseService.deleteLicenseAndTemplates(selectedLicense.id).then(result => {
			console.log(result)
			window.location.reload()
		})
	}

	const onHide = () => {
		setShowDeleteLicenseModal(false)
		setShowTemplatesModal(false)
		setShowDocumentationsModal(false)
		setBackdrop(false)
    }

    const formatLicenseCode = (col) => {
        console.log('formatLicenseCode')
        if (col.includes('#')) {
            var index = col.indexOf('#')
            return col.substring(0, Math.min(index + 10, col.length)) + '...'
        }
		return col
    }

    const filterLicenses = () => {
        var filteredLicenses = licenses.filter(license => selectedGroups.includes(license.name))
        if (!showExpiredLicenses) {
            filteredLicenses = filteredLicenses.filter(license => moment(license.expireDate).isAfter())
        }
		return filteredLicenses
    }

    const { SearchBar } = Search

    const showTemplates = license => {
		console.log('showTemplates ' + license)
		setSelectedLicense(license)
		setShowTemplatesModal(true)
	}

	const showDocumentations = license => {
		console.log('showDocumentations ' + license)
		setSelectedLicense(license)
		setShowDocumentationsModal(true)
	}

    const NoDataIndication = () => (
	    <div>
	        {loading &&
			    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'50px', marginBottom:'50px'}}>
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
			    </div>
		    }
		    {!loading &&
		        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'10px', marginBottom:'10px'}}>
	                <span>Ingen data hittades</span>
	            </div>
		    }
	    </div>
    )

    const pagination = paginationFactory({
		sizePerPage: 25,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		onPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		onSizePerPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		paginationTotalRenderer: (from, to, size) => {
			return ' ' + t('crudtable.total', {from: from, to: to, size: size})
		},
		nextPageTitle: t('crudtable.next'), // the title of next page button
		prePageTitle: t('crudtable.previous'), // the title of previous page button
		firstPageTitle: t('crudtable.first'), // the title of first page button
		lastPageTitle: t('crudtable.last')
    })

	return (
		<div>
			<h2>
				{t('demo.label', 'DEMO')}
			</h2>
			{ licenses && groups && templates.length > 0 &&
				<>
					<Row>
						<Col>
							<ul style={{paddingLeft: 0, listStyleType: 'none'}}>
								{groups.map((group, index) => {
									return <li key={index}>
										<label>
											<input type="checkbox" checked={selectedGroups.includes(group)} onChange={e => toggleGroup(group)}/>
											&nbsp;
											{group}
										</label>
									</li>
								})}
							</ul>
						</Col>
						<Col>
							<label>
								<input type="checkbox" checked={showExpiredLicenses} onChange={e => setShowExpiredLicenses(!showExpiredLicenses)}/>
								&nbsp;
								Visa utgångna licenser
							</label>
						</Col>
					</Row>
					<ToolkitProvider
						bootstrap4
						keyField='id'
						data={filterLicenses()}
						columns={columnState}
						search
					>
		                { toolkitprops => (
		                    <div>
		                        <SearchBar {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
		                        <BootstrapTable
									remote={{filter: false, search: false, pagination: false}}
									noDataIndication={ () => <NoDataIndication /> }
									condensed={true}
									pagination={pagination}
									{...toolkitprops.baseProps}
									onTableChange={() => console.log("onTableChange")}
									filter={filterFactory()}
									sort={{dataField: 'dateCreated', order: 'desc'}}
		                        />
		                    </div>
		                )}
			        </ToolkitProvider>
				</>
			}
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showDeleteLicenseModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Ta bort
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Är du säker på att du vill ta bort denna licens och dess mallar?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={confirmDeleteLicense}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={showTemplatesModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Mallar
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ selectedLicense && selectedLicense.templates &&
						<ul>
							{selectedLicense.templates.map(template => {
								return <li>
									<a href={"/template/" + template.id} target="_blank">{template.name}</a>
								</li>
							})}
						</ul>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				style={{overflow: 'scroll'}}
				size="xl"
				show={showDocumentationsModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Dokumentationer
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ selectedLicense && selectedLicense.documentations &&
						<table className="table table-bordered">
							<thead>
								<tr>
									<th>
										Namn
									</th>
									<th>
										Uppladdad
									</th>
									<th>
										Skapad
									</th>
									<th>
										Status
									</th>
								</tr>
							</thead>
							<tbody>
								{selectedLicense.documentations.map(documentation => {
									return <tr>
										<td>
											<a href={"/documentation/" + documentation.id} target="_blank">{documentation.name}</a>
										</td>
										<td>
											{documentation.dateUploaded ? moment(documentation.dateUploaded).format('yyyy-MM-DD HH:mm') : '-'}
										</td>
										<td>
											{documentation.dateCreated ? moment(documentation.dateCreated).format('yyyy-MM-DD HH:mm') : '-'}
										</td>
										<td>
											{documentation.status}
										</td>
									</tr>
								})}
							</tbody>
						</table>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}

export default Demo;