import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Row, Col, Tabs, Tab, Card, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faChevronUp, faChevronDown, faCamera, faPaperclip, faPaintbrush, faFont, faHashtag, faSquareCheck,
	faListCheck, faFolder, faGear } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { authService, documentationService, templateService, suggestionListService, licenseService, photoService,
	fileService } from '../../../_services'
import Select from 'react-select'
import { history } from '../../../_helpers';
import { alertActions } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import ScrollUpButton from '../../scrollupbutton.component';
import moment from 'moment'

function DocumentationForm() {

	const [templates, setTemplates] = useState([])
	const [selectedTemplate, setSelectedTemplate] = useState(null)
	const [suggestionLists, setSuggestionLists] = useState([])
	const [licenses, setLicenses] = useState([])
	const [selectedLicense, setSelectedLicense] = useState(null)
	const [documentation, setDocumentation] = useState(null)
	const [name, setName] = useState('')
	const [photos, setPhotos] = useState([])
	const [files, setFiles] = useState([])
	const [validated, setValidated] = useState(false)
	const [creating, setCreating] = useState(false)
	const [pausing, setPausing] = useState(false)
	const {t} = useTranslation('common')
    const dispatch = useDispatch()
    const alert = useSelector(state => state.alert);

    useEffect(() => {
        licenseService.list(1000).then(data => {
            setLicenses(data)
            if (data.length == 1) {
                setSelectedLicense(data[0])
            }
        })
		templateService.detailedList(10000).then(data => {
            setTemplates(data)
        })
        suggestionListService.list(1000).then(data => {
            setSuggestionLists(data)
        })
    }, [])

    const handleSubmit = (event) => {
        console.log('handleSubmit')
	    event.preventDefault()

	    const form = event.currentTarget

	    var complete = event.nativeEvent.submitter.value != 'pause'

	    if (complete && form.checkValidity() === false) {
	        event.stopPropagation()
	        setValidated(true)
	    } else {
	        handleConfirm(documentation, complete)
	        setValidated(false)
	    }
    }

    async function handleConfirm(documentation, complete) {
        if (complete) {
            setCreating(true)
        } else {
            setPausing(true)
        }

        const licenseCode = selectedLicense.licenseCode

		documentation.name = name
        documentation.numberOfPhotos = photos.length

        var result = await documentationService.saveWithLicenseCode(documentation, licenseCode)
        console.log(result)

		for (let i = 0; i < photos.length; i++) {
			const photo = photos[i]
            const photoResult = await photoService.saveWithLicenseCode(photo, licenseCode)
            console.log(photoResult)
            const uploadResult = await photoService.upload({id: photo.id, customerId: documentation.customerId, data: photo.data}, photo.id, licenseCode)
            console.log(uploadResult)
		}

		for (let i = 0; i < files.length; i++) {
			const file = files[i]
            const fileResult = await fileService.saveWithLicenseCode(file, licenseCode)
            console.log(fileResult)
            const uploadResult = await fileService.upload({id: file.id, customerId: documentation.customerId, data: file.data}, file.id, licenseCode)
            console.log(uploadResult)
		}

		if (complete) {
			result = await documentationService.completeWithLicenseCode(documentation.id, licenseCode)
	        console.log(result)
        } else {
			result = await documentationService.pauseWithLicenseCode(documentation.id, licenseCode)
	        console.log(result)
        }

		setCreating(false)
		setPausing(false)
        history.push("/documentation/details/" + documentation.id)
        dispatch(alertActions.success(t('alerts.createsuccessful')))
    }

    const handleNameChange = e => {
        setName(e.target.value)
    }

    const handleAnswerChange = (e, task) => {
        console.log('handleAnswerChange')

	    const target = e.target
	    const name = target.name
	    var value = target.value
	    if (task.type == 'MULTI_CHOICE' || task.type == 'MULTI_CHOICE_SUGGESTION') {
			var answers = task.answer ? task.answer.split(';') : []

			if (target.checked) {
				answers.push(target.name)
			} else {
				answers = answers.filter(answer => answer != target.name)
			}

			value = answers.join(';')
	    } else if (target.type === 'checkbox') {
	        value = target.checked
	    }

	    task.answer = value

	    setDocumentation( prevState => ({
	        ...prevState,
	        tasks: prevState.tasks
		}))
	}

	const handlePhotoComment = (e, photo) => {
		photo.comment = e.target.value

		var newPhotos = [...photos]
		setPhotos(newPhotos)
	}

	const handleFileChange = (e, task) => {
        console.log('handleFileChange ' + task.name + ' ' + task.id)

        let targetFiles = e.target.files

        var promises = []

        Array.from(targetFiles).forEach(targetFile => {
            console.log(targetFile)
            let promise = new Promise((resolve, reject) => {
                let fileReader = new FileReader()
                fileReader.onload = function() {
                    let binaryString = fileReader.result
                    let hexString = btoa(binaryString)

                    if (task.type == "PHOTO" || task.type == "DRAWING") {
                        var existingPhotoNames = photos.filter(photo => photo.documentationTask == task.id).map(photo => photo.name)
						var index = 1
	                    var photoName = task.name + "_" + (index < 10 ? '0' : '') + index

	                    while (existingPhotoNames.includes(photoName)) {
	                        index++
	                        photoName = task.name + "_" + (index < 10 ? '0' : '') + index
	                    }

	                    var photo = {
				            id: uuidv4(),
				            customerId: documentation.customerId,
				            documentationTask: task.id,
				            documentation: documentation.id,
				            name: photoName,
				            comment: '',
				            creator: documentation.creator,
				            lat: 1000,
				            lng: 1000,
				            imported: true,
				            dateCreated: moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
				            data: hexString
				        }

				        resolve(photo)
                    } else if (task.type == "FILE") {
                        var file = {
				            id: uuidv4(),
				            customerId: documentation.customerId,
				            documentationTask: task.id,
				            documentation: documentation.id,
				            name: targetFile.name,
				            creator: documentation.creator,
				            type: targetFile.type,
				            imported: true,
				            dateCreated: moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
				            data: hexString
				        }

				        resolve(file)
                    }
                }

                fileReader.onerror = function() {
                    reject(fileReader)
                }

                fileReader.readAsBinaryString(targetFile)
            })
            promises.push(promise)
        })

        Promise.all(promises).then(values => {
            if (task.type == "PHOTO" || task.type == "DRAWING") {
	            var newPhotos = [...photos]
	            values.forEach(value => {
	                newPhotos.push(value)
	            })
	            setPhotos(newPhotos)
            } else if (task.type == "FILE") {
                var newFiles = [...files]
	            values.forEach(value => {
	                newFiles.push(value)
	            })
	            setFiles(newFiles)
            }
        })
    }

    const removePhoto = (e, photo) => {
        e.preventDefault()

		var newPhotos = photos.filter(p => p.id != photo.id)
		var remainingPhotos = newPhotos.filter(p => p.documentationTask == photo.documentationTask)
		if (remainingPhotos.length > 0) {
			var task = documentation.tasks.find(task => task.id == photo.documentationTask)
			if (task) {
				var index = 1
				remainingPhotos.forEach(remainingPhoto => {
					remainingPhoto.name = task.name + "_" + (index < 10 ? '0' : '') + index
					index++
				})
			}
		}
		setPhotos(newPhotos)
    }

    const removeFile = (e, file) => {
        e.preventDefault()

		var newFiles = files.filter(f => f.id != file.id)
		setFiles(newFiles)
    }

    const handleLicenseChange = (license) => {
        setSelectedLicense(license)
        setSelectedTemplate(null)
        setDocumentation(null)
        setPhotos([])
        setFiles([])
        setValidated(false)
    }

    const handleTemplateChange = (template) => {
        setSelectedTemplate(template)
        setPhotos([])
        setFiles([])
        setValidated(false)

        var documentation = {
            id: uuidv4(),
            customerId: template.customerId,
            templateId: template.id,
            name: "",
            creator: authService.getCurrentUser().username,
            phoneModel: "N/A",
            osVersion: navigator.oscpu,
            appVersion: "N/A",
            deviceId: "N/A",
            numberOfPhotos: 0,
            deleteAutomatically: template.deleteAutomatically,
            deleteTimeLimit: template.deleteTimeLimit,
            exportAutomatically: template.exportAutomatically,
            dateCreated: moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
            templateLastUpdated: template.lastUpdated,
            tasks: []
        }

        template.tasks.forEach(templateTask => {
            var task = {
                id: uuidv4(),
                customerId: template.customerId,
                templateTaskId: templateTask.id,
                documentation: documentation.id,
                parent: templateTask.parent,
                name: templateTask.name,
                description: templateTask.description,
                type: templateTask.type,
                options: templateTask.options,
                answer: "",
                required: templateTask.required,
                position: templateTask.position,
                preconditions: [],
                events: [],
                dateCreated: moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]")
            }

            templateTask.preconditions.forEach(templatePrecondition => {
                var precondition = {
                    id: uuidv4(),
                    customerId: template.customerId,
                    task: task.id,
                    type: templatePrecondition.type,
                    params: templatePrecondition.params,
                    dateCreated: moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]")
                }
                task.preconditions.push(precondition)
            })

            documentation.tasks.push(task)
        })

        documentation.tasks.forEach(task => {
            if (task.parent) {
	            var parent = documentation.tasks.find(parent => parent.templateTaskId == task.parent)
	            if (parent) {
	                task.parent = parent.id
	            }
            }
            task.preconditions.forEach(precondition => {
                if (precondition.params.TASK_ID) {
                    var otherTask = documentation.tasks.find(otherTask => otherTask.templateTaskId == precondition.params.TASK_ID)
                    if (otherTask) {
                        precondition.params.TASK_ID = otherTask.id
                    }
                }
            })
        })

        setDocumentation(documentation)
    }

    const getTaskCard = task => {
        if (!checkPreconditions(task)) {
            return <div key={task.id}></div>
        }
        return <Card key={task.id} style={{ marginBottom: 10 }}>
            <Card.Header style={{ background: 'none' }}>
                {getTaskIcon(task)} {task.name} {task.required == 'YES' ? '*' : ''}
                { task.description &&
                    <div>
                        <i>{task.description}</i>
                    </div>
                }
            </Card.Header>
            <Card.Body>
                { (task.type == 'PHOTO' || task.type == 'DRAWING') &&
                    <>
						{ photos.filter(photo => photo.documentationTask == task.id).map(photo => {
							return <div key={photo.id}>
								<div className="clearfix">
									<div className="float-left">
										{photo.name}
									</div>
									<div className="float-right">
										<a href="#" onClick={e => removePhoto(e, photo)} style={{color: 'red'}}>
											<FontAwesomeIcon icon={faTrash}/>
										</a>
									</div>
								</div>
								<img src={"data:image/png;base64, " + photo.data} style={{width: '100%'}}/>
								{ task.type == 'PHOTO' &&
									<Form.Control
						                placeholder={t('photo.columns.comment')}
						                name="text"
						                value={photo.comment}
						                onChange={e => handlePhotoComment(e, photo)}
						                maxLength="255"
						            />
					            }
							</div>
						})}
	                    <Form.Control
	                        type="file"
	                        accept={"image/jpeg"}
	                        name="file"
	                        onChange={e => handleFileChange(e, task)}
	                        multiple
	                        style={{color: 'transparent'}}
	                    />
	                    { task.required == 'YES' && !photos.some(photo => photo.documentationTask == task.id) &&
	                        <>
	                            <Form.Check
		                            type="checkbox"
		                            name={task.id + "-photosrequired"}
		                            label=""
		                            checked={false}
		                            onChange={(e) => console.log(e)}
		                            required
		                            style={{visibility: 'hidden'}}
		                        />
		                        { validated &&
		                            <Alert variant="danger" style={{marginTop: '5px'}}>
					                    {t('documentation.nophotosselectederror')}
					                </Alert>
		                        }
	                        </>
	                    }
                    </>
                }
                { task.type == 'FILE' &&
                    <>
                        { files.filter(file => file.documentationTask == task.id).map(file => {
							return <div key={file.id} className="clearfix">
								<div className="float-left">
									{file.name}
								</div>
								<div className="float-right">
									<a href="#" onClick={e => removeFile(e, file)} style={{color: 'red'}}>
										<FontAwesomeIcon icon={faTrash}/>
									</a>
								</div>
							</div>
						})}
	                    <Form.Control
	                        type="file"
	                        accept={task.options ? task.options.split(';').map(option => '.' + option).join(',') : ''}
	                        name="file"
	                        onChange={e => handleFileChange(e, task)}
	                        multiple
	                        style={{color: 'transparent'}}
	                    />
	                    { task.required == 'YES' && !files.some(file => file.documentationTask == task.id) &&
	                        <>
	                            <Form.Check
		                            type="checkbox"
		                            name={task.id + "-filesrequired"}
		                            label=""
		                            checked={false}
		                            onChange={(e) => console.log(e)}
		                            required
		                            style={{visibility: 'hidden'}}
		                        />
		                        { validated &&
		                            <Alert variant="danger" style={{marginTop: '5px'}}>
					                    {t('documentation.nofilesselectederror')}
					                </Alert>
		                        }
	                        </>
	                    }
                    </>
                }
                { task.type == 'TEXT' &&
                    <Form.Control
		                placeholder={''}
		                name="text"
		                value={task.answer}
		                onChange={e => handleAnswerChange(e, task)}
		                required={task.required == 'YES'}
		                maxLength="2000"
		            />
                }
                { task.type == 'CHECKBOX' &&
                    <Form.Label>
                        <Form.Check
                            id={task.id + '-checkbox'}
                            type="checkbox"
                            name="check"
                            label={task.options}
                            checked={task.answer}
                            onChange={(e) => handleAnswerChange(e, task)}
                            required={task.required == 'YES'}
                        />
                    </Form.Label>
                }
                { task.type == 'SINGLE_CHOICE' &&
                    <Form.Control
                        required={task.required == 'YES'}
                        as="select"
                        name="answer"
                        value={task.answer}
                        onChange={e => handleAnswerChange(e, task)}
                    >
                        <option value="">Välj svar</option>
                        { task.options.split(";").map(option => {
                            return <option key={task.id + '-' + option} value={option}>{option}</option>
                        })}
	                </Form.Control>
                }
                { task.type == 'SINGLE_CHOICE_SUGGESTION' &&
                    <Form.Control
                        required={task.required == 'YES'}
                        as="select"
                        name="answer"
                        value={task.answer}
                        onChange={e => handleAnswerChange(e, task)}
                    >
                        <option value="">Välj svar</option>
                        { suggestionLists.find(suggestionList => suggestionList.id == task.options) &&
                            <>
                                { suggestionLists.find(suggestionList => suggestionList.id == task.options).suggestions.map(suggestion => {
		                            return <option key={task.id + '-' + suggestion.id} value={suggestion.text}>{suggestion.text}</option>
		                        })}
                            </>
                        }
	                </Form.Control>
                }
                { task.type == 'MULTI_CHOICE' &&
                    <>
	                    { task.options.split(";").map(option => {
	                        return <div key={task.id + '-' + option}>
	                            <Form.Label>
			                        <Form.Check
			                            id={task.id + option}
			                            type="checkbox"
			                            name={option}
			                            label={option}
			                            checked={task.answer && task.answer.split(';').includes(option)}
			                            onChange={(e) => handleAnswerChange(e, task)}
			                            required={task.required == 'YES' && !task.answer}
			                        />
			                    </Form.Label>
		                    </div>
	                    })}
                    </>
                }
                { task.type == 'MULTI_CHOICE_SUGGESTION' &&
	                <>
	                    { suggestionLists.find(suggestionList => suggestionList.id == task.options) &&
	                        <>
			                    { suggestionLists.find(suggestionList => suggestionList.id == task.options).suggestions.map(suggestion => {
			                        return <div key={task.id + '-' + suggestion.id}>
			                            <Form.Label>
					                        <Form.Check
					                            id={task.id + '-' + suggestion.id}
					                            type="checkbox"
					                            name={suggestion.text}
					                            label={suggestion.text}
					                            checked={task.answer && task.answer.split(';').includes(suggestion.text)}
					                            onChange={(e) => handleAnswerChange(e, task)}
					                            required={task.required == 'YES' && !task.answer}
					                        />
					                    </Form.Label>
				                    </div>
			                    })}
	                        </>
	                    }
                    </>
                }
                { documentation.tasks.filter(child => child.parent == task.id).map(child => {
					return getTaskCard(child)
				})}
            </Card.Body>
        </Card>
    }

    const getTaskIcon = (task) => {
	    switch (task.type) {
			case 'PHOTO':
				return <FontAwesomeIcon icon={faCamera}/>
			case 'FILE':
				return <FontAwesomeIcon icon={faPaperclip}/>
			case 'DRAWING':
				return <FontAwesomeIcon icon={faPaintbrush}/>
			case 'TEXT':
			case 'HEADER':
			case 'STATIC_TEXT':
				return <FontAwesomeIcon icon={faFont}/>
			case 'INTEGER':
			case 'DECIMAL':
				return <FontAwesomeIcon icon={faHashtag}/>
			case 'CHECKBOX':
				return <FontAwesomeIcon icon={faSquareCheck}/>
			case 'SINGLE_CHOICE':
			case 'MULTI_CHOICE':
			case 'SINGLE_CHOICE_SUGGESTION':
			case 'MULTI_CHOICE_SUGGESTION':
				return <FontAwesomeIcon icon={faListCheck}/>
			case 'GROUP':
				return <FontAwesomeIcon icon={faFolder}/>
			case 'PREDEFINED':
				return <FontAwesomeIcon icon={faGear}/>
		}
		return <FontAwesomeIcon icon={faCamera}/>
    }

    const filterTemplates = () => {
        var filteredTemplates = templates.filter(template => {
            return template.customerId == selectedLicense.customerId
        })
        filteredTemplates = filteredTemplates.filter(template => {
            var noLicenses = template.templateLicenses.length == 0
            var someLicense = template.templateLicenses.some(templateLicense => templateLicense.license == selectedLicense.id)
            return noLicenses || someLicense
        })
        if (selectedLicense.reverseDocumentationCreation) {
            var filteredLists = suggestionLists.filter(suggestionList => suggestionList.suggestions.some(suggestion => suggestion.text == name))
            filteredTemplates = filteredTemplates.filter(template => template.suggestionList && filteredLists.some(list => list.id == template.suggestionList))
        }
        return filteredTemplates
    }

    const getSuggestions = () => {
        var suggestions = []
        if (!selectedLicense.reverseDocumentationCreation) {
            var suggestionList = suggestionLists.find(suggestionList => suggestionList.id == selectedTemplate.suggestionList)
            if (suggestionList) {
                suggestionList.suggestions.forEach(suggestion => {
	                if (!suggestions.some(s => s.text == suggestion.text)) {
	                    suggestions.push(suggestion)
	                }
	            })
            }
        } else {
            suggestionLists.forEach(suggestionList => {
	            suggestionList.suggestions.forEach(suggestion => {
	                if (!suggestions.some(s => s.text == suggestion.text)) {
	                    suggestions.push(suggestion)
	                }
	            })
	        })
        }
        return suggestions
    }

    const getNameInput = () => {
		if (!selectedLicense.reverseDocumentationCreation) {
			if (selectedTemplate) {
				if (selectedTemplate.suggestionList) {
					if (selectedTemplate.onlySuggestionsAllowed) {
						return <Select
		                    placeholder={t('documentation.columns.name')}
		                    isLoading={suggestionLists.length === 0}
		                    options={getSuggestions()}
		                    getOptionLabel={ suggestion => suggestion.text }
		                    getOptionValue={ suggestion => suggestion.text }
		                    isMulti={false}
		                    closeMenuOnSelect={true}
		                    onChange={suggestion => setName(suggestion.text)}
		                />
					} else {
						return <>
							<Form.Control
								list="nameSuggestions"
				                placeholder={t('documentation.columns.name')}
				                name="name"
				                value={name}
				                onChange={handleNameChange}
				                required
				            />
				            <datalist id="nameSuggestions">
							    { getSuggestions().map(suggestion =>
							        <option key={suggestion.id} value={suggestion.text}/>
							    )}
							</datalist>
			            </>
					}
				} else {
					return <Form.Control
		                placeholder={t('documentation.columns.name')}
		                name="name"
		                value={name}
		                onChange={handleNameChange}
		                required
		            />
				}
			}
		} else {
			return <Select
                placeholder={t('documentation.columns.name')}
                isLoading={suggestionLists.length === 0}
                options={getSuggestions()}
                getOptionLabel={ suggestion => suggestion.text }
                getOptionValue={ suggestion => suggestion.text }
                isMulti={false}
                closeMenuOnSelect={true}
                onChange={suggestion => setName(suggestion.text)}
            />
		}
		return <></>
    }

    const checkPreconditions = task => {
        if (task.preconditions.length == 0) {
            return true
        }

        var result = true
        task.preconditions.forEach(precondition => {
            switch (precondition.type) {
                case 'TASK_ANSWER':
                    var taskId = precondition.params.TASK_ID
                    var answer = precondition.params.ANSWER
                    var operator = precondition.params.OPERATOR
                    var task = documentation.tasks.find(task => task.id == taskId)
                    if (task) {
                        if (operator == 'EQUALS') {
                            if (task.answer != answer) {
                                result = false
                            }
                        } else if (operator == 'NOT_EQUALS') {
                            if (task.answer == answer) {
                                result = false
                            }
                        }
                    }
                    break
                case 'TASK_PHOTOS_TAKEN':
					var taskId = precondition.params.TASK_ID
					if (!photos.some(photo => photo.documentationTask == taskId)) {
						result = false
					}
                    break
                case 'TASK_NO_PHOTOS_TAKEN':
					var taskId = precondition.params.TASK_ID
					if (photos.some(photo => photo.documentationTask == taskId)) {
						result = false
					}
                    break
            }
        })

        return result
    }

    return (
        <>
	        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
	            { licenses.length > 1 &&
		            <Row>
						<Col sm="4">
							<Form.Group controlId="license">
			                    <Form.Label>
				                    {t('documentation.columns.license')}
				                </Form.Label>
				                <Select
				                    placeholder="Välj licens"
				                    isLoading={licenses.length === 0}
				                    options={licenses}
				                    value={selectedLicense}
				                    getOptionLabel={ license => {
				                        return license.name ? license.name + ' (' + license.licenseCode + ')' : license.licenseCode
				                    }}
				                    getOptionValue={ license => license.id }
				                    isMulti={false}
				                    closeMenuOnSelect={true}
				                    onChange={handleLicenseChange}
				                />
		                    </Form.Group>
		                </Col>
		            </Row>
	            }
	            { selectedLicense &&
	                <>
	                    { !selectedLicense.reverseDocumentationCreation &&
	                        <>
	                            <Row>
									<Col sm="4">
										<Form.Group controlId="template">
						                    <Form.Label>
							                    {t('documentation.columns.template')}
							                </Form.Label>
							                <Select
							                    placeholder={t('template.choosetemplate')}
							                    isLoading={templates.length === 0}
							                    options={filterTemplates()}
							                    value={selectedTemplate}
							                    getOptionLabel={ template => {
							                        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
							                            return template.name + ' (' + template.customerName + ')'
							                        }
							                        return template.name
							                    }}
							                    getOptionValue={ template => template.id }
							                    isMulti={false}
							                    closeMenuOnSelect={true}
							                    onChange={handleTemplateChange}
							                />
					                    </Form.Group>
					                </Col>
					            </Row>
					            { selectedTemplate &&
						            <Row>
						                <Col sm="4">
						                    <Form.Group controlId="name">
							                    <Form.Label>
								                    {t('documentation.columns.name')} *
								                </Form.Label>
						                        {getNameInput()}
						                    </Form.Group>
						                </Col>
						            </Row>
					            }
			                </>
	                    }
	                    { selectedLicense.reverseDocumentationCreation &&
	                        <>
		                        <Row>
					                <Col sm="4">
					                    <Form.Group controlId="name">
						                    <Form.Label>
							                    {t('documentation.columns.name')} *
							                </Form.Label>
					                        {getNameInput()}
					                    </Form.Group>
					                </Col>
					            </Row>
					            { name &&
		                            <Row>
										<Col sm="4">
											<Form.Group controlId="template">
							                    <Form.Label>
								                    {t('documentation.columns.template')}
								                </Form.Label>
								                <Select
								                    placeholder={t('template.choosetemplate')}
								                    isLoading={templates.length === 0}
								                    options={filterTemplates()}
								                    value={selectedTemplate}
								                    getOptionLabel={ template => {
								                        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
								                            return template.name + ' (' + template.customerName + ')'
								                        }
								                        return template.name
								                    }}
								                    getOptionValue={ template => template.id }
								                    isMulti={false}
								                    closeMenuOnSelect={true}
								                    onChange={handleTemplateChange}
								                />
						                    </Form.Group>
						                </Col>
						            </Row>
					            }
			                </>
	                    }
	                </>
	            }
	            { documentation &&
	                <>
		                <Row>
							<Col sm="4">
								<Form.Label>
									{t('documentation.columns.tasks')}
								</Form.Label>
							</Col>
						</Row>
			            <Row>
							<Col sm="4">
								{ documentation.tasks.filter(task => !task.parent).map(task => {
									return getTaskCard(task)
								})}
							</Col>
						</Row>
					</>
				}
				<Row style={{marginTop: '10px', marginBottom: '10px'}}>
					<Col sm="12">
						<Button variant="secondary" onClick={() => history.goBack()}>
				            {t('crudtable.cancel')}
				        </Button>
				        { documentation && name &&
				            <>
				                &nbsp;
				                { !creating &&
				                    <Button form="data-form" name="pause" value="create" type="submit" variant="primary" disabled={pausing}>
				                        {t('documentation.create')}
				                    </Button>
				                }
				                { creating &&
				                    <Button variant="primary">
				                        {t('crudtable.saving')}
				                        &nbsp;
								            <Spinner animation="border" role="status" size="sm">
												<span className="sr-only">Loading...</span>
											</Spinner>
				                    </Button>
				                }
				            </>
				        }
				        { selectedLicense && documentation && name && selectedLicense.pauseDocumentations &&
				            <>
				                &nbsp;
				                { !pausing &&
				                    <Button form="data-form" name="pause" value="pause" type="submit" variant="primary" disabled={creating}>
				                        {t('documentation.pause')}
				                    </Button>
				                }
				                { pausing &&
				                    <Button variant="primary">
				                        {t('crudtable.saving')}
				                        &nbsp;
								            <Spinner animation="border" role="status" size="sm">
												<span className="sr-only">Loading...</span>
											</Spinner>
				                    </Button>
				                }
				            </>
				        }
			        </Col>
		        </Row>
		        <ScrollUpButton/>
	        </Form>
        </>
    )
}

export default DocumentationForm