import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Row, Col, Form, Alert, Spinner, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { uploadLaterService } from '../../_services'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';

function UploadLater() {

	const [documentation, setDocumentation] = useState(null)
	const [tasks, setTasks] = useState([])
	const [files, setFiles] = useState([])
	const [showSuccessAlert, setShowSuccessAlert] = useState(false)
	const [showErrorAlert, setShowErrorAlert] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const queryParameters = new URLSearchParams(window.location.search)
	const token = queryParameters.get("token")
	const {t} = useTranslation('common')
	const hiddenFileInput = useRef(null)

    useEffect(() => {
        uploadLaterService.getDocumentation(token).then(data => {
            setDocumentation(data)
        })
		uploadLaterService.getTasks(token).then(data => {
            setTasks(data)
        })
    }, [])

    const getAcceptTypes = (task) => {
        if (task.options) {
            return task.options.split(";").map(type => '.' + type)
        }
        return []
    }

    const onClick = (task) => {
        document.getElementById('file-' + task.id).click()
    }

    const handleFilesChange = (e, task) => {
        let targetFiles = e.target.files
        if (targetFiles && targetFiles.length > 0) {
            var promises = []
            for (var i = 0; i < targetFiles.length; i++) {
                let file = targetFiles[i]
                let promise = new Promise((resolve, reject) => {
                    let fileReader = new FileReader()
                    fileReader.onload = function() {
                        let binaryString = fileReader.result;
                        let hexString = btoa(binaryString)
                        console.log(file)
                        var externalFile = {
                            id: uuidv4(),
							name: file.name,
							size: file.size,
							data: hexString,
							type: file.type,
							customerId: task.customerId,
							documentationTask: task.id,
							documentation: task.documentationId,
							imported: true
						}
                        resolve(externalFile)
                    }

                    fileReader.onerror = function() {
                        reject(fileReader)
                    }

                    fileReader.readAsBinaryString(file)
                })
                promises.push(promise)
            }

            Promise.all(promises).then(values => {
                var newExternalFiles = [...files]
                values.forEach(value => {
                    console.log(value)
                    if (!newExternalFiles.some(externalFile => externalFile.name == value.name)) {
                        newExternalFiles.push(value)
                    }
                })
                setFiles(newExternalFiles)
            })
        }
    }

    const removeExternalFile = (e, externalFile) => {
        e.preventDefault()

        var newExternalFiles = files.filter(otherExternalFile => otherExternalFile != externalFile)
        setFiles(newExternalFiles)
    }

    const upload = () => {
        setShowSuccessAlert(false)
        setShowErrorAlert(false)

        if (files.length == 0) {
            return
        }

        setIsUploading(true)

		uploadLaterService.upload(files, token)
		.then(result => {
			setFiles([])
			setShowSuccessAlert(true)
			setIsUploading(false)

			uploadLaterService.getTasks(token).then(data => {
	            setTasks(data)
	        })
        })
        .catch(error => {
			setShowErrorAlert(true)
			setIsUploading(false)
		})
    }

	return (
		<Container>
			{ token &&
				<Row>
					{ showSuccessAlert &&
						<Col sm="12">
							<Alert style={{marginTop: '10px'}} variant="success" dismissible onClose={() => setShowSuccessAlert(false)}>
				                {t('uploadlater.success')}
				            </Alert>
			            </Col>
					}
					{ showErrorAlert &&
						<Col sm="12">
							<Alert style={{marginTop: '10px'}} variant="danger" dismissible onClose={() => setShowErrorAlert(false)}>
				                {t('uploadlater.error')}
				            </Alert>
			            </Col>
					}
					{ documentation &&
						<Col md="12">
							<h1>
								{documentation.name}
							</h1>
			            </Col>
					}
					<Col md="12">
						<h3>
							{t('uploadlater.label')}
						</h3>
		            </Col>
		            <Col md="12">
		                { tasks.length == 0 &&
		                    <div>
		                        {t('uploadlater.notasks')}
		                    </div>
		                }
						{ tasks.map(task => {
							return <Card key={task.id}>
								<Card.Header style={{ background: 'none' }}>
									{task.name}
								</Card.Header>
								<Card.Body>
									<Row>
										{ files.filter(file => file.documentationTask == task.id).map(externalFile => {
											return <Col xs="12" sm="6" md="3" key={externalFile.id} style={{paddingBottom: '10px'}}>
												<div style={{width: '100%'}}>
													<div style={{display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', width: 'calc(100% - 40px)'}}>
														{externalFile.name}
													</div>
													<div style={{display: 'inline-block'}}>
														<Button variant="outline-danger" onClick={e => removeExternalFile(e, externalFile)}>
															<FontAwesomeIcon icon={faTrash} style={{color: 'red'}}/>
														</Button>
													</div>
												</div>
												{ externalFile.type == 'image/jpeg' &&
													<img src={'data:image/jpeg;base64, ' + externalFile.data} style={{width: '100%'}}/>
												}
											</Col>
										})}
									</Row>
									<Row>
										<Col>
											<Form.Group controlId="files">
												<Button variant="outline-primary" onClick={() => onClick(task)}>
													{t('uploadlater.choose')}
												</Button>
												<div style={{display: 'none'}}>
													<input id={'file-' + task.id} accept={getAcceptTypes(task)} type="file" multiple onChange={e => handleFilesChange(e, task)}/>
												</div>
								            </Form.Group>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						})}
		            </Col>
					<Col sm="12" style={{marginTop: 20, marginBottom: 20}}>
						{ tasks.length == 0 &&
							<Button variant="primary" disabled>
								{t('uploadlater.upload')}
							</Button>
						}
						{ tasks.length != 0 &&
							<>
								{ !isUploading &&
									<Button variant="primary" onClick={upload}>
										{t('uploadlater.upload')}
									</Button>
								}
								{ isUploading &&
									<Button variant="primary" disabled>
										{t('uploadlater.uploading')}
										&nbsp;
							            <Spinner animation="border" role="status" size="sm">
											<span className="sr-only">{t('uploadlater.uploading')}...</span>
										</Spinner>
									</Button>
								}
							</>
						}
					</Col>
	            </Row>
            }
        </Container>
    )
}

export default UploadLater;