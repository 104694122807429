import React from 'react';
import { Button, Dropdown, InputGroup, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function DateFilter({dateRange, setDateRange, toggleable = false, active = true, setActive}) {

	const {t} = useTranslation('common');

	const formatDate = (date) => {
		return date.toISOString().substring(0, 10)
	}

	const changeStartDate = (e) => {
		let value = e.target.value
		setDateRange([value, dateRange[1]])
	}

	const changeEndDate = (e) => {
		let value = e.target.value
		setDateRange([dateRange[0], value])
	}

	return (
		<div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block', cursor: 'default'}}>
			<Dropdown>
				<Dropdown.Toggle variant="light" size="sm" id="dropdown-basic" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
					<FontAwesomeIcon icon={faFilter}/>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					{ toggleable &&
						<div>
							<label>
								<input type="checkbox" name="active" checked={active} onChange={() => setActive(!active)}/>
								&nbsp;
								{t('documentation.filter.active')}
							</label>
						</div>
					}
					{ ((toggleable && active) || !toggleable) &&
						<>
							<label>
								{t('documentation.filter.startdate')}
							</label>
							<br/>
							<InputGroup className="mb-3">
								<Form.Control type="date" value={dateRange[0]} min="2021-01-01" max={dateRange[1]} onChange={changeStartDate}/>
								<Button variant="outline-secondary" size="sm" onClick={e => setDateRange([new Date().toISOString().substring(0, 10), dateRange[1]])}>
									{t('documentation.filter.today')}
								</Button>
								<Button variant="outline-secondary" size="sm" onClick={e => setDateRange(["2021-01-01", dateRange[1]])}>
									{t('documentation.filter.reset')}
								</Button>
							</InputGroup>
							<label>
								{t('documentation.filter.enddate')}
							</label>
							<br/>
							<InputGroup className="mb-3">
								<Form.Control type="date" value={dateRange[1]} min={dateRange[0]} max={formatDate(new Date())} onChange={changeEndDate}/>
								<Button variant="outline-dark" size="sm" onClick={e => setDateRange([dateRange[0], new Date().toISOString().substring(0, 10)])}>
									{t('documentation.filter.today')}
								</Button>
							</InputGroup>
						</>
					}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default DateFilter;