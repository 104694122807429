import React, { useState, useEffect } from 'react'
import { exportDocumentConfigurationService } from '../../_services'
import { Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

function CopyConfiguration(props) {

	const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false)
	const [name, setName] = useState('')
    const {t} = useTranslation('common')

	const onClick = () => {
		setName(props.configuration.name + ' - Kopia')
		setShow(true)
		setBackdrop(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const confirm = () => {
		exportDocumentConfigurationService.copy(props.configuration.id, name).then(data => {
            window.location.reload()
        })
    }

	return (
		<>
			<Button variant="outline-dark" size="sm" onClick={onClick} title="Kopiera konfiguration">
				<FontAwesomeIcon icon={faCopy}/>
			</Button>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Kopiera konfiguration
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="name">
						<Form.Label>
			                Namn
			            </Form.Label>
			            <Form.Control required type="text" placeholder={t('exportdocumentconfiguration.columns.name')} name="name" value={name} onChange={e => setName(e.target.value)} />
		            </Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="danger" onClick={confirm}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CopyConfiguration