import React from 'react';
import CrudTable from '../table/crudtable'
import { expectedAnswerListService, authService } from '../../_services'
import ExpectedAnswerListForm from './expectedanswerlistform.component'
import { useTranslation } from "react-i18next";

function ExpectedAnswerList() {

    const {t} = useTranslation('common');

    const initialState = {
        customerId: 1,
        name: '',
        description: '',
        expectedAnswers: []
    }

    const descriptionFormatter = function(cell) {
        return cell ? cell : '-'
    }

    const numberOfExpectedAnswersFormatter = function(cell, row) {
	    return row.expectedAnswers.length
    }

    const getSort = () => {
	    if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        return {dataField: 'customerName', order: 'asc'}
	    }
	    return {dataField: 'name', order: 'asc'}
    }

    const columns = [
        {
            dataField: 'customerName',
            text: t('expectedanswerlist.columns.customer'),
            sort: true,
            hidden: !authService.hasRoles(['ROLE_SUPER_ADMIN'])
        },
        {
            dataField: 'name',
            text: t('expectedanswerlist.columns.name'),
            sort: true
        },
        {
            dataField: 'description',
            text: t('expectedanswerlist.columns.description'),
            formatter: descriptionFormatter,
            sort: true
        },
        {
            dataField: 'numberOfExpectedAnswers',
            text: t('expectedanswerlist.columns.numberofexpectedanswers'),
            formatter: numberOfExpectedAnswersFormatter,
            sort: true
        }
    ]

  return (
    <div>
      <h2>
        {t('expectedanswerlist.label')}
      </h2>
      <CrudTable
        service={expectedAnswerListService}
        columns={columns}
        initialState={initialState}
        form={<ExpectedAnswerListForm />}
        remote={{"filter": false, "search": false, "pagination": false}}
        sort={getSort()}
        modalSize="lg"
        creatable={authService.hasRoles(['ROLE_EXPECTED_ANSWER_LIST_WRITE'])}
        editable={authService.hasRoles(['ROLE_EXPECTED_ANSWER_LIST_WRITE'])}
        deletable={authService.hasRoles(['ROLE_EXPECTED_ANSWER_LIST_WRITE'])}
      />
      </div>
  )
}

export default ExpectedAnswerList;