import {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { userActions } from '../_actions';

function Logout() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.logout("/"))
    }, [dispatch])

    return ( null )
}

export default Logout