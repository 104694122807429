import api from './api.service'

export const expectedAnswerImportEventService = {
    list
};

const BASE_URL = '/expectedAnswerImportEvents'

function list(max) {
    return api.get(BASE_URL, {max: max || 1000, sort: 'dateCreated', order: 'desc'})
}
