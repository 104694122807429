import api from './api.service'

export const formMonthService = {
    list,
    findAllByDocumentationName
};

const BASE_URL = '/formMonths'

function list(max) {
    return api.get(BASE_URL, {max: max || 1000, sort: 'dateCreated', order: 'desc'})
}

function findAllByDocumentationName(name) {
	let url = '/formMonth/findAllByDocumentationName/' + name
    return api.get(url, {max: 10000, sort: 'dateCreated', order: 'asc'})
}
