import React from 'react';
import CrudTable from '../table/crudtable'
import { deviceService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug, faCopy } from '@fortawesome/free-solid-svg-icons'

function Device() {

    const {t} = useTranslation('common');

	const copyId = (id) => {
		navigator.clipboard.writeText(id);
		alert("Kopierat id!");
	}

    const formatDeviceId = (cell) => {
        return <div>
            <span title={cell}>
                { cell && cell.length > 11 &&
                    cell.substring(0, 4) + '...' + cell.substring(cell.length - 4, cell.length)
                }
                { (!cell || cell.length <= 11) &&
	                cell
	            }
            </span>
            &nbsp;
            <a onClick={e => copyId(cell)} style={{cursor: 'pointer'}}>
                <FontAwesomeIcon icon={faCopy} style={{fontSize: '12px'}}/>
            </a>
        </div>
	}

	const formatDate = (cell) => {
		return moment(cell).format('YYYY-MM-DD HH:mm:ss')
	}

	const formatAppVersion = (cell) => {
		if (cell.includes('DEBUG')) {
			return <div>{cell.replace(' DEBUG', '')} <FontAwesomeIcon icon={faBug} style={{fontSize: '12px'}}/></div>
		}
		return cell
	}

	const formatSettings = (cell, row) => {
		return <div>
			{formatBoolean(row.deletePhotosAfterUpload)} / {formatBoolean(row.backupToLibrary)} / {formatBoolean(row.uploadOnWifiOnly)}
		</div>
	}

	const formatBoolean = (cell) => {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const formatLicenses = (cell, row) => {
		if (row.currentLicenseCode) {
			var otherLicenseCodes = cell.map(license => license.licenseCode).filter(licenseCode => licenseCode != row.currentLicenseCode)
			if (otherLicenseCodes && otherLicenseCodes.length > 0) {
				return row.currentLicenseCode + ', ' + otherLicenseCodes.sort(Intl.Collator().compare).join(', ')
			}
			return row.currentLicenseCode
		}
		return cell.map(license => license.licenseCode).sort(Intl.Collator().compare).join(', ')
	}

    const columns = [
        {
			dataField: 'deviceId',
			text: t('device.columns.deviceid'),
			formatter: formatDeviceId,
			sort: true
	    },
	    {
			dataField: 'username',
			text: t('device.columns.username'),
			sort: true
	    },
	    {
			dataField: 'osVersion',
			text: t('device.columns.osversion'),
			sort: true
	    },
	    {
			dataField: 'phoneModel',
			text: t('device.columns.phonemodel'),
			sort: true
	    },
	    {
			dataField: 'appVersion',
			text: t('device.columns.appversion'),
			formatter: formatAppVersion,
			sort: true
	    },
	    {
			dataField: 'settings',
			headerTitle: () => {
				return t('device.columns.deletephotosafterupload') + ' / ' +
					t('device.columns.backuptolibrary') + ' / ' +
					t('device.columns.uploadonwifionly')
			},
			text: t('device.columns.settings'),
			formatter: formatSettings,
			sort: true
	    },
	    {
			dataField: 'dateCreated',
			text: t('device.columns.datecreated'),
			formatter: formatDate,
			sort: true
	    },
	    {
			dataField: 'lastUpdated',
			text: t('device.columns.lastupdated'),
			formatter: formatDate,
			sort: true
	    },
	    {
			dataField: 'licenses',
			text: t('device.columns.licenses'),
			formatter: formatLicenses,
			filterValue: formatLicenses,
			sort: true
	    }
    ]

	return (
		<div>
			<h2>
				{t('device.label')}
            </h2>
            <CrudTable
		        service={deviceService}
		        columns={columns}
		        remote={{"filter": false, "search": false, "pagination": false}}
				creatable={false}
				editable={false}
				deletable={true}
		        sort={{dataField: 'username', order: 'asc'}}
			/>
		</div>
    )
}

export default Device;