import api from './api.service'

export const exportEventService = {
    list,
    get,
    save,
    update,
    _delete,
    findAllByDocumentation,
    findAllByDocumentationName
};

const BASE_URL = '/exportEvents'

function list(max) {
    return api.get(BASE_URL)
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(exportEvent) {
    return api.post(BASE_URL, exportEvent)
}

function update(id, exportEvent) {
    return api.put(BASE_URL + "/" + id, exportEvent)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function findAllByDocumentation(documentationId, max) {
	return api.get('/exportEvent/findAllByDocumentation/' + documentationId, {max : max || 1000, sort: 'dateCreated', order: 'asc'})
}

function findAllByDocumentationName(documentationName, max) {
	return api.get('/exportEvent/findAllByDocumentationName/' + documentationName, {max : max || 1000, sort: 'dateCreated', order: 'asc'})
}
