import React, {useState, useEffect} from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { customerService, roleService, userService, licenseService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'

const UserForm = ({data, handleChange, handleSubmit, validated}) => {
    const [customers, setCustomers] = useState([])
    const [userLicenses, setUserLicenses] = useState([])
    const [selectedUserLicenses, setSelectedUserLicenses] = useState([])
    const [roles, setRoles] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [users, setUsers] = useState([])

    const {t} = useTranslation('common');

    useEffect(() => {
        customerService.getOptions().then(customers => {
            customers.sort((a, b) => a.name.localeCompare(b.name))
            setCustomers(customers)
            if (data.customer === -1 && customers.length > 0) {
                handleChange({target: {type: "select", name: "customer", value: customers[0].id}})
            }
        })
        licenseService.simpleList().then(licenses => {
            var userLicenses = []
            var selectedUserLicenses = []
            licenses.forEach(license => {
                var userLicense = data.userLicenses.find(userLicense => userLicense.license == license.id)
                if (userLicense) {
                    userLicense.name = license.name
					userLicense.licenseCode = license.licenseCode
					userLicense.customerId = license.customerId
					selectedUserLicenses.push(userLicense)
                } else {
					userLicense = {
						license: license.id,
						name: license.name,
						licenseCode: license.licenseCode,
						customerId: license.customerId
					}
                }
                userLicenses.push(userLicense)
            })
            setUserLicenses(userLicenses)
            setSelectedUserLicenses(selectedUserLicenses)
        })
        roleService.list().then(roles => {
            setSelectedRoles(data.userRoles.map(userRole => ({id: userRole.role, authority: roles.find(role => role.id === userRole.role)}.authority)))
            setRoles(roles)
        })
        userService.list().then(users => {
            users.sort((a, b) => a.username.localeCompare(b.username))
            setUsers(users)
        })
    }, [])

    function onRoleChange(e) {
        //Example: [{id: 10, authority: "ROLE_DEVICE_READ"}]
        if (e) {
            setSelectedRoles(e)
            handleChange({target: {type: "select", name: "userRoles", value: e.map(userRole => ({role: userRole.id}))}})
        } else {
            setSelectedRoles([])
            handleChange({target: {type: "select", name: "userRoles", value: []}})
        }
    }

    function onUserLicenseChange(e) {
        if (e) {
            setSelectedUserLicenses(e)
            handleChange({target: {type: "select", name: "userLicenses", value: e}})
        } else {
            setSelectedUserLicenses([])
            handleChange({target: {type: "select", name: "userLicenses", value: []}})
        }
    }

    const handleUserChange = (user) => {
        if (user) {
            var newSelectedValues = []
            user.userRoles.forEach(userRole => {
                newSelectedValues.push({id: userRole.role, authority: userRole.roleAuthority})
            })
            setSelectedRoles(newSelectedValues)
            handleChange({target: {type: "select", name: "userRoles", value: newSelectedValues.map(userRole => ({role: userRole.id}))}})
        }
    }

    const filterUserLicenses = () => {
		return userLicenses.filter(userLicense => userLicense.customerId == data.customer)
    }

    const generatePassword = () => {
        let validChars = "ABCDEFGHJKLMNPQRSTUVXYZabcdefghijkmnopqrstuvxyz23456789"
        var newPassword = ""
        for (var i = 0; i < 12; i++) {
            let index = Math.floor(Math.random() * validChars.length)
            newPassword += validChars.charAt(index)
        }
        handleChange({target: {type: "input", name: "password", value: newPassword}})
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="customer">
                <Form.Label>
                    {t('user.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customer" value={data.customer} onChange={handleChange}>
                    { customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="parent">
                <Form.Label>
                    {t('user.columns.parent')}
                </Form.Label>
                <Form.Control as="select" name="parent" value={data.parent || ''} onChange={handleChange}>
                    <option value="">{t('user.noparent')}</option>
                    { users.map(user => (
                        <option key={user.id} value={user.id}>{user.username}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="username">
                <Form.Label>
                    {t('user.columns.username')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('user.columns.username')} name="username" value={data.username} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="password">
                <Form.Label>
                    {t('user.columns.password')}
                </Form.Label>
                <InputGroup>
	                <Form.Control required type="input" placeholder={t('user.columns.password')} name="password" value={data.password} onChange={handleChange} autoComplete="new-password"/>
	                <Button variant="outline-primary" onClick={generatePassword}>
	                    <FontAwesomeIcon icon={faKey}/>
	                </Button>
                </InputGroup>
            </Form.Group>
             <Form.Group controlId="email">
                <Form.Label>
                    {t('user.columns.email')}
                </Form.Label>
                <Form.Control required={data.twoFactorAuthentication} type="email" placeholder={t('user.columns.email')} name="email" value={data.email || ''} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="roles">
                <Form.Label>
                    {t('user.columns.roles')}
                </Form.Label>
                <Select placeholder={t('user.select.placeholder')} isLoading={roles.length === 0} options={roles} value={selectedRoles} getOptionLabel={role => role.authority} getOptionValue={role => role.id} isMulti={true} closeMenuOnSelect={false} onChange={onRoleChange}/>
            </Form.Group>
            { !data.dateCreated &&
                <Form.Group controlId="copyFrom">
                    <Form.Label>
	                    {t('user.copyrolesfrom')}
	                </Form.Label>
	                <Select
	                    placeholder={t('user.chooseuser')}
	                    options={users}
	                    value={[]}
	                    getOptionLabel={user => user.username + ' (' + user.customerName + ')'}
	                    getOptionValue={user => user.id}
	                    isMulti={false}
	                    closeMenuOnSelect={true}
	                    onChange={handleUserChange}
	                />
                </Form.Group>
            }
            <Form.Group controlId="userLicenses">
                <Form.Label>
                    {t('user.columns.licenses')}
                </Form.Label>
                <Select
                    placeholder={t('user.columns.licenses')}
                    isLoading={userLicenses.length === 0}
                    options={filterUserLicenses()}
                    value={selectedUserLicenses}
                    getOptionLabel={userLicense => userLicense.name || userLicense.licenseCode}
                    getOptionValue={license => license.id}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={onUserLicenseChange}
                />
            </Form.Group>
            <Form.Group controlId="enabled">
                <Form.Check type="checkbox" label={t('user.columns.enabled')} name="enabled" checked={data.enabled} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="accountExpired">
                <Form.Check type="checkbox" label={t('user.columns.accountexpired')} name="accountExpired" checked={data.accountExpired} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="accountLocked">
                <Form.Check type="checkbox" label={t('user.columns.accountlocked')} name="accountLocked" checked={data.accountLocked} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="passwordExpired">
                <Form.Check type="checkbox" label={t('user.columns.passwordexpired')} name="passwordExpired" checked={data.passwordExpired} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="twoFactorAuthentication">
                <Form.Check type="checkbox" label={t('user.columns.twofactorauthentication')} name="twoFactorAuthentication" checked={data.twoFactorAuthentication} onChange={handleChange}/>
            </Form.Group>
        </Form>
    )
}


export default UserForm